import { createAsyncThunk } from "@reduxjs/toolkit";
import field_mask_pb from "google-protobuf/google/protobuf/field_mask_pb";

import simulator_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/simulator_pb";

import endpoints from "../endpoints";
import { sendRequest } from "../requests-browser";

import { SimulatorsRequest, NewSimulator, SimulatorUpdate } from "./types";
import { RelatedUser } from "@skydio/pbtypes/pbtypes/tools/cloud_api/user_pb";

const createUpdateProto = (update: SimulatorUpdate) => {
  const simProto = new simulator_pb.Simulator();
  const updateMask = new field_mask_pb.FieldMask();
  if ("name" in update) {
    updateMask.addPaths("name");
    if (update.name) simProto.setName(update.name);
  }
  if ("vehicleId" in update) {
    updateMask.addPaths("vehicle_id");
    if (update.vehicleId) simProto.setVehicleId(update.vehicleId);
  }
  if ("hostname" in update) {
    updateMask.addPaths("hostname");
    if (update.hostname) simProto.setHostname(update.hostname);
  }
  if ("host" in update) {
    updateMask.addPaths("host");
    if (update.host) simProto.setHost(update.host);
  }
  if ("externalHttpPort" in update) {
    updateMask.addPaths("external_http_port");
    if (update.externalHttpPort) simProto.setExternalHttpPort(update.externalHttpPort);
  }
  if ("httpPort" in update) {
    updateMask.addPaths("http_port");
    if (update.httpPort) simProto.setHttpPort(update.httpPort);
  }
  if ("udpPort" in update) {
    updateMask.addPaths("udp_port");
    if (update.udpPort) simProto.setUdpPort(update.udpPort);
  }
  if ("insecure" in update) {
    updateMask.addPaths("insecure");
    if (update.insecure) simProto.setInsecure(update.insecure);
  }
  if ("connectWithoutWifi" in update) {
    updateMask.addPaths("connect_without_wifi");
    if (update.connectWithoutWifi) simProto.setConnectWithoutWifi(update.connectWithoutWifi);
  }
  if ("defaultRunmode" in update) {
    updateMask.addPaths("default_runmode");
    if (update.defaultRunmode) simProto.setDefaultRunmode(update.defaultRunmode);
  }
  if ("region" in update) {
    updateMask.addPaths("region");
    if (update.region) simProto.setRegion(update.region);
  }
  if ("pooled" in update) {
    updateMask.addPaths("pooled");
    if (update.pooled) simProto.setPooled(update.pooled);
  }
  if ("imageTag" in update) {
    updateMask.addPaths("image_tag");
    if (update.imageTag) simProto.setImageTag(update.imageTag);
  }
  if ("userEmailsList" in update) {
    updateMask.addPaths("user_emails");
    update.userEmailsList?.forEach(userEmail => {
      simProto.addUserEmails(userEmail);
    });
  }
  if ("users" in update) {
    updateMask.addPaths("users");
    update.usersList?.forEach(user => {
      const u = new RelatedUser();
      u.setUuid(user.uuid);
      simProto.addUsers(u);
    });
  }
  const updateProto = new simulator_pb.UpdateSimulatorRequest();
  updateProto.setSimulator(simProto);
  updateProto.setUpdateMask(updateMask);
  return updateProto;
};

export const fetchSimulators = createAsyncThunk(
  "simulators/fetchAll",
  async (args: SimulatorsRequest) => {
    const responseProto = await sendRequest(endpoints.GET_SIMULATORS, args);
    return responseProto.toObject();
  }
);

export const fetchSimulator = createAsyncThunk("simulators/fetchOne", async (uuid: string) => {
  const responseProto = await sendRequest(endpoints.GET_SIMULATOR, { path: { uuid } });
  return responseProto.toObject();
});

export const createSimulator = createAsyncThunk(
  "simulators/create",
  async (newSim: NewSimulator) => {
    const responseProto = await sendRequest(endpoints.ADD_SIMULATOR, {
      path: { uuid: "" },
      protobuf: createUpdateProto(newSim),
    });
    return responseProto.toObject();
  }
);

export interface UpdateArgs {
  uuid: string;
  update: SimulatorUpdate;
}

export const updateSimulator = createAsyncThunk(
  "simulators/update",
  async ({ uuid, update }: UpdateArgs) => {
    const responseProto = await sendRequest(endpoints.UPDATE_SIMULATOR, {
      path: { uuid },
      protobuf: createUpdateProto(update),
    });
    return responseProto.toObject();
  }
);
