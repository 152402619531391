import _ from "lodash";

export type Selector<State, Return, Args extends Array<any> = []> = (
  state: State,
  ...args: Args
) => Return;
type WrappedSelector<AppState, SliceState, T> = T extends Selector<
  SliceState,
  infer Return,
  infer Args
>
  ? Selector<AppState, Return, Args>
  : unknown;

export const makeWrappedSelector = <
  AppState,
  SliceState,
  Return,
  Args extends Array<any>,
  T extends Selector<SliceState, Return, Args>
>(
  fn: T,
  sliceSelector: Selector<AppState, SliceState>
) =>
  ((state: AppState, ...args: Args) => fn(sliceSelector(state), ...args)) as WrappedSelector<
    AppState,
    SliceState,
    T
  >;

// Take a bundle of partial selectors (selectors that take an individual module's state slice as
// an argument) and return a bundle of wrapped selectors that expect an app's top level state
// instead. Each new selector will call the provided slice selector and pass the resulting state as
// the first argument to the selector being wrapped. All additional arguments and the return type
// of the original selector are preserved in the new selector.
export const makeWrappedSelectors = <
  AppState,
  SliceState,
  Selectors extends Record<keyof Selectors, Selector<SliceState, any, any>>
>(
  partialSelectors: Selectors,
  sliceSelector: Selector<AppState, SliceState>
) =>
  _.reduce(
    partialSelectors,
    (all, selector, name) => ({
      ...all,
      [name]: makeWrappedSelector(selector, sliceSelector),
    }),
    {} as { [K in keyof Selectors]: WrappedSelector<AppState, SliceState, Selectors[K]> }
  );
