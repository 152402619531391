import _ from "lodash";

import enums_pb2 from "@skydio/pbtypes/pbtypes/tools/cloud_api/enums_pb";
import {
  parseSearchState,
  makeQuerySerializable,
  convertToNumber,
  convertToBoolean,
  convertFromBoolean,
} from "@skydio/uri_util/src/query";

import { VehiclesQueryState, VehiclesQueryParams } from "./types";

export const vehiclesQueryInitialState: VehiclesQueryState = {
  vehicleId: "",
  userEmail: "",
  userUuid: "",
  skydioSerial: "",
  releaseKey: "",
  organizationId: "",
  includeOrganizationFlightCount: false,
};

export const parseVehiclesQueryState = (searchString: string) =>
  parseSearchState(searchString, vehiclesQueryInitialState, (params: VehiclesQueryParams) => ({
    ...params,
    limit: convertToNumber(params.limit),
    perPage: convertToNumber(params.perPage),
    pageNumber: convertToNumber(params.pageNumber),
    disabled: convertToBoolean(params.disabled),
    includeOrganizationFlightCount: convertToBoolean(params.includeOrganizationFlightCount),
  }));

export const prepareVehiclesRequest = (state: VehiclesQueryState) =>
  makeQuerySerializable(state, vehiclesQueryInitialState, state => ({
    ...state,
    disabled: convertFromBoolean(state.disabled),
    includeOrganizationFlightCount: convertFromBoolean(state.includeOrganizationFlightCount),
  }));

export const vehicleTypeEnum = enums_pb2.VehicleType.VehicleTypeEnum;

export const getVehicleTypeKey = (vehicleType: enums_pb2.VehicleType.VehicleTypeEnum) =>
  _.findKey(enums_pb2.VehicleType.VehicleTypeEnum, o => o === vehicleType);

type VehicleReleaseMap = {
  [key in enums_pb2.VehicleType.VehicleTypeEnum]?: string;
};

export const VEHICLE_RELEASE_NAMES: VehicleReleaseMap = {
  [enums_pb2.VehicleType.VehicleTypeEnum.E1]: "Skydio X2",
  [enums_pb2.VehicleType.VehicleTypeEnum.R3]: "Skydio 2",
  [enums_pb2.VehicleType.VehicleTypeEnum.R47]: "Skydio X10",
};

export const getVehicleReleaseName = (vehicleType: enums_pb2.VehicleType.VehicleTypeEnum) =>
  VEHICLE_RELEASE_NAMES[vehicleType] || getVehicleTypeKey(vehicleType);
