import { createAsyncThunk } from "@reduxjs/toolkit";
import field_mask_pb from "google-protobuf/google/protobuf/field_mask_pb";

import release_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/release_pb";

import endpoints from "../endpoints";
import { sendRequest } from "../requests-browser";

import { DevReleaseFilesRequest, DevReleaseFileUpdate } from "./types";

export const fetchDevReleaseFiles = createAsyncThunk(
  "releases/fetchAllDev",
  async (args: DevReleaseFilesRequest) => {
    const response = await sendRequest(endpoints.GET_RELEASE_FILES, {
      ...args,
      devFlashpacksOnly: 1,
      fullFlashpacksOnly: 1,
      includeAdminFields: 1,
    });
    return response.toObject();
  }
);

export const fetchDevReleaseFile = createAsyncThunk("releases/fetchOneDev", async (key: string) => {
  const release = await sendRequest(endpoints.GET_RELEASE_FILE, { path: { key } });
  return release.toObject();
});

export interface UpdateArgs {
  key: string;
  update: DevReleaseFileUpdate;
}

export const updateDevReleaseFile = createAsyncThunk(
  "releases/devUpdate",
  async ({ key, update }: UpdateArgs) => {
    const releaseUpdateProto = new release_pb.ReleaseFile();
    const updateMasks = new field_mask_pb.FieldMask();
    if ("vehicleType" in update) {
      updateMasks.addPaths("vehicle_type");
      if (update.vehicleType) releaseUpdateProto.setVehicleType(update.vehicleType);
    }
    const protobuf = new release_pb.UpdateReleaseRequest();
    protobuf.setRelease(releaseUpdateProto);
    protobuf.setUpdateMask(updateMasks);

    const release = await sendRequest(endpoints.UPDATE_RELEASE, { path: { key }, protobuf });
    return release.toObject();
  }
);
