import { createSlice, combineReducers, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";

import {
  createRequestReducerFromThunk,
  createIndexedRequestReducerFromThunk,
  createClearableIndexedRequestReducer,
  reduceReducers,
} from "@skydio/redux_util/src";

import { fetchFlights, fetchFlight } from "../flights/asyncThunks";
import { fetchVehicles, fetchVehicle } from "../vehicles/asyncThunks";
import { fetchReleaseFiles, updateDeviceOverride, updateOverride } from "../releases/asyncThunks";
import { fetchDevReleaseFiles, fetchDevReleaseFile, updateDevReleaseFile } from "./asyncThunks";

import { APIVehicle } from "../vehicles/types";
import { APIFlight } from "../flights/types";
import {
  DevReleaseFilesPrimaryState,
  DevReleaseFile,
  DevReleaseFileUpdate,
  DevReleaseFilesMap,
} from "./types";
import { APIRelease } from "../releases/types";
import {
  defaultPagination,
  initialFile,
  releaseFileInitialState,
  clearableUserReleaseOverrideReducer,
  clearableDeviceReleaseOverrideReducer,
} from "../releases/slice";

const initialState: DevReleaseFilesPrimaryState = {
  devReleaseFiles: {},
  releaseFiles: {},
  dispatchTimeout: null,
  requestedIds: [],
  pagination: defaultPagination,
};

const devReleaseFileInitialState: DevReleaseFile = { ...releaseFileInitialState };

export interface FieldUpdatePayload {
  key: string;
  name: keyof DevReleaseFileUpdate;
  value: any;
}

const updateDevReleaseFileState = (
  state: DevReleaseFilesMap,
  { file = initialFile, ...release }: APIRelease
) => {
  if (!(file.key in state)) {
    state[file.key] = { ...devReleaseFileInitialState };
  }

  Object.assign(state[file.key]!, file, release, {
    uploaded: file.uploaded ? moment(file.uploaded * 1000) : state.uploaded,
    s3Key: file.key,
  });
};

const updateReleasesFromVehicles = (state: DevReleaseFilesMap, vehicles: APIVehicle[]) => {
  vehicles.forEach(vehicle => {
    if (vehicle.release) {
      updateDevReleaseFileState(state, vehicle.release);
    }
  });
};

const updateReleasesFromFlights = (state: DevReleaseFilesMap, flights: APIFlight[]) => {
  flights.forEach(flight => {
    if (flight.release) {
      updateDevReleaseFileState(state, flight.release);
    }
  });
};

const { actions, reducer: primaryReducer } = createSlice({
  name: "devReleaseFiles",
  initialState,
  reducers: {
    setDevReleaseFilesQueryTimeout(state, { payload }: PayloadAction<number>) {
      state.dispatchTimeout = payload;
    },
    clearDevReleaseFilesQueryTimeout(state) {
      state.dispatchTimeout = null;
    },
    updateDevReleaseFileField(state, { payload }: PayloadAction<FieldUpdatePayload>) {
      const release = state.devReleaseFiles[payload.key];
      if (_.isEqual(release![payload.name as keyof DevReleaseFile], payload.value)) {
        delete release!.modified[payload.name];
      } else {
        // @ts-ignore TS2322
        release.modified[payload.name] = payload.value;
      }
    },
    clearDevReleaseFileModifications(state, { payload }: PayloadAction<string>) {
      state.devReleaseFiles[payload]!.modified = {};
    },
  },
  extraReducers: builder =>
    builder
      .addCase(fetchFlights.fulfilled, (state, { payload }) => {
        updateReleasesFromFlights(state.devReleaseFiles as DevReleaseFilesMap, payload.flightsList);
      })
      .addCase(fetchFlight.fulfilled, (state, { payload }) => {
        updateReleasesFromFlights(state.devReleaseFiles as DevReleaseFilesMap, [payload]);
      })
      .addCase(fetchVehicles.fulfilled, (state, { payload }) => {
        updateReleasesFromVehicles(
          state.devReleaseFiles as DevReleaseFilesMap,
          payload.vehiclesList
        );
      })
      .addCase(fetchVehicle.fulfilled, (state, { payload }) => {
        updateReleasesFromVehicles(state.devReleaseFiles as DevReleaseFilesMap, [payload]);
      })
      .addCase(updateOverride.fulfilled, (state, { payload }) => {
        if (!(payload.releaseKey in state.devReleaseFiles)) {
          state.devReleaseFiles[payload.releaseKey] = { ...devReleaseFileInitialState };
        }
        Object.assign(state.devReleaseFiles[payload.releaseKey]!, {
          userReleaseOverridesList: payload.userReleaseOverridesList,
        });
      })
      .addCase(updateDeviceOverride.fulfilled, (state, { payload }) => {
        if (!(payload.releaseKey in state.devReleaseFiles)) {
          state.devReleaseFiles[payload.releaseKey] = { ...devReleaseFileInitialState };
        }
        Object.assign(state.devReleaseFiles[payload.releaseKey]!, {
          deviceReleaseOverridesList: payload.deviceReleaseOverridesList,
        });
      })
      .addCase(fetchDevReleaseFiles.fulfilled, (state, { payload }) => {
        state.requestedIds = payload.releaseFilesList.map(({ file }) => file!.key);
        payload.releaseFilesList.forEach(release => {
          updateDevReleaseFileState(state.devReleaseFiles as DevReleaseFilesMap, release);
        });
        state.pagination = {
          maxPerPage: payload.pagination!.maxPerPage,
          currentPage: payload.pagination!.currentPage,
          totalPages: payload.pagination!.totalPages,
        };
      })
      .addCase(fetchDevReleaseFiles.rejected, state => {
        state.requestedIds = [];
      })
      .addCase(fetchDevReleaseFile.fulfilled, (state, { payload }) => {
        updateDevReleaseFileState(state.devReleaseFiles as DevReleaseFilesMap, payload);
      })
      .addCase(updateDevReleaseFile.fulfilled, (state, { payload }) => {
        updateDevReleaseFileState(state.devReleaseFiles as DevReleaseFilesMap, payload);
        state.devReleaseFiles[payload.toReleaseKey]!.modified = {};
      }),
});

const { clearAction: clearDevReleaseFileRequest, clearableReducer } =
  createClearableIndexedRequestReducer(
    reduceReducers(
      createIndexedRequestReducerFromThunk(fetchDevReleaseFile),
      createIndexedRequestReducerFromThunk(updateDevReleaseFile, "key")
    ),
    "devReleaseFiles"
  );

const reducer = combineReducers({
  state: primaryReducer,
  requests: combineReducers({
    devReleaseFiles: createRequestReducerFromThunk(fetchDevReleaseFiles),
    devReleaseFile: clearableReducer,
    releaseFiles: createIndexedRequestReducerFromThunk(fetchReleaseFiles, "toReleaseKey"),
    userReleaseOverrides: clearableUserReleaseOverrideReducer,
    deviceReleaseOverrides: clearableDeviceReleaseOverrideReducer,
  }),
});
export type DevReleaseFilesState = ReturnType<typeof reducer>;

export const devReleaseFileActions = { ...actions, clearDevReleaseFileRequest };
export default reducer;
