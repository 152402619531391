import { createSlice, createReducer, combineReducers, PayloadAction } from "@reduxjs/toolkit";

import {
  createRequestReducerFromThunk,
  createIndexedRequestReducerFromThunk,
  createClearableIndexedRequestReducer,
  reduceReducers,
} from "@skydio/redux_util/src";

import {
  fetchOrganization,
  fetchOrganizations,
  createOrganization,
  deleteOrganization,
  updateOrganization,
  fetchOrganizationPilotStats,
  fetchOrganizationStorageStats,
} from "./asyncThunks";

import {
  OrganizationsMap,
  OrganizationPilotStats,
  OrganizationStorageStats,
  Organization,
  APIOrganization,
  NEW_ORG_PLACEHOLDER_ID,
} from "./types";

const organizationInitialState: Organization = {
  name: "",
  uuid: "",
  userEmailSuffix: "",
  userEmailSuffixDefaultOrgPermission: 0,
  groupLinksList: [],
  skillsetLinksList: [],
  usersList: [],
  modified: {},
  userCount: 0,
  switchesList: [],
  dataLevel: 0,
  resourceGroupsList: [],
  permissionGrantedForProductImprovement: false,
  dataUsageRights: 0,
  orgTier: 0,
};

export interface UpdateFieldPayload {
  uuid: string;
  name: string;
  value: string;
}

const updateOrganizationState = (state: OrganizationsMap, org: APIOrganization) => {
  if (!(org.uuid in state)) {
    state[org.uuid] = { ...organizationInitialState };
  }
  Object.assign(state[org.uuid]!, org);
};

const { actions, reducer: organizations } = createSlice({
  name: "organizations",
  initialState: {} as OrganizationsMap,
  reducers: {
    updateOrganizationField(state, { payload }: PayloadAction<UpdateFieldPayload>) {
      state[payload.uuid]!.modified = {
        // Doing this update mutatively causes typescript to complain
        ...state[payload.uuid]!.modified,
        [payload.name]: payload.value,
      };
    },
    clearOrganizationModifications(state, { payload }: PayloadAction<string>) {
      state[payload]!.modified = {};
    },
  },
  extraReducers: builder =>
    builder
      .addCase(fetchOrganizations.fulfilled, (state, { payload }) => {
        payload.forEach(org => {
          if (!state[org.uuid]) {
            updateOrganizationState(state, org);
          }
        });
      })
      .addCase(createOrganization.fulfilled, (state, { payload }) => {
        updateOrganizationState(state, payload);
      })
      .addCase(fetchOrganization.fulfilled, (state, { payload }) => {
        updateOrganizationState(state, payload);
      })
      .addCase(updateOrganization.fulfilled, (state, { payload }) => {
        updateOrganizationState(state, payload);
      })
      .addCase(deleteOrganization.fulfilled, (state, { meta }) => {
        delete state[meta.arg];
      }),
});

const organizationStorageStats = createReducer({} as OrganizationStorageStats, builder =>
  builder.addCase(fetchOrganizationStorageStats.fulfilled, (_state, { payload }) => payload)
);

const organizationPilotStats = createReducer({} as OrganizationPilotStats, builder =>
  // @ts-ignore TS6133
  builder.addCase(fetchOrganizationPilotStats.fulfilled, (state, { payload }) => {
    state = payload;
  })
);

const { clearAction: clearOrganizationRequest, clearableReducer } =
  createClearableIndexedRequestReducer(
    reduceReducers(
      createIndexedRequestReducerFromThunk(fetchOrganization, "uuid"),
      createIndexedRequestReducerFromThunk(updateOrganization, "uuid"),
      createIndexedRequestReducerFromThunk(createOrganization, () => NEW_ORG_PLACEHOLDER_ID)
    ),
    "organizations"
  );

const reducer = combineReducers({
  organizations,
  organizationStorageStats,
  organizationPilotStats,
  requests: combineReducers({
    organizations: createRequestReducerFromThunk(fetchOrganizations),
    organization: clearableReducer,
  }),
});
export type OrganizationsState = ReturnType<typeof reducer>;

export const organizationActions = { ...actions, clearOrganizationRequest };
export default reducer;
