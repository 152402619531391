import { createAsyncThunk } from "@reduxjs/toolkit";
import field_mask_pb from "google-protobuf/google/protobuf/field_mask_pb";

import pagination_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/pagination_pb";
import release_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/release_pb";
import user_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/user_pb";

import { download } from "../../../common/http";
import endpoints from "../endpoints";
import { sendRequest } from "../requests-browser";

import { ControllerReleasesRequest, ControllerReleaseUpdate } from "./types";

export const fetchControllerReleases = createAsyncThunk(
  "controllerReleases/fetchAll",
  async (args: ControllerReleasesRequest) => {
    const protobuf = new release_pb.ControllerReleasesRequest();
    const pagination = new pagination_pb.Pagination();
    if (args.releaseKey) {
      protobuf.setReleaseKey(args.releaseKey);
    }
    if (args.minVersion) {
      protobuf.setMinVersion(args.minVersion);
    }
    if (args.maxVersion) {
      protobuf.setMaxVersion(args.maxVersion);
    }
    if (args.group) {
      protobuf.setGroupList(args.group);
    }
    if (args.notGroup) {
      protobuf.setNotGroupList(args.notGroup);
    }
    if (args.description) {
      protobuf.setDescription(args.description);
    }
    if (args.limit) {
      protobuf.setLimit(args.limit);
    }
    if (args.pageNumber) {
      pagination.setCurrentPage(args.pageNumber);
    }
    if (args.perPage) {
      pagination.setMaxPerPage(args.perPage);
    }
    protobuf.setPagination(pagination);
    const response = await sendRequest(endpoints.GET_CONTROLLER_RELEASES, { protobuf });
    return response.toObject();
  }
);

export const fetchControllerRelease = createAsyncThunk(
  "controllerReleases/fetchOne",
  async (key: string) => {
    const controllerRelease = await sendRequest(endpoints.GET_CONTROLLER_RELEASE, {
      path: { key },
    });
    return controllerRelease.toObject();
  }
);

export interface UpdateArgs {
  key: string;
  update: ControllerReleaseUpdate;
}

export const updateControllerRelease = createAsyncThunk(
  "controllerReleases/update",
  async ({ key, update }: UpdateArgs) => {
    const controllerReleaseUpdateProto = new release_pb.ControllerReleaseFile();
    const updateMasks = new field_mask_pb.FieldMask();
    if ("version" in update) {
      updateMasks.addPaths("version");
      if (update.version) controllerReleaseUpdateProto.setVersion(update.version);
    }
    if ("controllerType" in update) {
      updateMasks.addPaths("controller_type");
      if (update.controllerType)
        controllerReleaseUpdateProto.setControllerType(update.controllerType);
    }
    if ("description" in update) {
      updateMasks.addPaths("description");
      if (update.description) controllerReleaseUpdateProto.setDescription(update.description);
    }
    if ("active" in update) {
      updateMasks.addPaths("active");
      if (update.active) controllerReleaseUpdateProto.setActive(update.active);
    }
    if ("groups" in update) {
      updateMasks.addPaths("groups");
      update.groups?.forEach(groupName => {
        const groupProto = new user_pb.Group();
        groupProto.setName(groupName);
        controllerReleaseUpdateProto.addGroups(groupProto);
      });
    }
    const protobuf = new release_pb.UpdateControllerReleaseRequest();
    protobuf.setControllerRelease(controllerReleaseUpdateProto);
    protobuf.setUpdateMask(updateMasks);

    try {
      const controllerRelease = await sendRequest(endpoints.UPDATE_CONTROLLER_RELEASE, {
        path: { key },
        protobuf,
      });
      return controllerRelease.toObject();
    } catch (error: any) {
      console.error(error);
      alert(
        "Failed to update controller release. Check the console for more information. " +
          (error.code ? `Code: ${error.code}` : "")
      );
      throw error;
    }
  }
);

export interface DownloadArgs {
  key: string;
  doDownload?: boolean | null;
}

export const downloadControllerRelease = createAsyncThunk(
  "controllerReleases/download",
  async ({ key, doDownload = true }: DownloadArgs) => {
    const protobuf = new release_pb.GetControllerReleaseRequest();
    protobuf.setReleaseKey(key);
    const response = await sendRequest(endpoints.DOWNLOAD_CONTROLLER_RELEASE, { protobuf });
    const downloadUrl = response.toObject().downloadUrl;

    if (doDownload) {
      download(downloadUrl);
    } else {
      navigator.clipboard.writeText(downloadUrl);
    }
  }
);

export interface ControllerOverrideUpdateArgs {
  key: string;
  controllerId: string;
  validDuration?: number;
}

export const updateControllerOverride = createAsyncThunk(
  "releases/setController",
  async ({ key, controllerId, validDuration }: ControllerOverrideUpdateArgs) => {
    const protobuf = new release_pb.ControllerReleaseOverrideRequest();
    if (controllerId) {
      protobuf.setControllerId(controllerId);
    }
    if (validDuration) {
      protobuf.setValidDuration(validDuration);
    }
    const controllerReleaseOverrides = await sendRequest(
      endpoints.SET_CONTROLLER_RELEASE_OVERRIDE,
      {
        path: { key },
        protobuf,
      }
    );
    return controllerReleaseOverrides.toObject();
  }
);

interface ControllerUserOverrideUpdateArgs {
  key: string;
  userEmail: string;
  validDuration?: number;
}

export const updateControllerUserOverride = createAsyncThunk(
  "releases/setControllerUser",
  async ({ key, userEmail, validDuration }: ControllerUserOverrideUpdateArgs) => {
    const protobuf = new release_pb.ControllerReleaseOverrideRequest();
    if (userEmail) {
      protobuf.setEmail(userEmail);
    }
    if (validDuration) {
      protobuf.setValidDuration(validDuration);
    }
    const controllerReleaseOverrides = await sendRequest(
      endpoints.SET_CONTROLLER_RELEASE_OVERRIDE,
      {
        path: { key },
        protobuf,
      }
    );
    return controllerReleaseOverrides.toObject();
  }
);

export interface DeleteControllerOverrideArgs {
  key: string;
  controllerId: string;
}

export const deleteControllerOveride = createAsyncThunk(
  "releases/deleteController",
  async ({ key, controllerId }: DeleteControllerOverrideArgs) => {
    const protobuf = new release_pb.DeleteControllerReleaseOverrideRequest();
    if (controllerId) {
      protobuf.setControllerId(controllerId);
    }
    await sendRequest(endpoints.DELETE_CONTROLLER_RELEASE_OVERRIDE, {
      path: { key },
      protobuf,
    });
    return { key, controllerId };
  }
);

export const deleteControllerUserOverride = createAsyncThunk(
  "releases/deleteControllerUser",
  async ({ key, userEmail }: ControllerUserOverrideUpdateArgs) => {
    const protobuf = new release_pb.DeleteControllerReleaseOverrideRequest();
    if (userEmail) {
      protobuf.setEmail(userEmail);
    }
    await sendRequest(endpoints.DELETE_CONTROLLER_RELEASE_OVERRIDE, {
      path: { key },
      protobuf,
    });
    return { key, userEmail };
  }
);
