import { ThunkCreator, AsyncThunkCreator } from "@skydio/redux_util/src";
import { setSearchParams } from "@skydio/uri_util/src/query";

import { QUERY_CHANGE_DELAY } from "../constants";
import { fetchVehicles, fetchVehicle } from "./asyncThunks";
import {
  isVehiclesRequestActive,
  isVehicleRequestActive,
  getVehiclesQueryTimeout,
} from "./selectors";
import { vehicleActions, VehiclesState } from "./slice";
import { prepareVehiclesRequest } from "./utils";

import { VehiclesQueryState } from "./types";

const { clearVehiclesQueryTimeout, setVehiclesQueryTimeout } = vehicleActions;

export const updateVehiclesQuery: ThunkCreator<VehiclesState, [VehiclesQueryState, boolean?]> =
  (fields, immediate) => (dispatch, getState) => {
    const newArgs = prepareVehiclesRequest(fields);

    if (immediate) {
      dispatch(fetchVehicles(newArgs));
    } else {
      const state = getState();

      clearTimeout(getVehiclesQueryTimeout(state)!);
      dispatch(clearVehiclesQueryTimeout());

      const timeout = window?.setTimeout(
        () => dispatch(fetchVehicles(newArgs)),
        QUERY_CHANGE_DELAY
      );
      dispatch(setVehiclesQueryTimeout(timeout));
    }

    dispatch(setSearchParams(newArgs));
  };

export const fetchVehiclesIfAble: AsyncThunkCreator<VehiclesState, [VehiclesQueryState]> =
  fields => async (dispatch, getState) => {
    if (!isVehiclesRequestActive(getState())) {
      dispatch(fetchVehicles(prepareVehiclesRequest(fields)));
    }
  };

export const fetchVehicleIfAble: AsyncThunkCreator<VehiclesState, [string]> =
  vehicleId => async (dispatch, getState) => {
    if (!isVehicleRequestActive(getState(), vehicleId)) {
      dispatch(fetchVehicle(vehicleId));
    }
  };
