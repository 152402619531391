import { createSlice, combineReducers, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import { $CombinedState } from "@reduxjs/toolkit";

import {
  createRequestReducerFromThunk,
  createIndexedRequestReducerFromThunk,
  createClearableIndexedRequestReducer,
  reduceReducers,
} from "@skydio/redux_util/src";

import {
  fetchControllerReleases,
  fetchControllerRelease,
  updateControllerRelease,
  updateControllerOverride,
  deleteControllerOveride,
  updateControllerUserOverride,
  deleteControllerUserOverride,
} from "./asyncThunks";

import {
  ControllerReleasesPrimaryState,
  ControllerRelease,
  ControllerReleaseUpdate,
  APIControllerRelease,
  ControllerReleasesMap,
} from "./types";
import { APIDataFile } from "../data_files/types";
import { APIPagination } from "../pagination/types";

export const defaultPagination: APIPagination = {
  totalPages: 1,
  currentPage: 1,
  maxPerPage: 100,
};

export const initialState: ControllerReleasesPrimaryState = {
  controllerReleases: {},
  dispatchTimeout: null,
  requestedIds: [],
  pagination: defaultPagination,
};

export const initialFile: APIDataFile = {
  uuid: "",
  kind: "",
  sha1: "",
  uploadUrl: "",
  bucket: "",
  key: "",
  filename: "",
  signature: "",
  size: 0,
  uploaded: 0,
  deleted: 0,
  encrypted: false,
  created: 0,
  etag: "",
  bucketType: 0,
  vehicleId: "",
  dockId: "",
  flightId: "",
  userId: "",
  organizationId: "",
  mediaCapturedAt: 0, // This is always 0 for releases
  canDownload: false, // This is unused for releases
  fileUsage: 0, // This is unused for releases
  controllerId: "", // This is unused for releases
  accessPointSerial: "", // This is unused for releases
};

const controllerReleaseInitialState: ControllerRelease = {
  ...initialFile,
  releaseKey: "",
  description: "",
  version: "",
  active: true,
  groups: [],
  manifest: "",
  meta: "",
  otaMeta: "",
  modified: {},
  controllerReleaseOverridesList: [],
  controllerType: 0,
};

export const controllerReleaseFileInitialState: ControllerRelease = {
  ...controllerReleaseInitialState,
};

export interface FieldUpdatePayload {
  key: string;
  name: keyof ControllerReleaseUpdate;
  value: any;
}

const updateControllerReleaseState = (
  state: ControllerReleasesMap,
  { file = initialFile, ...controllerRelease }: APIControllerRelease
) => {
  if (!(controllerRelease.releaseKey in state)) {
    state[controllerRelease.releaseKey] = { ...controllerReleaseInitialState };
  }

  Object.assign(state[controllerRelease.releaseKey]!, file, controllerRelease, {
    uploaded: file.uploaded ? moment(file.uploaded * 1000) : state.uploaded,
    groups: controllerRelease.groupsList
      ? controllerRelease.groupsList.map(group => group.name)
      : state.groups,
  });
};

const { actions, reducer: primaryReducer } = createSlice({
  name: "controllerReleases",
  initialState,
  reducers: {
    setControllerReleasesQueryTimeout(state, { payload }: PayloadAction<number>) {
      state.dispatchTimeout = payload;
    },
    clearControllerReleasesQueryTimeout(state) {
      state.dispatchTimeout = null;
    },
    updateControllerReleaseField(state, { payload }: PayloadAction<FieldUpdatePayload>) {
      const controllerRelease = state.controllerReleases[payload.key];
      if (_.isEqual(controllerRelease![payload.name as keyof ControllerRelease], payload.value)) {
        delete controllerRelease!.modified[payload.name];
      } else {
        // @ts-ignore TS2322
        controllerRelease.modified[payload.name] = payload.value;
      }
    },
    clearControllerReleaseModifications(state, { payload }: PayloadAction<string>) {
      state.controllerReleases[payload]!.modified = {};
    },
  },
  extraReducers: builder =>
    builder
      .addCase(fetchControllerReleases.fulfilled, (state, { payload }) => {
        state.requestedIds = payload.controllerReleasesList.map(({ releaseKey }) => releaseKey);
        payload.controllerReleasesList.forEach(controllerRelease => {
          updateControllerReleaseState(state.controllerReleases, controllerRelease);
        });
        state.pagination = {
          maxPerPage: payload.pagination!.maxPerPage,
          currentPage: payload.pagination!.currentPage,
          totalPages: payload.pagination!.totalPages,
        };
      })
      .addCase(updateControllerOverride.fulfilled, (state, { payload }) => {
        if (!(payload.releaseKey in state.controllerReleases)) {
          state.controllerReleases[payload.releaseKey] = { ...controllerReleaseInitialState };
        }
        Object.assign(state.controllerReleases[payload.releaseKey]!, {
          controllerReleaseOverridesList: payload.overridesList,
        });
      })
      .addCase(updateControllerUserOverride.fulfilled, (state, { payload }) => {
        if (!(payload.releaseKey in state.controllerReleases)) {
          state.controllerReleases[payload.releaseKey] = { ...controllerReleaseInitialState };
        }
        Object.assign(state.controllerReleases[payload.releaseKey]!, {
          controllerReleaseOverridesList: payload.overridesList,
        });
      })
      .addCase(deleteControllerOveride.fulfilled, (state, { payload }) => {
        const { key, controllerId } = payload;
        const controllerRelease = state.controllerReleases[key];

        if (controllerRelease) {
          controllerRelease.controllerReleaseOverridesList =
            controllerRelease.controllerReleaseOverridesList.filter(
              override => override.controllerId !== controllerId
            );
        }
      })
      .addCase(deleteControllerUserOverride.fulfilled, (state, { payload }) => {
        const { key, userEmail } = payload;
        const controllerRelease = state.controllerReleases[key];

        if (controllerRelease) {
          controllerRelease.controllerReleaseOverridesList =
            controllerRelease.controllerReleaseOverridesList.filter(
              override => override.email !== userEmail
            );
        }
      })
      .addCase(fetchControllerReleases.rejected, state => {
        state.requestedIds = [];
      })
      .addCase(fetchControllerRelease.fulfilled, (state, { payload }) => {
        updateControllerReleaseState(state.controllerReleases, payload);
      })
      .addCase(updateControllerRelease.fulfilled, (state, { payload }) => {
        if (payload.controllerRelease) {
          updateControllerReleaseState(state.controllerReleases, payload.controllerRelease);
          state.controllerReleases[payload.controllerRelease.releaseKey]!.modified = {};
        }
      }),
});

const { clearAction: clearControllerReleaseRequest, clearableReducer } =
  createClearableIndexedRequestReducer(
    reduceReducers(
      createIndexedRequestReducerFromThunk(fetchControllerRelease),
      createIndexedRequestReducerFromThunk(updateControllerRelease, "key")
    ),
    "controllerReleases"
  );

const {
  clearAction: clearControllerReleaseOverrideRequest,
  clearableReducer: clearableControllerReleaseOverrideReducer,
} = createClearableIndexedRequestReducer(
  createIndexedRequestReducerFromThunk(updateControllerOverride, "key"),
  "controllerReleaseOverrides"
);

export const {
  clearAction: clearControllerReleaseUserOverrideRequest,
  clearableReducer: clearableControllerReleaseUserOverrideReducer,
} = createClearableIndexedRequestReducer(
  createIndexedRequestReducerFromThunk(updateControllerUserOverride, "key"),
  "controllerReleaseUserOverrides"
);

const reducer = combineReducers({
  state: primaryReducer,
  requests: combineReducers({
    controllerReleases: createRequestReducerFromThunk(fetchControllerReleases),
    controllerRelease: clearableReducer,
    controllerReleaseOverrides: clearableControllerReleaseOverrideReducer,
    controllerReleaseUserOverrides: clearableControllerReleaseUserOverrideReducer,
  }),
});
export type ControllerReleasesState = Omit<ReturnType<typeof reducer>, typeof $CombinedState>;

export const controllerReleaseActions = {
  ...actions,
  clearControllerReleaseRequest,
  clearControllerReleaseOverrideRequest,
  clearControllerReleaseUserOverrideRequest,
};
export default reducer;
