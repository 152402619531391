import user_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/user_pb";

import { Overwrite } from "@skydio/common_util/src";
import { SearchableState } from "../../../common/types";
import { APIPagination, PaginationQueryState, StringifyPagination } from "../pagination/types";
import { APIUserReleaseOverride } from "../releases/types";

export const NEW_USER_PLACEHOLDER_EMAIL = "NEW_USER_PLACEHOLDER_EMAIL";

export type APIUser = user_pb.User.AsObject;
export type APIUserResourceGroupLink = user_pb.UserResourceGroupLink.AsObject;
export type APIRelatedUser = user_pb.RelatedUser.AsObject;

export interface User extends Omit<APIUser, "groupsList" | "organizationGroupsList"> {
  groups: string[];
  organizationGroups: string[];
  modified: UserUpdate;
  releaseOverridesList: APIUserReleaseOverride[];
  displayName: string;
  resourceGroupLinksList: APIUserResourceGroupLink[];
}

export type NewUser = Pick<User, "email" | "groups" | "firstName" | "lastName">;

export type UserUpdate = Partial<Omit<User, "modified">>;

export interface UsersMap {
  [uuid: string]: User;
}

export interface UsersPrimaryState extends SearchableState {
  users: UsersMap;
  pagination: APIPagination;
  addModalOpen: boolean;
}

export interface UsersQueryState extends PaginationQueryState {
  email: string;
  uuid: string;
  group: string[];
  notGroup: string[];
  firstName: string;
  lastName: string;
  emailStatus: string;
  organizationId: string;
  includeOrganizationFlightCount: boolean;
  includeDisabled: boolean;
  organizationPermission: string;
  limit?: number;
}

export type UsersRequest = Overwrite<
  Partial<UsersQueryState>,
  {
    includeOrganizationFlightCount?: string;
  }
>;

export type UsersQueryParams = Overwrite<
  StringifyPagination<UsersRequest>,
  {
    group?: string | string[];
    notGroup?: string | string[];
    limit?: string;
  }
>;

export interface UserRequest {
  path: { identifier: string };
}


export interface AddOrUpdateUserRequest extends UserRequest {
  protobuf?: user_pb.UpdateUserRequest;
}
