import { createReducer, ActionCreatorWithPreparedPayload } from "@reduxjs/toolkit";

import { PaginationState, APIPagination, PaginationProps } from "./types";

const defaultPagination: PaginationState = {
  maxPerPage: 25,
  currentPage: 1,
  totalPages: 1,
};

// Create a reducer that will keep track of the pagination state in the provided fulfilled async action
export const createPaginationReducer = <T extends { pagination?: APIPagination }>(
  fulfilledAction: ActionCreatorWithPreparedPayload<any[], T, any, never, any>
) =>
  createReducer(defaultPagination, builder =>
    builder.addCase(fulfilledAction, (state, { payload }) => {
      Object.assign(state, payload.pagination!);
    })
  );

export const PER_PAGE_OPTIONS = [10, 25, 50, 100];

// Use this to convert pagination state to props expected by antd pagination
export const paginationStateToProps = (
  { maxPerPage, currentPage, totalPages }: PaginationState,
  options?: number[]
): PaginationProps => ({
  pageSize: maxPerPage,
  pageSizeOptions: (options ?? PER_PAGE_OPTIONS).map(String),
  current: currentPage,
  total: maxPerPage * totalPages,
});

// If page size gets changed to a larger value the current page number may no longer be valid,
// this makes sure the page number in the new pagination state will be within the total data
export const safelyGetNewPagination = (newPerPage: number, { current, total }: PaginationProps) =>
  newPerPage * current > total
    ? { perPage: newPerPage, pageNumber: Math.ceil(total / newPerPage) }
    : { perPage: newPerPage };
