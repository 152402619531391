import {
  parseSearchState,
  makeQuerySerializable,
  convertToNumber,
  convertToArray,
} from "@skydio/uri_util/src/query";

import { ControllerDevReleaseFilesQueryState, ControllerDevReleaseFilesQueryParams } from "./types";

export const controllerDevReleaseFilesQueryInitialState: ControllerDevReleaseFilesQueryState = {
  releaseKey: "",
  builder: "",
  branch: "",
  commit: "",
  description: "",
};

export const parseControllerDevReleaseFilesQueryState = (searchString: string) =>
  parseSearchState(
    searchString,
    controllerDevReleaseFilesQueryInitialState,
    (params: ControllerDevReleaseFilesQueryParams) => ({
      ...params,
      limit: convertToNumber(params.limit),
      perPage: convertToNumber(params.perPage),
      pageNumber: convertToNumber(params.pageNumber),
    })
  );

export const prepareControllerDevReleaseFilesRequest = (
  state: ControllerDevReleaseFilesQueryState
) =>
  makeQuerySerializable(
    state,
    controllerDevReleaseFilesQueryInitialState,
    controllerDevReleaseFileState => {
      return controllerDevReleaseFileState;
    }
  );
