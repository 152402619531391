import { createAsyncThunk } from "@reduxjs/toolkit";
import struct_pb from "google-protobuf/google/protobuf/struct_pb";
import field_mask_pb from "google-protobuf/google/protobuf/field_mask_pb";

import enums_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/enums_pb";
import organization_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/organization_pb";

import endpoints from "../endpoints";
import { sendRequest } from "../requests-browser";
import { download } from "../../../common/http";
// import download from "downloadjs";

import { OrganizationUpdate, NewOrganization } from "./types";
import { orgPermissionEnum } from "./utils";
import { OrganizationRequest } from "../analytics/types";

const createUpdateProto = (update: OrganizationUpdate) => {
  // NOTE(roshan): The update message has a field for the item and a FieldMask
  // the FieldMask contains a list of the field paths to use for updating this in the db
  // so that we don't have to populate all the fields of the item each time
  const orgUpdateProto = new organization_pb.Organization();
  const updateMasks = new field_mask_pb.FieldMask();
  if ("name" in update) {
    updateMasks.addPaths("name");
    if (update.name) orgUpdateProto.setName(update.name);
  }
  if ("userEmailSuffix" in update) {
    updateMasks.addPaths("user_email_suffix");
    if (update.userEmailSuffix) orgUpdateProto.setUserEmailSuffix(update.userEmailSuffix);
  }
  if ("skillsetLinksList" in update) {
    updateMasks.addPaths("skillset_links");
    update.skillsetLinksList?.forEach(skillsetLink => {
      const skillsetLinkProto = new organization_pb.OrganizationSkillsetLink();
      skillsetLinkProto.setSkillsetName(skillsetLink.skillsetName);
      skillsetLinkProto.setOrganizationPermission(skillsetLink.organizationPermission);
      orgUpdateProto.addSkillsetLinks(skillsetLinkProto);
    });
  }
  if ("groupLinksList" in update) {
    updateMasks.addPaths("group_links");
    update.groupLinksList?.forEach(groupLink => {
      const groupLinkProto = new organization_pb.OrganizationGroupLink();
      groupLinkProto.setGroupName(groupLink.groupName);
      groupLinkProto.setOrganizationPermission(groupLink.organizationPermission);
      orgUpdateProto.addGroupLinks(groupLinkProto);
    });
  }
  if ("dataLevel" in update) {
    updateMasks.addPaths("data_level");
    if (update.dataLevel) orgUpdateProto.setDataLevel(update.dataLevel);
  }
  if ("orgTier" in update) {
    updateMasks.addPaths("org_tier");
    if (update.orgTier) orgUpdateProto.setOrgTier(update.orgTier);
  }
  const updateProto = new organization_pb.UpdateOrganizationRequest();
  updateProto.setOrganization(orgUpdateProto);
  updateProto.setUpdateMask(updateMasks);
  return updateProto;
};

export const fetchOrganizations = createAsyncThunk("organizations/fetchAll", async () => {
  const response = await sendRequest(endpoints.GET_ORGANIZATIONS);
  const { organizationsList } = response.toObject();
  return organizationsList;
});

export interface FetchOrganizationPayload {
  uuid: string;
  includeUsers?: boolean;
  includeGroupLinks?: boolean;
  includeSkillsetLinks?: boolean;
  includeSwitches?: boolean;
}

export const fetchOrganization = createAsyncThunk(
  "organizations/fetchOne",
  async ({
    uuid,
    includeUsers = false,
    includeGroupLinks = false,
    includeSkillsetLinks = false,
    includeSwitches = false,
  }: FetchOrganizationPayload) => {
    const req: OrganizationRequest = { path: { uuid } };
    if (includeUsers) req["users"] = "true";
    if (includeGroupLinks) req["groupLinks"] = "true";
    if (includeSkillsetLinks) req["skillsetLinks"] = "true";
    if (includeSwitches) req["switches"] = "true";
    const response = await sendRequest(endpoints.GET_ORGANIZATION, req);
    return response.toObject();
  }
);

export const createOrganization = createAsyncThunk(
  "organizations/create",
  async (newOrg: NewOrganization) => {
    const responseProto = await sendRequest(endpoints.ADD_ORGANIZATION, {
      path: { uuid: "" },
      protobuf: createUpdateProto(newOrg),
    });
    return responseProto.toObject();
  }
);

export interface UpdatePayload {
  uuid: string;
  update: OrganizationUpdate;
}

export const updateOrganization = createAsyncThunk(
  "organizations/update",
  async ({ uuid, update }: UpdatePayload) => {
    const responseProto = await sendRequest(endpoints.UPDATE_ORGANIZATION, {
      path: { uuid },
      protobuf: createUpdateProto(update),
    });
    return responseProto.toObject();
  }
);

export const deleteOrganization = createAsyncThunk(
  "organizations/delete",
  async (uuid: string, { dispatch }) => {
    await sendRequest(endpoints.DELETE_ORGANIZATION, {
      path: { uuid },
      protobuf: new struct_pb.Struct(),
    });
    dispatch(fetchOrganizations());
  }
);

export const fetchOrganizationStats = createAsyncThunk(
  "organizations/fetchStats",
  async (uuid: string) => {
    const responseProto = await sendRequest(endpoints.GET_ORG_STATS, {
      path: { uuid },
    });
    return responseProto.toObject();
  }
);

export interface OrgUserPayload {
  uuid: string;
  email: string;
  orgRole?: enums_pb.OrgPermission.PermEnum;
}

export const inviteUserToOrganization = createAsyncThunk(
  "organizations/invite",
  async ({ uuid, email, orgRole }: OrgUserPayload) => {
    const requestProto = new organization_pb.InviteUserToOrganizationRequest();
    requestProto.setOrganizationPermission(orgRole ? orgRole : orgPermissionEnum.MEMBER);

    const responseProto = await sendRequest(endpoints.INVITE_USER_TO_ORG, {
      path: { uuid, email },
      protobuf: requestProto,
    });
    return responseProto.toObject();
  }
);

export const acceptOrganizationInvite = createAsyncThunk(
  "organizations/acceptInvite",
  async (jwtPayload: string) => {
    await sendRequest(endpoints.ACCEPT_ORG_INVITE, {
      path: { jwtPayload },
    });
  }
);

export const removeUserFromOrganization = createAsyncThunk(
  "organizations/removeUser",
  async ({ email, uuid }: OrgUserPayload) => {
    await sendRequest(endpoints.REMOVE_USER_FROM_ORG, { path: { email, uuid } });
  }
);

export const fetchOrganizationPilotStats = createAsyncThunk(
  "organization/fetchPilotStats",
  async (organizationId: string) => {
    const responseProto = await sendRequest(endpoints.GET_ORG_PILOT_STATS, {
      path: { organizationId },
    });
    return responseProto.toObject();
  }
);

export const fetchOrganizationStorageStats = createAsyncThunk(
  "organization/fetchStorageStats",
  async (organizationId: string) => {
    const responseProto = await sendRequest(endpoints.GET_ORG_STORAGE_STATS, {
      path: { organizationId },
    });
    return responseProto.toObject();
  }
);

export const downloadStatsCSV = createAsyncThunk(
  "organization/downloadStatsCSV",
  async (organizationId: string) => {
    const response: Response = await sendRequest(endpoints.DOWNLOAD_STATS_CSV, {
      path: { organizationId },
    });
    const csvBlob = await response.blob();
    const csvInMemoryURL = URL.createObjectURL(csvBlob);
    download(csvInMemoryURL, "Skydio Stats.zip");
    URL.revokeObjectURL(csvInMemoryURL);
    return response;
  }
);

export const uploadLogo = async (organizationId: string, file: File) => {
  const proto = new organization_pb.OrganizationLogoUploadRequest();
  proto.setImage(new Uint8Array(await file.arrayBuffer()));
  proto.setFilename(file.name);
  const responseProto = await sendRequest(endpoints.UPLOAD_LOGO, {
    path: { organizationId },
    protobuf: proto,
  });
  return responseProto.toObject();
};
