import {
  parseSearchState,
  convertToArray,
  convertToMoment,
  convertFromMoment,
  convertToNumber,
  convertToBoolean,
  convertFromBoolean,
  makeQuerySerializable,
} from "@skydio/uri_util/src/query";

import { DataFilesQueryState, DataFilesQueryParams } from "./types";

export const dataFilesQueryInitialState: DataFilesQueryState = {
  vehicleId: "",
  kind: [],
  downloadUrls: false,
};

export const parseDataFilesQueryState = (searchString: string) =>
  parseSearchState(searchString, dataFilesQueryInitialState, (params: DataFilesQueryParams) => ({
    ...params,
    kind: convertToArray(params.kind),
    uploadedSince: convertToMoment(params.uploadedSince),
    uploadedBefore: convertToMoment(params.uploadedBefore),
    createdSince: convertToMoment(params.createdSince),
    createdBefore: convertToMoment(params.createdBefore),
    limit: convertToNumber(params.limit),
    perPage: convertToNumber(params.perPage),
    pageNumber: convertToNumber(params.pageNumber),
    downloadUrls: convertToBoolean(params.downloadUrls),
  }));

export const prepareDataFilesRequest = (state: DataFilesQueryState) =>
  makeQuerySerializable(state, dataFilesQueryInitialState, state => ({
    ...state,
    uploadedSince: convertFromMoment(state.uploadedSince),
    uploadedBefore: convertFromMoment(state.uploadedBefore),
    createdSince: convertFromMoment(state.createdSince),
    createdBefore: convertFromMoment(state.createdBefore),
    downloadUrls: convertFromBoolean(state.downloadUrls),
  }));
