import { createSlice, createReducer, combineReducers, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";

import {
  createRequestReducerFromThunk,
  createIndexedRequestReducerFromThunk,
} from "@skydio/redux_util/src";

import { createPaginationReducer } from "../pagination/utils";
import { fetchFlights, fetchFlight, fetchFlightStats } from "./asyncThunks";
import { getStatsIdTypeKey } from "./utils";

import {
  Flight,
  APIFlight,
  FlightsMap,
  FlightStatsState,
  AggregateFlightsMap,
  FlightsRequest,
} from "./types";

export const flightInitialState: Flight = {
  flightId: "",
  handle: "",
  vehicleId: "",
  gimbalId: "",
  gimbalSerial: "",
  skydioSerial: "",
  takeoffUserId: "",
  userEmail: "",
  takeoffPhoneId: "",
  takeoffClientType: 0,
  takeoffDockId: "",
  takeoffUtime: 0,
  landingUtime: 0,
  takeoffUclock: 0,
  landingUclock: 0,
  takeoff: null,
  landing: null,
  deployCommit: "",
  releaseKey: "",
  dataFiles: [],
  organizationId: "",
};

const updateFlightState = (state: FlightsMap, flight: APIFlight) => {
  if (!(flight.flightId in state)) {
    state[flight.flightId] = { ...flightInitialState };
  }

  Object.assign(state[flight.flightId]!, _.omit(flight, "dataFilesList"), {
    takeoff: moment(flight.takeoffUclock / 1000),
    landing: flight.landingUclock ? moment(flight.landingUclock / 1000) : null,
    dataFiles: flight.dataFilesList.map(dataFile => dataFile.uuid),
  });
};

const { actions, reducer: dispatchTimeout } = createSlice({
  name: "flights",
  initialState: null as number | null,
  reducers: {
    clearFlightsQueryTimeout: () => null,
    setFlightsQueryTimeout: (_state, { payload }: PayloadAction<number>) => payload,
  },
});

const { actions: requestActions, reducer: requestState } = createSlice({
  name: "flights",
  initialState: null as FlightsRequest | null,
  reducers: {
    setRequest: (_state, { payload }: PayloadAction<FlightsRequest>) => payload,
  },
});

const requestedIds = createReducer([] as string[], builder =>
  builder
    .addCase(fetchFlights.fulfilled, (_state, { payload }) =>
      payload.flightsList.map(({ flightId }) => flightId)
    )
    .addCase(fetchFlights.rejected, () => [])
);

const flights = createReducer({} as FlightsMap, builder =>
  builder
    .addCase(fetchFlights.fulfilled, (state, { payload }) => {
      payload.flightsList.forEach(flight => {
        updateFlightState(state as FlightsMap, flight);
      });
    })
    .addCase(fetchFlight.fulfilled, (state, { payload }) => {
      updateFlightState(state as FlightsMap, payload);
    })
);

const initialStatsState: FlightStatsState = {};

const stats = createReducer(initialStatsState, builder =>
  builder.addCase(fetchFlightStats.fulfilled, (state, { payload, meta }) => {
    state[getStatsIdTypeKey(meta.arg.aggregateType)] = payload.reduce(
      (acc, { totalFlights, totalTime, id }) => ({
        ...acc,
        [id]: {
          totalFlights,
          totalTime,
        },
      }),
      {} as AggregateFlightsMap
    );
  })
);

const reducer = combineReducers({
  dispatchTimeout,
  requestState,
  requestedIds,
  flights,
  stats,
  pagination: createPaginationReducer(fetchFlights.fulfilled),
  requests: combineReducers({
    flights: createRequestReducerFromThunk(fetchFlights),
    flight: createIndexedRequestReducerFromThunk(fetchFlight, "flightId"),
  }),
});
export type FlightsState = ReturnType<typeof reducer>;
export const flightActions = { ...actions, ...requestActions };

export default reducer;
