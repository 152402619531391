import { fetchControllerDevReleaseFile, fetchControllerDevReleaseFiles } from "./asyncThunks";
import { controllerDevReleaseFileActions, ControllerDevReleaseFilesState } from "./slice";

import { AsyncThunkCreator, ThunkCreator } from "@skydio/redux_util/src";
import { ControllerDevReleaseFilesQueryState } from "./types";
import {
  getControllerDevReleaseFilesQueryTimeout,
  isControllerDevReleaseFileRequestActive,
  isControllerDevReleaseFilesRequestActive,
} from "./selectors";
import { prepareControllerDevReleaseFilesRequest } from "./utils";
import { QUERY_CHANGE_DELAY } from "../constants";
import { setSearchParams } from "@skydio/uri_util/src/query";

const { clearControllerDevReleaseFilesQueryTimeout, setControllerDevReleaseFilesQueryTimeout } =
  controllerDevReleaseFileActions;

export const updateControllerDevReleaseFilesQuery: ThunkCreator<
  ControllerDevReleaseFilesState,
  [ControllerDevReleaseFilesQueryState]
> = fields => (dispatch, getState) => {
  const state = getState();

  clearTimeout(getControllerDevReleaseFilesQueryTimeout(state)!);
  dispatch(clearControllerDevReleaseFilesQueryTimeout());

  const newArgs = prepareControllerDevReleaseFilesRequest(fields);
  const timeout = window?.setTimeout(
    () => dispatch(fetchControllerDevReleaseFiles(newArgs)),
    QUERY_CHANGE_DELAY
  );
  dispatch(setControllerDevReleaseFilesQueryTimeout(timeout));

  dispatch(setSearchParams(newArgs));
};

export const fetchControllerDevReleaseFilesIfAble: AsyncThunkCreator<
  ControllerDevReleaseFilesState,
  [ControllerDevReleaseFilesQueryState]
> = fields => async (dispatch, getState) => {
  const state = getState();
  if (!isControllerDevReleaseFilesRequestActive(state)) {
    dispatch(fetchControllerDevReleaseFiles(prepareControllerDevReleaseFilesRequest(fields)));
  }
};

export const fetchControllerDevReleaseFileIfAble: AsyncThunkCreator<
  ControllerDevReleaseFilesState,
  [string]
> = key => async (dispatch, getState) => {
  if (!isControllerDevReleaseFileRequestActive(getState(), key)) {
    dispatch(fetchControllerDevReleaseFile(key));
  }
};
