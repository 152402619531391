import { findKey, startCase, lowerCase } from "lodash";
import moment from "moment";

import {
  parseSearchState,
  makeQuerySerializable,
  convertToArray,
  convertToMoment,
  convertToNumber,
  convertToBoolean,
  convertToBooleanWithFalsy,
  convertFromMoment,
  convertFromBoolean,
} from "@skydio/uri_util/src/query";

import {
  FlightsQueryState,
  FlightsQueryParams,
  Flight,
  FlightStatsIdType,
  FlightStatsIdTypeKey,
  FlightStatsDuration,
  FlightsRequest,
  FlightRequest,
} from "./types";

export const flightsQueryInitialState: FlightsQueryState = {
  handle: "",
  vehicleId: "",
  userEmail: "",
  takeoffUserId: "",
  takeoffPhoneId: "",
  deployCommit: "",
  organizationId: "",
  releaseKey: "",
  userGroup: [],
  userNotGroup: [],
};

export const parseFlightsQueryState = (searchString: string) =>
  parseSearchState(searchString, flightsQueryInitialState, (params: FlightsQueryParams) => ({
    ...params,
    userGroup: convertToArray(params.userGroup),
    userNotGroup: convertToArray(params.userNotGroup),
    takeoffSince: convertToMoment(params.takeoffSince),
    takeoffBefore: convertToMoment(params.takeoffBefore),
    limit: convertToNumber(params.limit),
    hasDataFiles: convertToBoolean(params.hasDataFiles),
    perPage: convertToNumber(params.perPage),
    pageNumber: convertToNumber(params.pageNumber),
    orderAscending: convertToBoolean(params.orderAscending),
    includeInvalidFlights: convertToBooleanWithFalsy(params.includeInvalidFlights),
  }));

export const prepareFlightsRequest = (state: FlightsQueryState): FlightsRequest =>
  // @ts-ignore TS2322
  makeQuerySerializable(state, flightsQueryInitialState, state => ({
    ...state,
    takeoffSince: convertFromMoment(state.takeoffSince),
    takeoffBefore: convertFromMoment(state.takeoffBefore),
    hasDataFiles: convertFromBoolean(state.hasDataFiles),
    orderAscending: convertFromBoolean(state.orderAscending),
    includeInvalidFlights: convertFromBoolean(state.includeInvalidFlights),
  }));

export const prepareFlightRequest = (flightId: string): FlightRequest => {
  return { path: { flightId: flightId } };
};

export const getFlightDuration = ({ takeoffUtime, landingUtime }: Flight) =>
  moment.duration((landingUtime! - takeoffUtime!) / 1000000, "s");

export const formatFlightDuration = (flight: Flight, long = false) => {
  const duration = getFlightDuration(flight);
  const days = duration.days() > 0 ? `${duration.days()}d ` : "";
  const hours = duration.hours() > 0 ? `${duration.hours()}h ` : "";
  return `${days}${hours}${duration.minutes()}${long ? " min" : "m"} ${duration.seconds()}${
    long ? " sec" : "s"
  }`;
};

export const getStatsIdTypeKey = (idType: FlightStatsIdType) =>
  findKey(FlightStatsIdType, o => o === idType)! as FlightStatsIdTypeKey;

export const FLIGHT_STAT_RANGES_GROUP_BY: Record<FlightStatsDuration, FlightStatsIdType> = {
  day: FlightStatsIdType.HOUR,
  week: FlightStatsIdType.DAY,
  month: FlightStatsIdType.DAY,
  year: FlightStatsIdType.MONTH,
};

export const getDurationGranularity = (duration: keyof typeof FLIGHT_STAT_RANGES_GROUP_BY) =>
  startCase(lowerCase(getStatsIdTypeKey(FLIGHT_STAT_RANGES_GROUP_BY[duration])));
