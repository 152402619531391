import type { PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import Immer from "immer";

export interface APIRequestState {
  requestId?: string; // This is just in case we care to keep track of a unique per-request id
  active: boolean;
  success: boolean;
  error: SerializedError | null;
}

export const INITIAL_REQUEST_STATE: APIRequestState = {
  active: false,
  success: false,
  error: null,
};

interface SuccessPayload<T> {
  data: T;
}
interface FailurePayload {
  error: Error;
}
interface IndexedRequestPayload {
  requestIndex: string;
}
interface IndexedSuccessPayload<T> extends SuccessPayload<T>, IndexedRequestPayload {
  update?: boolean;
}
interface IndexedFailurePayload extends FailurePayload, IndexedRequestPayload {}

// Both of these utilites leverage `createSlice` from Redux Toolkit
// (https://redux-toolkit.js.org/api/createSlice) to generate actions and reducers together, which
// allows for super easy type safety. This function creates a 'slice' for managing the state of an
// API request - for example to fetch a batch of flights - including `pending`, `fulfilled`, and
// `rejected` actions to dispatch at each stage of performing the fetch, as well as a reducer that
// will respond to those actions and can be combined into any slice of the Redux state tree.
// See the README for concrete-ish usage examples.
export const createRequestSlice = <TSuccess>(name: string) =>
  createSlice({
    // Redux Toolkit prepends this name to the type constant of each generated action. In this case
    // they'll look like 'name/request/pending', etc.
    name: `${name}/request`,
    initialState: INITIAL_REQUEST_STATE,
    reducers: {
      // This handler defines the logical branch of the generated reducer that will respond to the
      // correspondingly generated `pending` action
      pending: () => ({
        ...INITIAL_REQUEST_STATE,
        active: true,
      }),
      // The key for this item still determines the name of the action (`fulfilled`), but we can also
      // define a `prepare` method alongside the reducer to do some transformation on the input
      // data before the action is dispatched. In this case we use it to wrap the value in an
      // object and add a timestamp to the payload.
      fulfilled: {
        reducer(state) {
          // Redux toolkit uses Immer for their reducers under the hood, so we can write mutative
          // code in here that will translate to immutable updates. (It uses a copy-on-write
          // mechanism to ensure that a new object will be created automatically with our changes)
          state.active = false;
          state.success = true;
        },
        // The resulting action creator will have the same arguments as this function
        prepare: (data: TSuccess) => ({ payload: { data } }),
      },
      // Basically the same as above
      rejected: {
        reducer(state, { payload }: PayloadAction<FailurePayload>) {
          state.active = false;
          state.error = payload.error;
        },
        prepare: (error: Error) => ({ payload: { error } }),
      },
    },
  });

// Pretty much the same idea as the previous function, except this one can be used to manage the
// state of requests that there may be multiple simultaneous instances of - rather than a batch of
// flights, for example, you could use this to manage many requests for individual flights. The
// underlying state operated on by the generated reducer will be a mapping of unique identifiers
// (specified when each action is dispatched) to the request state for that identifier.
export const createIndexedRequestSlice = <TSuccess>(name: string) =>
  createSlice({
    name: `${name}/request`,
    // Initial state is an empty map
    initialState: {} as { [requestIndex: string]: APIRequestState },
    reducers: {
      pending: {
        reducer(state, { payload }: PayloadAction<IndexedRequestPayload>) {
          // Again, "mutating" the state with Immer allows for more concise reducer logic
          state[payload.requestIndex] = { ...INITIAL_REQUEST_STATE, active: true };
        },
        // This argument could be a flight id or something similar
        prepare: (requestIndex: string) => ({ payload: { requestIndex } }),
      },
      // The rest of this is about the same as above, except we take the request index into account
      fulfilled: {
        reducer(state, { payload }: PayloadAction<IndexedSuccessPayload<TSuccess>>) {
          const requestState = state[payload.requestIndex]!;
          requestState.active = false;
          requestState.success = true;
        },
        // This `update` param is optional and unused here, but can be leveraged in other reducers
        // that might respond to this action.
        prepare: (requestIndex: string, data: TSuccess, update?: boolean) => ({
          payload: { requestIndex, data, update },
        }),
      },
      rejected: {
        reducer(state, { payload }: PayloadAction<IndexedFailurePayload>) {
          const requestState = state[payload.requestIndex]!;
          requestState.active = false;
          requestState.error = payload.error;
        },
        prepare: (requestIndex: string, error: Error) => ({ payload: { requestIndex, error } }),
      },
    },
  });
