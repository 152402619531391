import { createReducer, combineReducers } from "@reduxjs/toolkit";

import { createRequestReducerFromThunk } from "@skydio/redux_util/src";

import { fetchFileKinds } from "./asyncThunks";

const fileKinds = createReducer([] as string[], builder =>
  builder
    .addCase(fetchFileKinds.fulfilled, (_state, { payload }) => payload.map(kind_name => kind_name))
    .addCase(fetchFileKinds.rejected, () => [])
);

const reducer = combineReducers({
  fileKinds,
  request: createRequestReducerFromThunk(fetchFileKinds),
});
export type FileKindsState = ReturnType<typeof reducer>;
export default reducer;
