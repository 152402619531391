import { setSearchParams } from "@skydio/uri_util/src/query";

import { QUERY_CHANGE_DELAY } from "../constants";
import { fetchFlightDataFiles, fetchDeviceDataFiles } from "./asyncThunks";
import { getDataFilesQueryTimeout, isDataFileRequestActive } from "./selectors";
import { dataFileActions, DataFilesState } from "./slice";
import { prepareDataFilesRequest } from "./utils";

import { AsyncThunkCreator, ThunkCreator } from "@skydio/redux_util/src";
import { DataFilesQueryState } from "./types";

const { clearDataFilesQueryTimeout, setDataFilesQueryTimeout } = dataFileActions;

export const updateDataFilesQuery: ThunkCreator<DataFilesState, [DataFilesQueryState, boolean?]> =
  (fields, immediate) => (dispatch, getState) => {
    const newArgs = prepareDataFilesRequest(fields);
    if (immediate) {
      dispatch(fetchDeviceDataFiles(newArgs));
      dispatch(fetchFlightDataFiles(newArgs));
    } else {
      const state = getState();

      clearTimeout(getDataFilesQueryTimeout(state)!);
      dispatch(clearDataFilesQueryTimeout());

      const vehicleTimeout = window?.setTimeout(
        () => dispatch(fetchDeviceDataFiles(newArgs)),
        QUERY_CHANGE_DELAY
      );
      const flightTimeout = window?.setTimeout(
        () => dispatch(fetchFlightDataFiles(newArgs)),
        QUERY_CHANGE_DELAY
      );

      dispatch(setDataFilesQueryTimeout(vehicleTimeout + flightTimeout));
    }

    dispatch(setSearchParams(newArgs));
  };

export const updateFlightDataFilesQuery: ThunkCreator<
  DataFilesState,
  [DataFilesQueryState, boolean?]
> = (fields, immediate) => (dispatch, getState) => {
  const newArgs = prepareDataFilesRequest(fields);
  if (immediate) {
    dispatch(fetchFlightDataFiles(newArgs));
  } else {
    const state = getState();

    clearTimeout(getDataFilesQueryTimeout(state)!);
    dispatch(clearDataFilesQueryTimeout());

    const flightTimeout = window?.setTimeout(
      () => dispatch(fetchFlightDataFiles(newArgs)),
      QUERY_CHANGE_DELAY
    );

    dispatch(setDataFilesQueryTimeout(flightTimeout));
  }

  dispatch(setSearchParams(newArgs));
};

export const fetchDataFilesIfAble: AsyncThunkCreator<DataFilesState, [DataFilesQueryState]> =
  fields => async (dispatch, getState) => {
    if (!isDataFileRequestActive(getState())) {
      await dispatch(fetchDeviceDataFiles(prepareDataFilesRequest(fields)));
      await dispatch(fetchFlightDataFiles(prepareDataFilesRequest(fields)));
    }
  };
