import { createSlice, createReducer, combineReducers, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import { $CombinedState } from "@reduxjs/toolkit";

import {
  createIndexedRequestReducerFromThunk,
  createRequestReducerFromThunk,
} from "@skydio/redux_util/src";

import { fetchFlight, fetchFlights } from "../flights/asyncThunks";
import { createPaginationReducer } from "../pagination/utils";
import {
  downloadFlightDataFile,
  downloadDeviceDataFile,
  fetchFlightDataFiles,
  fetchDeviceDataFiles,
} from "./asyncThunks";

import { APIFlight } from "../flights/types";
import { DataFile, DataFilesMap, APIDataFile } from "./types";

const dataFileInitialState: DataFile = {
  uuid: "",
  kind: "",
  bucket: "",
  key: "",
  filename: "",
  sha1: "",
  size: 0,
  etag: "",
  encrypted: false,
  uploadUrl: "",
  downloadUrl: "",
  signature: "",
  bucketType: 0,
  uploaded: null,
  deleted: null,
  created: null,
  flightId: "",
  vehicleId: "",
  dockId: "",
  controllerId: "",
  accessPointSerial: "",
  userId: "",
  organizationId: "",
  mediaCapturedAt: 0,
  canDownload: false,
  fileUsage: 0,
};

const updateDataFilesFromFlights = (state: DataFilesMap, flights: APIFlight[]) => {
  flights.forEach(({ flightId, vehicleId, dataFilesList }) => {
    dataFilesList.forEach(dataFile => {
      if (!(dataFile.uuid in state)) {
        state[dataFile.uuid] = { ...dataFileInitialState };
      }

      Object.assign(state[dataFile.uuid]!, dataFile, {
        flightId,
        vehicleId,
        uploaded: dataFile.uploaded ? moment(dataFile.uploaded * 1000) : null,
      });
    });
  });
};

const updateDataFiles = (state: DataFilesMap, files: APIDataFile[]) => {
  files.forEach(dataFile => {
    if (!(dataFile.uuid in state)) {
      state[dataFile.uuid] = { ...dataFileInitialState };
    }

    Object.assign(state[dataFile.uuid]!, dataFile, {
      uploaded: dataFile.uploaded ? moment(dataFile.uploaded * 1000) : null,
      created: dataFile.created ? moment(dataFile.created * 1000) : null,
    });
  });
};

const updateDateFileUrls = (state: DataFilesMap, uuidUrls: [string, string][]) => {
  uuidUrls.forEach(mapping => {
    Object.assign(state[mapping[0]]!, { downloadUrl: mapping[1] });
  });
};

const { actions, reducer: dispatchTimeout } = createSlice({
  name: "dataFiles",
  initialState: null as number | null,
  reducers: {
    clearDataFilesQueryTimeout: () => null,
    setDataFilesQueryTimeout: (_state, { payload }: PayloadAction<number>) => payload,
  },
});

const flightDataRequestedIds = createReducer([] as string[], builder =>
  builder
    .addCase(fetchFlightDataFiles.fulfilled, (_state, { payload }) =>
      payload.dataFilesList.map(({ uuid }) => uuid)
    )
    .addCase(fetchFlightDataFiles.rejected, () => [])
);

const deviceDataRequestedIds = createReducer([] as string[], builder =>
  builder
    .addCase(fetchDeviceDataFiles.fulfilled, (_state, { payload }) =>
      payload.dataFilesList.map(({ uuid }) => uuid)
    )
    .addCase(fetchDeviceDataFiles.rejected, () => [])
);

const flightDataFiles = createReducer({} as DataFilesMap, builder =>
  builder
    .addCase(fetchFlights.fulfilled, (state, { payload }) =>
      updateDataFilesFromFlights(state as DataFilesMap, payload.flightsList)
    )
    .addCase(fetchFlight.fulfilled, (state, { payload }) =>
      updateDataFilesFromFlights(state as DataFilesMap, [payload])
    )
    .addCase(fetchFlightDataFiles.fulfilled, (state, { payload }) => {
      updateDataFiles(state as DataFilesMap, payload.dataFilesList);
      updateDateFileUrls(state as DataFilesMap, payload.fileUuidPublicUrlsMap);
    })
);

const deviceDataFiles = createReducer({} as DataFilesMap, builder =>
  builder.addCase(fetchDeviceDataFiles.fulfilled, (state, { payload }) => {
    updateDataFiles(state as DataFilesMap, payload.dataFilesList);
    updateDateFileUrls(state as DataFilesMap, payload.fileUuidPublicUrlsMap);
  })
);

const reducer = combineReducers({
  dispatchTimeout,
  flightDataFiles,
  deviceDataFiles,
  flightDataRequestedIds,
  deviceDataRequestedIds,
  flightPagination: createPaginationReducer(fetchFlightDataFiles.fulfilled),
  devicePagination: createPaginationReducer(fetchDeviceDataFiles.fulfilled),
  requests: combineReducers({
    deviceDataFiles: createRequestReducerFromThunk(fetchDeviceDataFiles),
    flightDataFiles: createRequestReducerFromThunk(fetchFlightDataFiles),
  }),
  flightDataDownloads: createIndexedRequestReducerFromThunk(downloadFlightDataFile),
  deviceDataDownloads: createIndexedRequestReducerFromThunk(downloadDeviceDataFile),
});
export type DataFilesState = Omit<ReturnType<typeof reducer>, typeof $CombinedState>;

export { actions as dataFileActions };
export default reducer;
