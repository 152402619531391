import { createAsyncThunk } from "@reduxjs/toolkit";
import field_mask_pb from "google-protobuf/google/protobuf/field_mask_pb";

import vehicle_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/vehicle_pb";

import endpoints from "../endpoints";
import { sendRequest } from "../requests-browser";

import { VehiclesRequest, VehicleUpdate } from "./types";

export const fetchVehicles = createAsyncThunk(
  "vehicles/fetchAll",
  async (args: VehiclesRequest) => {
    const vehicles = await sendRequest(endpoints.GET_VEHICLES, args);
    return vehicles.toObject();
  }
);

export const fetchVehicle = createAsyncThunk("vehicles/fetchOne", async (vehicleId: string) => {
  const vehicle = await sendRequest(endpoints.GET_VEHICLE, { path: { vehicleId } });
  return vehicle.toObject();
});

export interface UpdateArgs {
  vehicleId: string;
  update: VehicleUpdate;
}

export const updateVehicle = createAsyncThunk(
  "vehicles/update",
  async ({ vehicleId, update }: UpdateArgs) => {
    const vehicleProto = new vehicle_pb.Vehicle();
    const updateMask = new field_mask_pb.FieldMask();

    if ("organizationId" in update) {
      updateMask.setPathsList(["organization_id"]);
      if (update.organizationId) vehicleProto.setOrganizationId(update.organizationId);
    }
    if ("disabled" in update) {
      updateMask.setPathsList(["disabled"]);
      if (update.disabled) vehicleProto.setDisabled(update.disabled);
    }
    const updateProto = new vehicle_pb.UpdateVehicleRequest();
    updateProto.setVehicle(vehicleProto);
    updateProto.setUpdateMask(updateMask);

    const vehicle = await sendRequest(endpoints.UPDATE_VEHICLE, {
      path: { vehicleId },
      protobuf: updateProto,
    });
    return vehicle.toObject();
  }
);
