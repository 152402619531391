import { setSearchParams } from "@skydio/uri_util/src/query";

import { QUERY_CHANGE_DELAY } from "../constants";
import { fetchFlights, fetchFlight, downloadFlightsCsv } from "./asyncThunks";
import {
  getFlightsQueryTimeout,
  isFlightsRequestActive,
  isFlightRequestActive,
  getRequestHasChanged,
} from "./selectors";
import { flightActions, FlightsState } from "./slice";
import { prepareFlightsRequest } from "./utils";

import { AsyncThunkCreator, ThunkCreator } from "@skydio/redux_util/src";
import { FlightsQueryState } from "./types";

const { clearFlightsQueryTimeout, setFlightsQueryTimeout, setRequest } = flightActions;

export const updateFlightsQuery: ThunkCreator<
  FlightsState,
  [FlightsQueryState, boolean?, boolean?]
> = (fields, immediate) => (dispatch, getState) => {
  const newArgs = prepareFlightsRequest(fields);
  const state = getState();
  if (!getRequestHasChanged(state, newArgs)) {
    return;
  }
  dispatch(setRequest(newArgs));

  if (immediate) {
    dispatch(fetchFlights(newArgs));
  } else {
    clearTimeout(getFlightsQueryTimeout(state)!);
    dispatch(clearFlightsQueryTimeout());

    const timeout = window?.setTimeout(() => dispatch(fetchFlights(newArgs)), QUERY_CHANGE_DELAY);
    dispatch(setFlightsQueryTimeout(timeout));
  }

  dispatch(setSearchParams(newArgs));
};

export const fetchFlightsIfAble: AsyncThunkCreator<FlightsState, [FlightsQueryState]> =
  fields => async (dispatch, getState) => {
    const state = getState();
    const newArgs = prepareFlightsRequest(fields);
    if (!getRequestHasChanged(state, newArgs)) {
      return;
    }
    if (!isFlightsRequestActive(state)) {
      dispatch(setRequest(newArgs));
      await dispatch(fetchFlights(prepareFlightsRequest(fields)));
    }
  };

export const fetchFlightIfAble: AsyncThunkCreator<FlightsState, [string]> =
  flightId => async (dispatch, getState) => {
    if (!isFlightRequestActive(getState(), flightId)) {
      await dispatch(fetchFlight({ flightId }));
    }
  };

export const downloadFlightsIfAble: AsyncThunkCreator<FlightsState, [FlightsQueryState]> =
  fields => async (dispatch, getState) => {
    const state = getState();
    const newArgs = prepareFlightsRequest(fields);
    if (!isFlightsRequestActive(state)) {
      dispatch(setRequest(newArgs));
      await dispatch(downloadFlightsCsv(prepareFlightsRequest(fields)));
    }
  };
