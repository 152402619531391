import {
  parseSearchState,
  makeQuerySerializable,
  convertToNumber,
  convertToArray,
} from "@skydio/uri_util/src/query";

import { ControllerReleasesQueryState, ControllerReleasesQueryParams } from "./types";

export const controllerReleasesQueryInitialState: ControllerReleasesQueryState = {
  releaseKey: "",
  minVersion: "",
  maxVersion: "",
  group: [],
  notGroup: [],
  description: "",
};

export const parseControllerReleasesQueryState = (searchString: string) =>
  parseSearchState(
    searchString,
    controllerReleasesQueryInitialState,
    (params: ControllerReleasesQueryParams) => ({
      ...params,
      group: convertToArray(params.group),
      notGroup: convertToArray(params.notGroup),
      limit: convertToNumber(params.limit),
      perPage: convertToNumber(params.perPage),
      pageNumber: convertToNumber(params.pageNumber),
    })
  );

export const prepareControllerReleasesRequest = (state: ControllerReleasesQueryState) =>
  makeQuerySerializable(state, controllerReleasesQueryInitialState, controllerReleaseState => {
    return controllerReleaseState;
  });
