import { createAsyncThunk } from "@reduxjs/toolkit";

import endpoints from "../endpoints";
import { sendRequest } from "../requests-browser";

export const fetchGroups = createAsyncThunk(
  "groups/fetchAll",
  async (include_group_metadata: boolean = true) => {
    const args = include_group_metadata ? { include_group_metadata } : undefined;
    const { groups } = await sendRequest(endpoints.GET_GROUPS, args);
    return groups;
  }
);
