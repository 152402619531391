import _ from "lodash";
import { createSelector } from "reselect";

import { ControllerDevReleaseFilesState } from "./slice";
import { ControllerDevReleaseFile } from "./types";
import { getUploadedSortFunc } from "../releases/utils";

export const getAllControllerDevReleaseFiles = ({ state }: ControllerDevReleaseFilesState) =>
  state.controllerDevReleaseFiles;

export const getControllerDevReleaseFile = (state: ControllerDevReleaseFilesState, key: string) =>
  getAllControllerDevReleaseFiles(state)[key]!;

export const getControllerDevReleaseFilesRequest = ({ requests }: ControllerDevReleaseFilesState) =>
  requests.controllerDevReleaseFiles;

export const isControllerDevReleaseFilesRequestActive = (state: ControllerDevReleaseFilesState) =>
  getControllerDevReleaseFilesRequest(state).active;

export const getControllerDevReleaseFileRequest = (
  { requests }: ControllerDevReleaseFilesState,
  key: string
) => requests.controllerDevReleaseFile[key];

export const isControllerDevReleaseFileRequestActive = (
  state: ControllerDevReleaseFilesState,
  key: string
) => getControllerDevReleaseFileRequest(state, key)?.active;

export const getControllerDevReleaseFileRequestError = (
  state: ControllerDevReleaseFilesState,
  key: string
) => getControllerDevReleaseFileRequest(state, key)?.error;

export const getControllerDevReleaseFilesQueryTimeout = ({
  state,
}: ControllerDevReleaseFilesState) => state.dispatchTimeout;

export const getControllerDevReleaseFileRequestKeys = ({ state }: ControllerDevReleaseFilesState) =>
  state.requestedIds;

function setControllerDevReleaseBuildInfo(release: ControllerDevReleaseFile) {
  if (release.manifest) {
    const parsedManifest = JSON.parse(JSON.parse(release.manifest)); // double parsing needing due to how the data is stored
    const fpInfo = parsedManifest?.fpInfo;

    return {
      ...release,
      branch: fpInfo?.gitBranch,
      builder: fpInfo?.gitAuthorName,
      commit: fpInfo?.gitCommit,
    };
  }

  return release;
}

const uploadedSort = getUploadedSortFunc<ControllerDevReleaseFile>("uploaded");

export const getControllerDevReleaseFilesForRequest = createSelector(
  getControllerDevReleaseFileRequestKeys,
  getAllControllerDevReleaseFiles,
  (controllerReleaseKeys, allControllerReleases: { [key: string]: ControllerDevReleaseFile }) =>
    controllerReleaseKeys
      .map(key => setControllerDevReleaseBuildInfo(allControllerReleases[key]!))
      .sort(uploadedSort)
);

export const getPagination = ({ state }: ControllerDevReleaseFilesState) => state.pagination;
