import { createSelector } from "reselect";
import { max } from "lodash";

import { DataFilesState } from "./slice";
import { paginationStateToProps } from "../pagination/utils";
import type { DataFile } from "./types";

export const getDataFilesQueryTimeout = (state: DataFilesState) => state.dispatchTimeout;

export const getAllDataFiles = createSelector(
  (state: DataFilesState) => state.flightDataFiles,
  (state: DataFilesState) => state.deviceDataFiles,
  (flightDataFiles, deviceDataFiles) => ({
    ...flightDataFiles,
    ...deviceDataFiles,
  })
);

export const getDataFile = (state: DataFilesState, uuid: string) => getAllDataFiles(state)[uuid];

export const getDataFilesFromIds = (state: DataFilesState, uuids: string[]) =>
  (uuids.map(uuid => getDataFile(state, uuid)) as DataFile[]).sort((a, b) =>
    a.filename?.localeCompare(b.filename)
  );

export const getFlightDataFilesRequestIds = (state: DataFilesState) => state.flightDataRequestedIds;
export const getDataFilesRequestIds = (state: DataFilesState) =>
  state.flightDataRequestedIds.concat(state.deviceDataRequestedIds);

export const getFlightDataFilesForRequest = createSelector(
  getFlightDataFilesRequestIds,
  getAllDataFiles,
  (uuids, allDataFiles) => uuids.map(uuid => allDataFiles[uuid])
);

export const getDataFilesForRequest = createSelector(
  getDataFilesRequestIds,
  getAllDataFiles,
  (uuids, allDataFiles) => uuids.map(uuid => allDataFiles[uuid])
);

export const getDeviceDataFilesRequest = (state: DataFilesState) => state.requests.deviceDataFiles;
export const getFlightDataFilesRequest = (state: DataFilesState) => state.requests.flightDataFiles;

export const isDataFileRequestActive = (state: DataFilesState) =>
  getDeviceDataFilesRequest(state).active || getFlightDataFilesRequest(state).active;

export const getFlightDataFileDownload = (state: DataFilesState, uuid: string) =>
  state.flightDataDownloads[uuid];
export const getDeviceDataFileDownload = (state: DataFilesState, uuid: string) =>
  state.deviceDataDownloads[uuid];

export const getFlightDataFileDownloads = (state: DataFilesState) => state.flightDataDownloads;
export const getDeviceDataFileDownloads = (state: DataFilesState) => state.deviceDataDownloads;

export const getFlightPagination = (state: DataFilesState) => state.flightPagination;
export const getDevicePagination = (state: DataFilesState) => state.devicePagination;

// NOTE(jesse): This is a hack to handle the combining of two different pagination schemes.
// It results in maxPerPage being incorrect,
// but maxPerPage will always be at least half of the number of actual results returned per page
export const getDataFilesPagination = (state: DataFilesState) => {
  const flightPagination = getFlightPagination(state);
  const devicePagination = getDevicePagination(state);
  return {
    currentPage: max([flightPagination.currentPage, devicePagination.currentPage]) || 1,
    totalPages: max([flightPagination.totalPages, devicePagination.totalPages])!,
    maxPerPage: max([flightPagination.maxPerPage, devicePagination.maxPerPage])!,
  };
};

export const getDataFilesPaginationProps = createSelector(
  getDataFilesPagination,
  paginationStateToProps
);

export const getFlightDataFilesPaginationProps = createSelector(
  getFlightPagination,
  paginationStateToProps
);
