import struct_pb from "google-protobuf/google/protobuf/struct_pb";

import organization_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/organization_pb";
import enums_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/enums_pb";

export const NEW_ORG_PLACEHOLDER_ID = "NEW_ORG_PLACEHOLDER_ID";

export type OrganizationPermission = enums_pb.OrgPermission.PermEnum;
export type DataLevel = enums_pb.DataLevel.LevelEnum;
export type OrganizationStats = organization_pb.OrganizationStats.AsObject;
export type OrganizationTier = enums_pb.OrgTier.OrgTierEnum;
export type OrganizationPilotStats = organization_pb.OrganizationPilotStats.AsObject;
export type OrganizationStorageStats = organization_pb.StorageStats.AsObject;
export type OrganizationLogoUploadRequest = organization_pb.OrganizationLogoUploadRequest;

export type NewOrganization = {
  name: string;
  dataLevel: DataLevel;
  orgTier: OrganizationTier
};

export type APIOrganization = organization_pb.Organization.AsObject;

export type OrganizationUpdate = Partial<Omit<APIOrganization, "uuid">>;

export interface Organization extends APIOrganization {
  modified: OrganizationUpdate;
}

export interface OrganizationsMap {
  [name: string]: Organization;
}

export interface OrganizationsState {
  organizations: OrganizationsMap;
  storageStats: OrganizationStorageStats;
  pilotStats: OrganizationPilotStats;
}

export interface UpdateOrganizationRequest {
  path: { uuid: string };
  protobuf?: organization_pb.UpdateOrganizationRequest;
}

export interface DeleteOrganizationRequest {
  path: { uuid: string };
  protobuf?: struct_pb.Struct;
}

export interface InviteUserRequest {
  path: { uuid: string; email: string };
  protobuf?: organization_pb.InviteUserToOrganizationRequest;
}

export interface AcceptInviteRequest {
  path: { jwtPayload: string };
}

export interface RemoveUserRequest {
  path: { uuid: string; email: string };
}

export interface GetOrganizationStatsRequest {
  path: { organizationId: string };
}

export interface UploadLogoRequest {
  path: { organizationId: string };
  protobuf?: organization_pb.OrganizationLogoUploadRequest;
}
