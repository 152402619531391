import {
  parseSearchState,
  makeQuerySerializable,
  convertToNumber,
  convertToBoolean,
  convertFromBoolean,
} from "@skydio/uri_util/src/query";

import { SimulatorsQueryState, SimulatorsQueryParams } from "./types";

export const simulatorsQueryInitialState: SimulatorsQueryState = {
  userEmail: "",
  userUuid: "",
  host: "",
  region: "",
};

export const parseSimulatorsQueryState = (searchString: string) =>
  parseSearchState(searchString, simulatorsQueryInitialState, (params: SimulatorsQueryParams) => ({
    ...params,
    pooled: convertToBoolean(params.pooled),
    limit: convertToNumber(params.limit),
    pageNumber: convertToNumber(params.pageNumber),
    perPage: convertToNumber(params.perPage),
  }));

export const prepareSimulatorsRequest = (state: SimulatorsQueryState) =>
  makeQuerySerializable(state, simulatorsQueryInitialState, state => ({
    ...state,
    pooled: convertFromBoolean(state.pooled),
  }));
