import _ from "lodash";
import { createSelector } from "reselect";
import Immer from "immer";

import { ControllerReleasesState } from "./slice";
import { ControllerRelease } from "./types";
import { getVersionSortFunc } from "../releases/utils";

export const getAllControllerReleases = ({ state }: ControllerReleasesState) =>
  state.controllerReleases;

export const getControllerRelease = (state: ControllerReleasesState, key: string) =>
  getAllControllerReleases(state)[key]!;

export const getControllerReleasesRequest = ({ requests }: ControllerReleasesState) =>
  requests.controllerReleases;

export const isControllerReleasesRequestActive = (state: ControllerReleasesState) =>
  getControllerReleasesRequest(state).active;

export const getControllerReleaseRequest = ({ requests }: ControllerReleasesState, key: string) =>
  requests.controllerRelease[key];

export const isControllerReleaseRequestActive = (state: ControllerReleasesState, key: string) =>
  getControllerReleaseRequest(state, key)?.active;

export const getControllerReleaseRequestError = (state: ControllerReleasesState, key: string) =>
  getControllerReleaseRequest(state, key)?.error;

export const getControllerReleaseModifications = (state: ControllerReleasesState, key: string) =>
  // @ts-ignore TS2339
  getControllerRelease(state, key).modified;

export const getControllerReleasesQueryTimeout = ({ state }: ControllerReleasesState) =>
  state.dispatchTimeout;

export const getControllerReleaseRequestKeys = ({ state }: ControllerReleasesState) =>
  state.requestedIds;

export const doesControllerReleaseNeedUpdate = (state: ControllerReleasesState, key: string) =>
  // @ts-ignore TS2339
  _.keys(getControllerRelease(state, key)?.modified ?? {}).length > 0;

export const updateControllerReleaseOverrideRequestError = (
  state: ControllerReleasesState,
  key: string
) => state.requests.controllerReleaseOverrides[key]?.error;

export const updateControllerReleaseUserOverrideRequestError = (
  state: ControllerReleasesState,
  key: string
) => state.requests.controllerReleaseUserOverrides[key]?.error;

const versionSort = getVersionSortFunc<ControllerRelease>("version");

export const getControllerReleasesForRequest = createSelector(
  getControllerReleaseRequestKeys,
  getAllControllerReleases,
  (controllerReleaseKeys, allControllerReleases) =>
    controllerReleaseKeys.map(key => allControllerReleases[key]!).sort(versionSort)
);

export const getPagination = ({ state }: ControllerReleasesState) => state.pagination;
