import { createAsyncThunk } from "@reduxjs/toolkit";

import { download } from "../../../common/http";
import endpoints from "../endpoints";
import { sendRequest } from "../requests-browser";

import files_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/files_pb";

import { DataFilesRequest } from "./types";

export const downloadFlightDataFile = createAsyncThunk(
  "flightDataFiles/download",
  async (uuid: string) => {
    const { dataFile } = await sendRequest(endpoints.GET_FLIGHT_DATA_FILE, { path: { uuid } });
    download(dataFile.downloadUrl);
  }
);

export const downloadZipFlightDataFiles = createAsyncThunk(
  "flightDataFiles/downloadZip",
  async (uuids: string[]) => {
    const requestProto = new files_pb.DownloadFilesRequest();
    requestProto.setFileUuidsList(uuids);
    const response = await sendRequest(endpoints.DOWNLOAD_FLIGHT_FILES_ZIP, {
      protobuf: requestProto,
    });
    const blob = await response.blob();
    const inMemoryURL = URL.createObjectURL(blob);
    download(inMemoryURL, "skydio-media.zip");
    URL.revokeObjectURL(inMemoryURL);
  }
);

export const downloadDeviceDataFile = createAsyncThunk(
  "deviceDataFiles/download",
  async (uuid: string) => {
    const { dataFile } = await sendRequest(endpoints.GET_DEVICE_DATA_FILE, { path: { uuid } });
    download(dataFile.downloadUrl);
  }
);

export const fetchDeviceDataFiles = createAsyncThunk(
  "deviceDataFiles/fetchAll",
  async (args: DataFilesRequest) => {
    const response = await sendRequest(endpoints.GET_DEVICE_DATA_FILES, args);
    return response.toObject();
  }
);

export const fetchFlightDataFiles = createAsyncThunk(
  "flightDataFiles/fetchAll",
  async (args: DataFilesRequest) => {
    const response = await sendRequest(endpoints.GET_FLIGHT_DATA_FILES, args);
    return response.toObject();
  }
);
