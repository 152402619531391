// source: pbtypes/vehicle/alerting/alert.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_vehicle_skills_mission_pb = require('../../../pbtypes/vehicle/skills/mission_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_mission_pb);
goog.exportSymbol('proto.alerting.AlertContext', null, global);
goog.exportSymbol('proto.alerting.DeviceAlert', null, global);
goog.exportSymbol('proto.alerting.DeviceAlert.AlertDetailsCase', null, global);
goog.exportSymbol('proto.alerting.DeviceAlert.AlertTypeEnum', null, global);
goog.exportSymbol('proto.alerting.DockErrorAlert', null, global);
goog.exportSymbol('proto.alerting.DockErrorAlert.DockErrorTypeEnum', null, global);
goog.exportSymbol('proto.alerting.HumanDetectedAlert', null, global);
goog.exportSymbol('proto.alerting.MissionIncompleteAlert', null, global);
goog.exportSymbol('proto.alerting.PositionXYZ', null, global);
goog.exportSymbol('proto.alerting.ScheduledMissionFailedTakeoffAlert', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.alerting.DeviceAlert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.alerting.DeviceAlert.oneofGroups_);
};
goog.inherits(proto.alerting.DeviceAlert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.alerting.DeviceAlert.displayName = 'proto.alerting.DeviceAlert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.alerting.ScheduledMissionFailedTakeoffAlert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.alerting.ScheduledMissionFailedTakeoffAlert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.alerting.ScheduledMissionFailedTakeoffAlert.displayName = 'proto.alerting.ScheduledMissionFailedTakeoffAlert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.alerting.MissionIncompleteAlert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.alerting.MissionIncompleteAlert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.alerting.MissionIncompleteAlert.displayName = 'proto.alerting.MissionIncompleteAlert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.alerting.PositionXYZ = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.alerting.PositionXYZ, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.alerting.PositionXYZ.displayName = 'proto.alerting.PositionXYZ';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.alerting.HumanDetectedAlert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.alerting.HumanDetectedAlert.repeatedFields_, null);
};
goog.inherits(proto.alerting.HumanDetectedAlert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.alerting.HumanDetectedAlert.displayName = 'proto.alerting.HumanDetectedAlert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.alerting.DockErrorAlert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.alerting.DockErrorAlert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.alerting.DockErrorAlert.displayName = 'proto.alerting.DockErrorAlert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.alerting.AlertContext = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.alerting.AlertContext, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.alerting.AlertContext.displayName = 'proto.alerting.AlertContext';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.alerting.DeviceAlert.oneofGroups_ = [[2,3,4,6]];

/**
 * @enum {number}
 */
proto.alerting.DeviceAlert.AlertDetailsCase = {
  ALERT_DETAILS_NOT_SET: 0,
  HUMAN_DETECTED_ALERT: 2,
  MISSION_INCOMPLETE_ALERT: 3,
  DOCK_ERROR_ALERT: 4,
  SCHEDULED_MISSION_FAILED_TAKEOFF_ALERT: 6
};

/**
 * @return {proto.alerting.DeviceAlert.AlertDetailsCase}
 */
proto.alerting.DeviceAlert.prototype.getAlertDetailsCase = function() {
  return /** @type {proto.alerting.DeviceAlert.AlertDetailsCase} */(jspb.Message.computeOneofCase(this, proto.alerting.DeviceAlert.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.alerting.DeviceAlert.prototype.toObject = function(opt_includeInstance) {
  return proto.alerting.DeviceAlert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.alerting.DeviceAlert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.alerting.DeviceAlert.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 7, 0),
type: jspb.Message.getFieldWithDefault(msg, 1, 0),
humanDetectedAlert: (f = msg.getHumanDetectedAlert()) && proto.alerting.HumanDetectedAlert.toObject(includeInstance, f),
missionIncompleteAlert: (f = msg.getMissionIncompleteAlert()) && proto.alerting.MissionIncompleteAlert.toObject(includeInstance, f),
dockErrorAlert: (f = msg.getDockErrorAlert()) && proto.alerting.DockErrorAlert.toObject(includeInstance, f),
scheduledMissionFailedTakeoffAlert: (f = msg.getScheduledMissionFailedTakeoffAlert()) && proto.alerting.ScheduledMissionFailedTakeoffAlert.toObject(includeInstance, f),
alertContext: (f = msg.getAlertContext()) && proto.alerting.AlertContext.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.alerting.DeviceAlert}
 */
proto.alerting.DeviceAlert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.alerting.DeviceAlert;
  return proto.alerting.DeviceAlert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.alerting.DeviceAlert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.alerting.DeviceAlert}
 */
proto.alerting.DeviceAlert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 1:
      var value = /** @type {!proto.alerting.DeviceAlert.AlertTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.alerting.HumanDetectedAlert;
      reader.readMessage(value,proto.alerting.HumanDetectedAlert.deserializeBinaryFromReader);
      msg.setHumanDetectedAlert(value);
      break;
    case 3:
      var value = new proto.alerting.MissionIncompleteAlert;
      reader.readMessage(value,proto.alerting.MissionIncompleteAlert.deserializeBinaryFromReader);
      msg.setMissionIncompleteAlert(value);
      break;
    case 4:
      var value = new proto.alerting.DockErrorAlert;
      reader.readMessage(value,proto.alerting.DockErrorAlert.deserializeBinaryFromReader);
      msg.setDockErrorAlert(value);
      break;
    case 6:
      var value = new proto.alerting.ScheduledMissionFailedTakeoffAlert;
      reader.readMessage(value,proto.alerting.ScheduledMissionFailedTakeoffAlert.deserializeBinaryFromReader);
      msg.setScheduledMissionFailedTakeoffAlert(value);
      break;
    case 5:
      var value = new proto.alerting.AlertContext;
      reader.readMessage(value,proto.alerting.AlertContext.deserializeBinaryFromReader);
      msg.setAlertContext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.alerting.DeviceAlert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.alerting.DeviceAlert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.alerting.DeviceAlert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.alerting.DeviceAlert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getHumanDetectedAlert();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.alerting.HumanDetectedAlert.serializeBinaryToWriter
    );
  }
  f = message.getMissionIncompleteAlert();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.alerting.MissionIncompleteAlert.serializeBinaryToWriter
    );
  }
  f = message.getDockErrorAlert();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.alerting.DockErrorAlert.serializeBinaryToWriter
    );
  }
  f = message.getScheduledMissionFailedTakeoffAlert();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.alerting.ScheduledMissionFailedTakeoffAlert.serializeBinaryToWriter
    );
  }
  f = message.getAlertContext();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.alerting.AlertContext.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.alerting.DeviceAlert.AlertTypeEnum = {
  UNKNOWN: 0,
  HUMAN_DETECTED: 1,
  MISSION_INCOMPLETE: 2,
  DOCK_ERROR: 3,
  SCHEDULED_MISSION_FAILED_TAKEOFF: 4,
  FLIGHT_STATUS: 5,
  WAYPOINT_PROGRESS: 6
};

/**
 * optional int64 utime = 7;
 * @return {number}
 */
proto.alerting.DeviceAlert.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.alerting.DeviceAlert} returns this
 */
proto.alerting.DeviceAlert.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional AlertTypeEnum type = 1;
 * @return {!proto.alerting.DeviceAlert.AlertTypeEnum}
 */
proto.alerting.DeviceAlert.prototype.getType = function() {
  return /** @type {!proto.alerting.DeviceAlert.AlertTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.alerting.DeviceAlert.AlertTypeEnum} value
 * @return {!proto.alerting.DeviceAlert} returns this
 */
proto.alerting.DeviceAlert.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional HumanDetectedAlert human_detected_alert = 2;
 * @return {?proto.alerting.HumanDetectedAlert}
 */
proto.alerting.DeviceAlert.prototype.getHumanDetectedAlert = function() {
  return /** @type{?proto.alerting.HumanDetectedAlert} */ (
    jspb.Message.getWrapperField(this, proto.alerting.HumanDetectedAlert, 2));
};


/**
 * @param {?proto.alerting.HumanDetectedAlert|undefined} value
 * @return {!proto.alerting.DeviceAlert} returns this
*/
proto.alerting.DeviceAlert.prototype.setHumanDetectedAlert = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.alerting.DeviceAlert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.alerting.DeviceAlert} returns this
 */
proto.alerting.DeviceAlert.prototype.clearHumanDetectedAlert = function() {
  return this.setHumanDetectedAlert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.alerting.DeviceAlert.prototype.hasHumanDetectedAlert = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MissionIncompleteAlert mission_incomplete_alert = 3;
 * @return {?proto.alerting.MissionIncompleteAlert}
 */
proto.alerting.DeviceAlert.prototype.getMissionIncompleteAlert = function() {
  return /** @type{?proto.alerting.MissionIncompleteAlert} */ (
    jspb.Message.getWrapperField(this, proto.alerting.MissionIncompleteAlert, 3));
};


/**
 * @param {?proto.alerting.MissionIncompleteAlert|undefined} value
 * @return {!proto.alerting.DeviceAlert} returns this
*/
proto.alerting.DeviceAlert.prototype.setMissionIncompleteAlert = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.alerting.DeviceAlert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.alerting.DeviceAlert} returns this
 */
proto.alerting.DeviceAlert.prototype.clearMissionIncompleteAlert = function() {
  return this.setMissionIncompleteAlert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.alerting.DeviceAlert.prototype.hasMissionIncompleteAlert = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DockErrorAlert dock_error_alert = 4;
 * @return {?proto.alerting.DockErrorAlert}
 */
proto.alerting.DeviceAlert.prototype.getDockErrorAlert = function() {
  return /** @type{?proto.alerting.DockErrorAlert} */ (
    jspb.Message.getWrapperField(this, proto.alerting.DockErrorAlert, 4));
};


/**
 * @param {?proto.alerting.DockErrorAlert|undefined} value
 * @return {!proto.alerting.DeviceAlert} returns this
*/
proto.alerting.DeviceAlert.prototype.setDockErrorAlert = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.alerting.DeviceAlert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.alerting.DeviceAlert} returns this
 */
proto.alerting.DeviceAlert.prototype.clearDockErrorAlert = function() {
  return this.setDockErrorAlert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.alerting.DeviceAlert.prototype.hasDockErrorAlert = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ScheduledMissionFailedTakeoffAlert scheduled_mission_failed_takeoff_alert = 6;
 * @return {?proto.alerting.ScheduledMissionFailedTakeoffAlert}
 */
proto.alerting.DeviceAlert.prototype.getScheduledMissionFailedTakeoffAlert = function() {
  return /** @type{?proto.alerting.ScheduledMissionFailedTakeoffAlert} */ (
    jspb.Message.getWrapperField(this, proto.alerting.ScheduledMissionFailedTakeoffAlert, 6));
};


/**
 * @param {?proto.alerting.ScheduledMissionFailedTakeoffAlert|undefined} value
 * @return {!proto.alerting.DeviceAlert} returns this
*/
proto.alerting.DeviceAlert.prototype.setScheduledMissionFailedTakeoffAlert = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.alerting.DeviceAlert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.alerting.DeviceAlert} returns this
 */
proto.alerting.DeviceAlert.prototype.clearScheduledMissionFailedTakeoffAlert = function() {
  return this.setScheduledMissionFailedTakeoffAlert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.alerting.DeviceAlert.prototype.hasScheduledMissionFailedTakeoffAlert = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional AlertContext alert_context = 5;
 * @return {?proto.alerting.AlertContext}
 */
proto.alerting.DeviceAlert.prototype.getAlertContext = function() {
  return /** @type{?proto.alerting.AlertContext} */ (
    jspb.Message.getWrapperField(this, proto.alerting.AlertContext, 5));
};


/**
 * @param {?proto.alerting.AlertContext|undefined} value
 * @return {!proto.alerting.DeviceAlert} returns this
*/
proto.alerting.DeviceAlert.prototype.setAlertContext = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.alerting.DeviceAlert} returns this
 */
proto.alerting.DeviceAlert.prototype.clearAlertContext = function() {
  return this.setAlertContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.alerting.DeviceAlert.prototype.hasAlertContext = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.alerting.ScheduledMissionFailedTakeoffAlert.prototype.toObject = function(opt_includeInstance) {
  return proto.alerting.ScheduledMissionFailedTakeoffAlert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.alerting.ScheduledMissionFailedTakeoffAlert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.alerting.ScheduledMissionFailedTakeoffAlert.toObject = function(includeInstance, msg) {
  var f, obj = {
result: (f = msg.getResult()) && pbtypes_vehicle_skills_mission_pb.MissionResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.alerting.ScheduledMissionFailedTakeoffAlert}
 */
proto.alerting.ScheduledMissionFailedTakeoffAlert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.alerting.ScheduledMissionFailedTakeoffAlert;
  return proto.alerting.ScheduledMissionFailedTakeoffAlert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.alerting.ScheduledMissionFailedTakeoffAlert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.alerting.ScheduledMissionFailedTakeoffAlert}
 */
proto.alerting.ScheduledMissionFailedTakeoffAlert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_vehicle_skills_mission_pb.MissionResult;
      reader.readMessage(value,pbtypes_vehicle_skills_mission_pb.MissionResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.alerting.ScheduledMissionFailedTakeoffAlert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.alerting.ScheduledMissionFailedTakeoffAlert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.alerting.ScheduledMissionFailedTakeoffAlert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.alerting.ScheduledMissionFailedTakeoffAlert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_vehicle_skills_mission_pb.MissionResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional skills.MissionResult result = 1;
 * @return {?proto.skills.MissionResult}
 */
proto.alerting.ScheduledMissionFailedTakeoffAlert.prototype.getResult = function() {
  return /** @type{?proto.skills.MissionResult} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_mission_pb.MissionResult, 1));
};


/**
 * @param {?proto.skills.MissionResult|undefined} value
 * @return {!proto.alerting.ScheduledMissionFailedTakeoffAlert} returns this
*/
proto.alerting.ScheduledMissionFailedTakeoffAlert.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.alerting.ScheduledMissionFailedTakeoffAlert} returns this
 */
proto.alerting.ScheduledMissionFailedTakeoffAlert.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.alerting.ScheduledMissionFailedTakeoffAlert.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.alerting.MissionIncompleteAlert.prototype.toObject = function(opt_includeInstance) {
  return proto.alerting.MissionIncompleteAlert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.alerting.MissionIncompleteAlert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.alerting.MissionIncompleteAlert.toObject = function(includeInstance, msg) {
  var f, obj = {
result: (f = msg.getResult()) && pbtypes_vehicle_skills_mission_pb.MissionResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.alerting.MissionIncompleteAlert}
 */
proto.alerting.MissionIncompleteAlert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.alerting.MissionIncompleteAlert;
  return proto.alerting.MissionIncompleteAlert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.alerting.MissionIncompleteAlert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.alerting.MissionIncompleteAlert}
 */
proto.alerting.MissionIncompleteAlert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_vehicle_skills_mission_pb.MissionResult;
      reader.readMessage(value,pbtypes_vehicle_skills_mission_pb.MissionResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.alerting.MissionIncompleteAlert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.alerting.MissionIncompleteAlert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.alerting.MissionIncompleteAlert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.alerting.MissionIncompleteAlert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_vehicle_skills_mission_pb.MissionResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional skills.MissionResult result = 1;
 * @return {?proto.skills.MissionResult}
 */
proto.alerting.MissionIncompleteAlert.prototype.getResult = function() {
  return /** @type{?proto.skills.MissionResult} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_mission_pb.MissionResult, 1));
};


/**
 * @param {?proto.skills.MissionResult|undefined} value
 * @return {!proto.alerting.MissionIncompleteAlert} returns this
*/
proto.alerting.MissionIncompleteAlert.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.alerting.MissionIncompleteAlert} returns this
 */
proto.alerting.MissionIncompleteAlert.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.alerting.MissionIncompleteAlert.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.alerting.PositionXYZ.prototype.toObject = function(opt_includeInstance) {
  return proto.alerting.PositionXYZ.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.alerting.PositionXYZ} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.alerting.PositionXYZ.toObject = function(includeInstance, msg) {
  var f, obj = {
x: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
y: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
z: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.alerting.PositionXYZ}
 */
proto.alerting.PositionXYZ.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.alerting.PositionXYZ;
  return proto.alerting.PositionXYZ.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.alerting.PositionXYZ} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.alerting.PositionXYZ}
 */
proto.alerting.PositionXYZ.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setY(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setZ(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.alerting.PositionXYZ.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.alerting.PositionXYZ.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.alerting.PositionXYZ} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.alerting.PositionXYZ.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getZ();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double x = 1;
 * @return {number}
 */
proto.alerting.PositionXYZ.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.alerting.PositionXYZ} returns this
 */
proto.alerting.PositionXYZ.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double y = 2;
 * @return {number}
 */
proto.alerting.PositionXYZ.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.alerting.PositionXYZ} returns this
 */
proto.alerting.PositionXYZ.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double z = 3;
 * @return {number}
 */
proto.alerting.PositionXYZ.prototype.getZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.alerting.PositionXYZ} returns this
 */
proto.alerting.PositionXYZ.prototype.setZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.alerting.HumanDetectedAlert.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.alerting.HumanDetectedAlert.prototype.toObject = function(opt_includeInstance) {
  return proto.alerting.HumanDetectedAlert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.alerting.HumanDetectedAlert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.alerting.HumanDetectedAlert.toObject = function(includeInstance, msg) {
  var f, obj = {
subjectLocationsList: jspb.Message.toObjectList(msg.getSubjectLocationsList(),
    proto.alerting.PositionXYZ.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.alerting.HumanDetectedAlert}
 */
proto.alerting.HumanDetectedAlert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.alerting.HumanDetectedAlert;
  return proto.alerting.HumanDetectedAlert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.alerting.HumanDetectedAlert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.alerting.HumanDetectedAlert}
 */
proto.alerting.HumanDetectedAlert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.alerting.PositionXYZ;
      reader.readMessage(value,proto.alerting.PositionXYZ.deserializeBinaryFromReader);
      msg.addSubjectLocations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.alerting.HumanDetectedAlert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.alerting.HumanDetectedAlert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.alerting.HumanDetectedAlert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.alerting.HumanDetectedAlert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.alerting.PositionXYZ.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PositionXYZ subject_locations = 1;
 * @return {!Array<!proto.alerting.PositionXYZ>}
 */
proto.alerting.HumanDetectedAlert.prototype.getSubjectLocationsList = function() {
  return /** @type{!Array<!proto.alerting.PositionXYZ>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.alerting.PositionXYZ, 1));
};


/**
 * @param {!Array<!proto.alerting.PositionXYZ>} value
 * @return {!proto.alerting.HumanDetectedAlert} returns this
*/
proto.alerting.HumanDetectedAlert.prototype.setSubjectLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.alerting.PositionXYZ=} opt_value
 * @param {number=} opt_index
 * @return {!proto.alerting.PositionXYZ}
 */
proto.alerting.HumanDetectedAlert.prototype.addSubjectLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.alerting.PositionXYZ, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.alerting.HumanDetectedAlert} returns this
 */
proto.alerting.HumanDetectedAlert.prototype.clearSubjectLocationsList = function() {
  return this.setSubjectLocationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.alerting.DockErrorAlert.prototype.toObject = function(opt_includeInstance) {
  return proto.alerting.DockErrorAlert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.alerting.DockErrorAlert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.alerting.DockErrorAlert.toObject = function(includeInstance, msg) {
  var f, obj = {
type: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.alerting.DockErrorAlert}
 */
proto.alerting.DockErrorAlert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.alerting.DockErrorAlert;
  return proto.alerting.DockErrorAlert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.alerting.DockErrorAlert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.alerting.DockErrorAlert}
 */
proto.alerting.DockErrorAlert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.alerting.DockErrorAlert.DockErrorTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.alerting.DockErrorAlert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.alerting.DockErrorAlert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.alerting.DockErrorAlert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.alerting.DockErrorAlert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.alerting.DockErrorAlert.DockErrorTypeEnum = {
  UNSPECIFIED: 0,
  CANNOT_FIND_DOCK: 1,
  LANDED_OUTSIDE_DOCK: 2,
  VEHICLE_NOT_CHARGING: 3,
  POTENTIAL_PROP_DAMAGE: 4
};

/**
 * optional DockErrorTypeEnum type = 1;
 * @return {!proto.alerting.DockErrorAlert.DockErrorTypeEnum}
 */
proto.alerting.DockErrorAlert.prototype.getType = function() {
  return /** @type {!proto.alerting.DockErrorAlert.DockErrorTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.alerting.DockErrorAlert.DockErrorTypeEnum} value
 * @return {!proto.alerting.DockErrorAlert} returns this
 */
proto.alerting.DockErrorAlert.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.alerting.AlertContext.prototype.toObject = function(opt_includeInstance) {
  return proto.alerting.AlertContext.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.alerting.AlertContext} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.alerting.AlertContext.toObject = function(includeInstance, msg) {
  var f, obj = {
flightId: jspb.Message.getFieldWithDefault(msg, 1, ""),
missionState: (f = msg.getMissionState()) && pbtypes_vehicle_skills_mission_pb.MissionState.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.alerting.AlertContext}
 */
proto.alerting.AlertContext.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.alerting.AlertContext;
  return proto.alerting.AlertContext.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.alerting.AlertContext} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.alerting.AlertContext}
 */
proto.alerting.AlertContext.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 2:
      var value = new pbtypes_vehicle_skills_mission_pb.MissionState;
      reader.readMessage(value,pbtypes_vehicle_skills_mission_pb.MissionState.deserializeBinaryFromReader);
      msg.setMissionState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.alerting.AlertContext.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.alerting.AlertContext.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.alerting.AlertContext} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.alerting.AlertContext.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMissionState();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_vehicle_skills_mission_pb.MissionState.serializeBinaryToWriter
    );
  }
};


/**
 * optional string flight_id = 1;
 * @return {string}
 */
proto.alerting.AlertContext.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.alerting.AlertContext} returns this
 */
proto.alerting.AlertContext.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional skills.MissionState mission_state = 2;
 * @return {?proto.skills.MissionState}
 */
proto.alerting.AlertContext.prototype.getMissionState = function() {
  return /** @type{?proto.skills.MissionState} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_mission_pb.MissionState, 2));
};


/**
 * @param {?proto.skills.MissionState|undefined} value
 * @return {!proto.alerting.AlertContext} returns this
*/
proto.alerting.AlertContext.prototype.setMissionState = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.alerting.AlertContext} returns this
 */
proto.alerting.AlertContext.prototype.clearMissionState = function() {
  return this.setMissionState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.alerting.AlertContext.prototype.hasMissionState = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.alerting);
