import {
  createAction,
  createNextState,
  ActionCreatorWithPayload,
  ActionCreatorWithoutPayload,
  PayloadActionCreator,
  ActionCreator,
  AnyAction,
  Reducer,
} from "@reduxjs/toolkit";
import _ from "lodash";

import { APIRequestState, INITIAL_REQUEST_STATE } from "./requests";
import { IndexedRequestState } from "./thunks";

// Use these utilities to create a version of the provided request reducer that can be reset.
// Pass a string for the slice name to generate a new action (which will be returned along with the
// new reducer) or an existing redux-toolkit action creator to make the new reducer reset in
// response to that action type instead.
export function createClearableRequestReducer(
  reducer: Reducer<APIRequestState>,
  name: string
): { clearAction: ActionCreatorWithoutPayload; clearableReducer: Reducer<APIRequestState> };
export function createClearableRequestReducer(
  reducer: Reducer<APIRequestState>,
  // This can be any action creator
  clearAction: ActionCreator<AnyAction> | ActionCreator<AnyAction>[]
): Reducer<APIRequestState>;
export function createClearableRequestReducer(
  reducer: Reducer<APIRequestState>,
  clearActionOrName: any
) {
  const clearActions = _.isString(clearActionOrName)
    ? [createAction(`${clearActionOrName}/clearRequest`)]
    : ((_.isArray(clearActionOrName)
        ? clearActionOrName
        : [clearActionOrName]) as PayloadActionCreator<any>[]);

  const clearableReducer: Reducer<APIRequestState> = (state, action) => {
    if (state && _.some(clearActions, clearAction => clearAction.match(action))) {
      return createNextState(state, () => INITIAL_REQUEST_STATE);
    }
    return reducer(state, action);
  };

  if (_.isString(clearActionOrName)) {
    return { clearAction: clearActions[0], clearableReducer };
  }
  return clearableReducer;
}

// Same as above, but for indexed request reducers. If providing an existing action, it must be a
// normal action creator with a string payload (improvements to this may come in the future).
export function createClearableIndexedRequestReducer(
  reducer: Reducer<IndexedRequestState>,
  name: string
): {
  clearAction: ActionCreatorWithPayload<string>;
  clearableReducer: Reducer<IndexedRequestState>;
};
export function createClearableIndexedRequestReducer(
  reducer: Reducer<IndexedRequestState>,
  clearAction: PayloadActionCreator<string>
): Reducer<IndexedRequestState>;
export function createClearableIndexedRequestReducer(
  reducer: Reducer<IndexedRequestState>,
  clearActionOrName: any
) {
  const clearAction = _.isString(clearActionOrName)
    ? createAction<string>(`${clearActionOrName}/clearRequest`)
    : (clearActionOrName as PayloadActionCreator<string>);

  const clearableReducer: Reducer<IndexedRequestState> = (state, action) => {
    if (state && clearAction.match(action)) {
      return createNextState(state, draft => {
        draft[action.payload] = INITIAL_REQUEST_STATE;
      });
    }
    return reducer(state, action);
  };

  if (_.isString(clearActionOrName)) {
    return { clearAction, clearableReducer };
  }
  return clearableReducer;
}
