import { setSearchParams } from "@skydio/uri_util/src/query";

import { QUERY_CHANGE_DELAY } from "../constants";
import { fetchDevReleaseFiles, fetchDevReleaseFile, updateDevReleaseFile } from "./asyncThunks";
import {
  isDevReleaseFilesRequestActive,
  isDevReleaseFileRequestActive,
  getDevReleaseFileModifications,
  getDevReleaseFilesQueryTimeout,
} from "./selectors";
import { devReleaseFileActions, DevReleaseFilesState } from "./slice";
import { prepareDevReleaseFilesRequest } from "./utils";

import { ThunkCreator, AsyncThunkCreator } from "@skydio/redux_util/src";
import { DevReleaseFilesQueryState } from "./types";

const { clearDevReleaseFilesQueryTimeout, setDevReleaseFilesQueryTimeout } = devReleaseFileActions;

export const updateDevReleaseFilesQuery: ThunkCreator<
  DevReleaseFilesState,
  [DevReleaseFilesQueryState]
> = fields => (dispatch, getState) => {
  const state = getState();

  clearTimeout(getDevReleaseFilesQueryTimeout(state)!);
  dispatch(clearDevReleaseFilesQueryTimeout());

  const newArgs = prepareDevReleaseFilesRequest(fields);
  const timeout = window?.setTimeout(
    () => dispatch(fetchDevReleaseFiles(newArgs)),
    QUERY_CHANGE_DELAY
  );
  dispatch(setDevReleaseFilesQueryTimeout(timeout));

  dispatch(setSearchParams(newArgs));
};

export const fetchDevReleaseFilesIfAble: AsyncThunkCreator<
  DevReleaseFilesState,
  [DevReleaseFilesQueryState]
> = fields => async (dispatch, getState) => {
  const state = getState();
  if (!isDevReleaseFilesRequestActive(state)) {
    dispatch(fetchDevReleaseFiles(prepareDevReleaseFilesRequest(fields)));
  }
};

export const fetchDevReleaseFileIfAble: AsyncThunkCreator<DevReleaseFilesState, [string]> =
  key => async (dispatch, getState) => {
    if (!isDevReleaseFileRequestActive(getState(), key)) {
      dispatch(fetchDevReleaseFile(key));
    }
  };

export const updateDevReleaseFileIfAble: AsyncThunkCreator<DevReleaseFilesState, [string]> =
  key => async (dispatch, getState) => {
    const state = getState();
    if (!isDevReleaseFileRequestActive(state, key)) {
      dispatch(updateDevReleaseFile({ key, update: getDevReleaseFileModifications(state, key) }));
    }
  };
