import _ from "lodash";
import { createSelector } from "reselect";

import { paginationStateToProps } from "../pagination/utils";
import { vehicleInitialState, VehiclesState } from "./slice";
import { getVehicleReleaseName } from "./utils";

export const getVehiclesQueryTimeout = ({ state }: VehiclesState) => state.dispatchTimeout;
const getVehiclesRequestIds = ({ state }: VehiclesState) => state.requestedIds;
export const getAllVehicles = ({ state }: VehiclesState) => state.vehicles;

export const getVehiclesForRequest = createSelector(
  getVehiclesRequestIds,
  getAllVehicles,
  (vehicleIds, vehicles) => vehicleIds.map(vehicleId => vehicles[vehicleId])
);

export const getVehiclesRequest = ({ requests }: VehiclesState) => requests.vehicles;

export const isVehiclesRequestActive = (state: VehiclesState) => getVehiclesRequest(state).active;

export const getVehicle = (state: VehiclesState, vehicleId: string) =>
  getAllVehicles(state)[vehicleId] ?? vehicleInitialState;

export const getVehicleBySerial = (state: VehiclesState, serial: string) =>
  _.find(getAllVehicles(state), v => v.skydioSerial === serial);

export const getVehicleReleaseKey = (state: VehiclesState, vehicleId: string) =>
  getVehicle(state, vehicleId).releaseKey;

export const getVehicleOrgUuid = (state: VehiclesState, vehicleId: string) =>
  getVehicle(state, vehicleId).organizationId;

export const getVehicleRequest = ({ requests }: VehiclesState, vehicleId: string) =>
  requests.vehicle[vehicleId];

export const isVehicleRequestActive = (state: VehiclesState, vehicleId: string) =>
  getVehicleRequest(state, vehicleId)?.active;

export const getUserEmailsForVehicle = (state: VehiclesState, vehicleId: string) =>
  getVehicle(state, vehicleId).userEmails;

export const getVehiclesPagination = ({ state }: VehiclesState) => state.pagination;

export const getVehiclesPaginationProps = createSelector(
  getVehiclesPagination,
  paginationStateToProps
);

export const getFlownVehicles = createSelector(getAllVehicles, vehicles =>
  Object.values(vehicles).filter(vehicle => vehicle.organizationFlightCount > 0)
);

export const getVehiclesGroupedByType = createSelector(getAllVehicles, vehicles =>
  // returns data for @nivo/pie chart in dashboard.
  _.map(
    _.groupBy(vehicles, vehicle => getVehicleReleaseName(vehicle.vehicleType)),
    (listOfVehicles, vehicleType) => ({
      id: vehicleType,
      label: vehicleType,
      value: listOfVehicles.length,
    })
  )
);
