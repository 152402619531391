import { createAsyncThunk } from "@reduxjs/toolkit";

import enums_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/enums_pb";
import ucon_pb from "@skydio/pbtypes/pbtypes/vehicle/infrastructure/flight_deck/ucon_pb";

import endpoints from "../endpoints";
import { sendRequest } from "../requests-browser";

const createKindConfigProto = (
  kind: string,
  priority: enums_pb.UconUploadPriority.PriorityEnum,
  fileDeletionPolicy?: ucon_pb.FileDeletionPolicy.DeletionEnum
) => {
  const kindConfigProto = new ucon_pb.KindConfig();
  kindConfigProto.setKind(kind);
  kindConfigProto.setPriority(priority);
  if (fileDeletionPolicy) {
    kindConfigProto.setFileDeletionPolicy(fileDeletionPolicy);
  }
  return kindConfigProto;
};

export const fetchOrganizationUCONUploadConfig = createAsyncThunk(
  "organizations/fetchUCONUploadConfig",
  async (uuid: string) => {
    const responseProto = await sendRequest(endpoints.GET_ORG_UCON_UPLOAD_CONFIG, {
      path: { uuid },
    });
    return responseProto.toObject();
  }
);

export interface UpdateKindPayload {
  uuid: string;
  kind: string;
  priority: enums_pb.UconUploadPriority.PriorityEnum;
  fileDeletionPolicy?: ucon_pb.FileDeletionPolicy.DeletionEnum;
}

export const updateOrganizationUCONUploadConfig = createAsyncThunk(
  "organizations/updateUCONUploadConfig",
  async ({ uuid, kind, priority, fileDeletionPolicy }: UpdateKindPayload) => {
    const responseProto = await sendRequest(endpoints.UPDATE_ORG_UCON_UPLOAD_CONFIG, {
      path: { uuid },
      protobuf: createKindConfigProto(kind, priority, fileDeletionPolicy),
    });
    return responseProto.toObject();
  }
);

export interface RemoveKindPayload {
  uuid: string;
  kind: string;
}

export const removeOrganizationUCONUploadConfig = createAsyncThunk(
  "organizations/removeUCONUploadConfig",
  async ({ uuid, kind }: RemoveKindPayload) => {
    const kindConfigProto = new ucon_pb.KindConfig();
    kindConfigProto.setKind(kind);
    const responseProto = await sendRequest(endpoints.REMOVE_ORG_UCON_UPLOAD_CONFIG, {
      path: { uuid },
      protobuf: kindConfigProto,
    });
    return responseProto.toObject();
  }
);
