import { FeatureFlags } from "./types";
import { FeatureFlagsState } from "./slice";

export const getFeatureFlagValue = (state: FeatureFlagsState, feature: keyof FeatureFlags) =>
  state.featureFlags[feature];

export const isFeatureFlagRequestStateActive = (state: FeatureFlagsState) => state.request.active;

export const isFeatureFlagRequestStateCompleted = (state: FeatureFlagsState) =>
  !state.request.active && (state.request.success || state.request.requestId);
