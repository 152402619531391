import { createReducer, combineReducers } from "@reduxjs/toolkit";

import feature_flags_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/feature_flags_pb";
import { createRequestReducerFromThunk, reduceReducers } from "@skydio/redux_util/src";

import { fetchFeatureFlags, fetchFeatureFlagsForOrgRole } from "./asyncThunks";

import { FeatureFlags } from "./types";

const initialState: FeatureFlags = new feature_flags_pb.FeatureFlags().toObject();

const featureFlags = createReducer(initialState, builder =>
  builder
    .addCase(fetchFeatureFlags.fulfilled, (_state, { payload }) => payload)
    .addCase(fetchFeatureFlagsForOrgRole.fulfilled, (_state, { payload }) => payload)
);

const reducer = combineReducers({
  featureFlags,
  request: reduceReducers(
    createRequestReducerFromThunk(fetchFeatureFlags),
    createRequestReducerFromThunk(fetchFeatureFlagsForOrgRole)
  ),
});

export type FeatureFlagsState = ReturnType<typeof reducer>;
export default reducer;
