import {
  parseSearchState,
  makeQuerySerializable,
  convertToNumber,
  convertFromBoolean,
  convertToBooleanWithFalsy,
} from "@skydio/uri_util/src/query";

import {
  DevReleaseFilesQueryState,
  DevReleaseFilesQueryParams,
  DevReleaseFilesRequest,
} from "./types";
import { getVehicleTypeEnum } from "../releases/utils";

export const devReleaseFilesQueryInitialState: DevReleaseFilesQueryState = {
  key: "",
  toReleaseKey: "",
  name: "",
  vehicleType: "",
  branch: "",
  builder: "",
  commit: "",
  ubuntuSuite: "",
  includeTemporary: false,
};

export const parseDevReleaseFilesQueryState = (searchString: string) =>
  parseSearchState(
    searchString,
    devReleaseFilesQueryInitialState,
    (params: DevReleaseFilesQueryParams) => ({
      ...params,
      limit: convertToNumber(params.limit),
      perPage: convertToNumber(params.perPage),
      pageNumber: convertToNumber(params.pageNumber),
      includeTemporary: convertToBooleanWithFalsy(params.includeTemporary),
    })
  );

export const prepareDevReleaseFilesRequest = (
  state: DevReleaseFilesQueryState
): DevReleaseFilesRequest =>
  makeQuerySerializable(state, devReleaseFilesQueryInitialState, releaseState => {
    let request: DevReleaseFilesRequest = {
      ...releaseState,
      includeTemporary: convertFromBoolean(releaseState.includeTemporary),
    };
    const vehicleType = parseInt(releaseState.vehicleType);
    if (vehicleType >= 0) {
      request = {
        ...request,
        vehicleType: getVehicleTypeEnum(vehicleType),
      };
    }
    return request;
  });
