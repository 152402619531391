import { createSlice, combineReducers } from "@reduxjs/toolkit";
import { createRequestReducerFromThunk } from "@skydio/redux_util/src";

import enums_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/enums_pb";
import ucon_pb from "@skydio/pbtypes/pbtypes/vehicle/infrastructure/flight_deck/ucon_pb";

import { APIUCONKindConfig, UploadConfigState, KindConfigMap } from "./types";
import {
  fetchOrganizationUCONUploadConfig,
  updateOrganizationUCONUploadConfig,
  removeOrganizationUCONUploadConfig,
} from "./asyncThunks";

const initialState: UploadConfigState = {
  uploadConfig: {},
};

// @ts-ignore TS2741
const kindInitialState: APIUCONKindConfig = {
  kind: "",
  priority: enums_pb.UconUploadPriority.PriorityEnum.UNKNOWN,
  fileDeletionPolicy: ucon_pb.FileDeletionPolicy.DeletionEnum.UNKNOWN,
  fileUsage: enums_pb.FileUsage.FileUsageReason.UNKNOWN,
};

const updateConfigState = (state: KindConfigMap, kindConfig: APIUCONKindConfig) => {
  state[kindConfig.kind] = { ...kindInitialState };
  Object.assign(state[kindConfig.kind]!, kindConfig);
};

const { actions, reducer: primaryReducer } = createSlice({
  name: "uploadConfig",
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(fetchOrganizationUCONUploadConfig.pending, state => {
        state.uploadConfig = {};
      }) // Clear on fetch
      .addCase(fetchOrganizationUCONUploadConfig.fulfilled, (state, { payload }) => {
        state.uploadConfig = {};
        payload.kindConfigMapMap.forEach(kindTuple => {
          updateConfigState(state.uploadConfig, kindTuple[1]);
        });
      })
      .addCase(updateOrganizationUCONUploadConfig.fulfilled, (state, { payload }) => {
        state.uploadConfig = {};
        payload.kindConfigMapMap.forEach(kindTuple => {
          updateConfigState(state.uploadConfig, kindTuple[1]);
        });
      })
      .addCase(removeOrganizationUCONUploadConfig.fulfilled, (state, { payload }) => {
        state.uploadConfig = {};
        payload.kindConfigMapMap.forEach(kindTuple => {
          updateConfigState(state.uploadConfig, kindTuple[1]);
        });
      }),
});

const reducer = combineReducers({
  state: primaryReducer,
  requests: combineReducers({
    uploadConfig: createRequestReducerFromThunk(fetchOrganizationUCONUploadConfig),
  }),
});

export type UCONUploadConfigState = ReturnType<typeof reducer>;

export { actions as organizationUCONUploadConfigActions };
export default reducer;
