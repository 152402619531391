import _ from "lodash";
import enums_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/enums_pb";

import {
  deleteOrganization,
  updateOrganization,
  fetchOrganizations,
  fetchOrganization,
} from "./asyncThunks";
import {
  canFetchOrganizations,
  isOrganizationRequestActive,
  getOrganizationGroupLinks,
  getOrganizationSkillsetLinks,
} from "./selectors";
import { OrganizationsState } from "./slice";

import { ThunkCreator, AsyncThunkCreator } from "@skydio/redux_util/src";

export const fetchOrganizationsIfAble: ThunkCreator<OrganizationsState> =
  () => (dispatch, getState) => {
    if (canFetchOrganizations(getState())) {
      dispatch(fetchOrganizations());
    }
  };

export const fetchOrganizationIfAble: ThunkCreator<OrganizationsState, [string]> =
  uuid => (dispatch, getState) => {
    if (!isOrganizationRequestActive(getState(), uuid)) {
      dispatch(
        fetchOrganization({
          uuid,
          includeGroupLinks: true,
          includeSkillsetLinks: true,
          includeSwitches: true,
          includeUsers: true,
        })
      );
    }
  };

export const deleteOrganizationIfAble: ThunkCreator<OrganizationsState, [string]> =
  uuid => (dispatch, getState) => {
    if (!isOrganizationRequestActive(getState(), uuid)) {
      dispatch(deleteOrganization(uuid));
    }
  };

export const addGroupLinksToOrganization: AsyncThunkCreator<
  OrganizationsState,
  [string, string[], enums_pb.OrgPermission.PermEnum]
> = (uuid, groupNames, permission) => async (dispatch, getState) => {
  const state = getState();
  if (!isOrganizationRequestActive(state, uuid)) {
    let groupLinks = [...getOrganizationGroupLinks(state, uuid)];
    groupNames.forEach(groupName => {
      const newGroup = { groupName, organizationPermission: permission, released: false };
      if (!_.find(groupLinks, newGroup)) {
        groupLinks.push(newGroup);
      }
    });
    await dispatch(
      updateOrganization({
        uuid,
        update: {
          groupLinksList: groupLinks,
        },
      })
    );
  }
};

export const removeGroupLinkFromOrganization: AsyncThunkCreator<
  OrganizationsState,
  [string, string, enums_pb.OrgPermission.PermEnum]
> = (uuid, groupName, permission) => async (dispatch, getState) => {
  const state = getState();
  if (!isOrganizationRequestActive(state, uuid)) {
    const groupLinks = getOrganizationGroupLinks(state, uuid);
    await dispatch(
      updateOrganization({
        uuid,
        update: {
          groupLinksList: groupLinks.filter(
            group => !(group.groupName === groupName && group.organizationPermission === permission)
          ),
        },
      })
    );
  }
};

export const addSkillsetLinkToOrganization: AsyncThunkCreator<
  OrganizationsState,
  [string, string, enums_pb.OrgPermission.PermEnum]
> = (uuid, skillsetName, permission) => async (dispatch, getState) => {
  const state = getState();
  if (!isOrganizationRequestActive(state, uuid)) {
    const skillsetLinks = getOrganizationSkillsetLinks(state, uuid);
    await dispatch(
      updateOrganization({
        uuid,
        update: {
          skillsetLinksList: skillsetLinks.concat({
            skillsetName,
            // leave this blank when updating and have the server fill it in on the response
            skillsetId: "",
            organizationPermission: permission,
          }),
        },
      })
    );
  }
};

export const removeSkillsetLinkFromOrganization: AsyncThunkCreator<
  OrganizationsState,
  [string, string, enums_pb.OrgPermission.PermEnum]
> = (uuid, skillsetName, permission) => async (dispatch, getState) => {
  const state = getState();
  if (!isOrganizationRequestActive(state, uuid)) {
    const skillsetLinks = getOrganizationSkillsetLinks(state, uuid);
    await dispatch(
      updateOrganization({
        uuid,
        update: {
          skillsetLinksList: skillsetLinks.filter(
            link =>
              !(link.skillsetName === skillsetName && link.organizationPermission === permission)
          ),
        },
      })
    );
  }
};

export const setOrganizationDataLevel: AsyncThunkCreator<
  OrganizationsState,
  [string, enums_pb.DataLevel.LevelEnum]
> = (uuid, dataLevel) => async (dispatch, getState) => {
  const state = getState();
  if (!isOrganizationRequestActive(state, uuid)) {
    await dispatch(
      updateOrganization({
        uuid,
        update: {
          dataLevel,
        },
      })
    );
  }
};
