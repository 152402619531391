import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";

import { ThunkCreator } from "@skydio/redux_util/src";

import { download } from "../../../common/http";
import endpoints from "../endpoints";
import { sendRequest } from "../requests-browser";
import { FLIGHT_STAT_RANGES_GROUP_BY, prepareFlightRequest } from "./utils";

import { FlightsRequest, FlightStatsRequest } from "./types";

export const fetchFlights = createAsyncThunk("flights/fetchAll", async (args: FlightsRequest) => {
  const responseProto = await sendRequest(endpoints.GET_FLIGHTS, args);
  const responseObject = responseProto.toObject();
  return responseObject;
});

export const fetchFlight = createAsyncThunk(
  "flights/fetchOne",
  async ({ flightId }: { flightId: string }) => {
    const flight = await sendRequest(endpoints.GET_FLIGHT, prepareFlightRequest(flightId));
    return flight.toObject();
  }
);

export const fetchFlightStats = createAsyncThunk(
  "flights/fetchStats",
  async (args: FlightStatsRequest) => {
    const stats = await sendRequest(endpoints.GET_FLIGHT_STATS, args);
    return stats.toObject().statsList;
  }
);

export const downloadFlightsCsv = createAsyncThunk(
  "flights/download",
  async (args: FlightsRequest) => {
    const response = await sendRequest(endpoints.DOWNLOAD_FLIGHTS_CSV, args);
    const blob = await response.blob();
    const inMemoryURL = URL.createObjectURL(blob);
    const to = args.takeoffBefore ? new Date(args.takeoffBefore).toDateString() : "N/A";
    const from = args.takeoffSince ? new Date(args.takeoffSince).toDateString() : "N/A";
    download(inMemoryURL, `flights from ${from} to ${to}.csv`);
    URL.revokeObjectURL(inMemoryURL);
  }
);

export const fetchFlightStatsOverTime: ThunkCreator<
  unknown,
  [string, keyof typeof FLIGHT_STAT_RANGES_GROUP_BY, string, string]
> = (organizationId, duration, takeoffBefore, takeoffSince) => dispatch => {
  dispatch(
    fetchFlightStats({
      organizationId,
      aggregateType: FLIGHT_STAT_RANGES_GROUP_BY[duration],
      takeoffBefore: takeoffBefore || moment().format(),
      takeoffSince: takeoffSince || moment().subtract(1, duration).format(),
    })
  );
};
