import { fetchFileKinds } from "./asyncThunks";
import { canFetchFileKinds } from "./selectors";

import { AsyncThunkCreator } from "@skydio/redux_util/src";
import { FileKindsState } from "./slice";

export const fetchFileKindsIfAble: AsyncThunkCreator<FileKindsState> =
  () => async (dispatch, getState) => {
    if (canFetchFileKinds(getState())) {
      dispatch(fetchFileKinds());
    }
  };
