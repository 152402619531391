import { UCONUploadConfigState } from "./slice";
import { APIUCONKindConfig } from "./types";

export const getUCONUploadConfig = ({ state }: UCONUploadConfigState) => state.uploadConfig;

export const getKindConfig = ({ state }: UCONUploadConfigState, kindConfigKey: string) =>
  state.uploadConfig[kindConfigKey];

export const getKindConfigKeys = ({ state }: UCONUploadConfigState) =>
  Object.keys(state.uploadConfig) as Array<string>;

export const getAllKindConfigs = ({ state }: UCONUploadConfigState) =>
  Object.values(state.uploadConfig) as Array<APIUCONKindConfig>;
