import "history";
import { push } from "connected-react-router";
import moment, { Moment } from "moment";
import _ from "lodash";
import queryString from "query-string";

import { camelKeys } from "@skydio/common_util/src";

export const makeQuery = (args: Record<string, any>) => `?${queryString.stringify(args)}`;
export const setSearchParams = (args: Record<string, any>) => push({ search: makeQuery(args) });

// Some conversion utilities to make the converter functions for makeSearchStateSelector and
// makeSearchRequestPreparer easier to write, since these will sensibly handle unset properties.

// Use these in the selector converter func
export const convertToArray = (param?: string | string[]) =>
  param ? (_.isArray(param) ? param : [param]) : [];

export const convertToMoment = (param?: string) => (param ? moment(param) : undefined);

export const convertToNumber = (param?: string) => (param ? Number(param) : undefined);

export const convertToBoolean = (param?: string) => (param ? true : undefined);

// Allows the URL to specify myQueryParam=0 to preserve the 'falsiness' of this param, rather than
// returning undefined. This allows distinguishing between 3 URL states (true, false, not specified)
export const convertToBooleanWithFalsy = (param?: string) => {
  if (param !== undefined) {
    return param == "0" ? false : true;
  }
  return undefined;
};

// Use these in the preparer converter func
export const convertFromMoment = (param?: Moment) => param?.format();

export const convertFromBoolean = (param?: boolean) => (param ? "1" : undefined);

export const parseSearchState = <Q, S>(
  searchString: string,
  initialState: S,
  converter: (params: Q) => Partial<S>
) => {
  const args = camelKeys(queryString.parse(searchString)) as Q;
  return {
    ...initialState,
    ...converter(args),
  } as S;
};

export const makeQuerySerializable = <StateType extends Record<string, any>, RequestType>(
  state: StateType,
  initialState: StateType,
  converter: (state: StateType) => RequestType
) => {
  const updatedState = { ...state };
  _.forEach(updatedState, (val, key) => {
    if (_.isEqual(val, initialState[key])) {
      delete updatedState[key];
    }
  });
  return converter(updatedState);
};

export const getOneFromQuery = (name: string) => {
  const query = queryString.parse(window?.location?.search);
  if (!(name in query)) {
    // exit if doesn't exist
    return undefined;
  }
  const pickedQuery = query[name];
  // exit if null | undefined
  if (!pickedQuery) {
    return undefined;
  }
  if (typeof pickedQuery === "string") {
    return pickedQuery;
  } else {
    // string[], pick last
    return pickedQuery[pickedQuery.length - 1];
  }
};

// Add / update history with query param set
export const setOneInQuery = (name: string, value: string) => {
  const parsed = queryString.parseUrl(window?.location?.href);
  parsed.query[name] = value;
  const newQuery = queryString.stringify(parsed.query);
  history.pushState(undefined, "", `${parsed.url}?${newQuery}`);
};

export const getPortFromQuery = () => {
  let port: number | undefined;
  const stringPort = getOneFromQuery("port");
  if (stringPort) {
    port = parseInt(stringPort, 10);
  }

  // if any parseInt tried to parse non-numbers unset port
  if (port !== undefined && isNaN(port)) {
    port = undefined;
  }

  return port;
};
