import { createReducer, combineReducers } from "@reduxjs/toolkit";

import {
  fetchWifiSettings,
  addWifiSetting,
  updateWifiSetting,
  deleteWifiSettings,
} from "./asyncThunks";

import { WifiSettingsMap } from "./types";

const wifiSettings = createReducer({} as WifiSettingsMap, builder =>
  builder
    .addCase(fetchWifiSettings.fulfilled, (_state, { payload }) => {
      // return new state here. its just easy to replace
      return payload.wifiSettingsList.reduce((acc: WifiSettingsMap, value) => {
        acc[value.uuid] = value;
        return acc;
      }, {} as WifiSettingsMap);
    })
    .addCase(addWifiSetting.fulfilled, (state, { payload }) => {
      // just mutate state and update it with a new added one.
      state[payload.uuid] = payload;
    })
    .addCase(updateWifiSetting.fulfilled, (state, { payload }) => {
      state[payload.uuid] = payload;
    })
    .addCase(deleteWifiSettings.fulfilled, (state, { payload }) => {
      delete state[payload.uuid];
    })
);

const reducer = combineReducers({
  wifiSettings,
});

export type WifiSettingsState = ReturnType<typeof reducer>;
export default reducer;
