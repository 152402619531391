import { createReducer } from "@reduxjs/toolkit";
import type {
  ActionCreatorWithPreparedPayload,
  Reducer,
  ThunkAction,
  AnyAction,
  ThunkDispatch,
  SerializedError,
} from "@reduxjs/toolkit";
import _ from "lodash";

import { INITIAL_REQUEST_STATE, APIRequestState } from "./requests";
import { Selector } from "./selectors";

interface ThunkActions<ThunkArg, Result> {
  pending: ActionCreatorWithPreparedPayload<
    [string, ThunkArg],
    undefined,
    string,
    never,
    { arg: ThunkArg; requestId: string }
  >;
  fulfilled: ActionCreatorWithPreparedPayload<
    [Result, string, ThunkArg],
    Result,
    string,
    never,
    { arg: ThunkArg; requestId: string }
  >;
  rejected: ActionCreatorWithPreparedPayload<
    [Error | null, string, ThunkArg, unknown?],
    unknown,
    string,
    SerializedError,
    { arg: ThunkArg; requestId: string; aborted: boolean }
  >;
}

export type IndexedRequestState = Record<string, APIRequestState>;

// This function takes the return value of `createAsyncThunk` (see the Redux Toolkit docs for
// specific details on how to use it) - which contains three async lifecycle action creators - and
// returns a small reducer to manage the state of the corresponding request.
export const createRequestReducerFromThunk = <ThunkArg, Result>({
  pending,
  fulfilled,
  rejected,
}: ThunkActions<ThunkArg, Result>) =>
  createReducer(INITIAL_REQUEST_STATE, builder =>
    builder
      .addCase(pending, (_state, { meta }) => ({
        ...INITIAL_REQUEST_STATE,
        requestId: meta.requestId,
        active: true,
      }))
      .addCase(fulfilled, state => {
        state.active = false;
        state.success = true;
        state.error = null;
      })
      .addCase(rejected, (state, { error }) => {
        state.active = false;
        state.success = false;
        state.error = error;
      })
  );

// Utility type to use like `keyof`, except it only yields keys with string values
type KeyForString<T> = { [K in keyof T]: T[K] extends string ? K : never }[keyof T];

// Same idea as the previous function, except this one returns a reducer for managing a mapping of
// an arbitrary number of related request states. Contrary to the actions produced by
// `createIndexedRequestSlice` in this library, which require that a `requestIndex` be passed to
// each action creator to associate with the corresponding entry in the request state map, the
// actions produced by `createAsyncThunk` contain the argument passed to the thunk as a `meta`
// property (parameterized as `ThunkArg`), which should contain a key that can be used to retrieve
// an identifier for a particular request. The second argument to this function should be the name
// of that key.
// For example, if a thunk expects a flight object with a `flightId` property as an argument, you
// might call `createIndexedRequestReducerFromThunk(thunk, "flightId")`, which would then manage a
// mapping of `flightId`s to request states.
export function createIndexedRequestReducerFromThunk<ThunkArg extends Record<string, any>, Result>(
  thunk: ThunkActions<ThunkArg, Result>,
  requestKey: ((arg: ThunkArg) => string) | KeyForString<ThunkArg>
): Reducer<Record<string, APIRequestState>>;
export function createIndexedRequestReducerFromThunk<Result>(
  thunk: ThunkActions<string, Result>
): Reducer<Record<string, APIRequestState>>;
export function createIndexedRequestReducerFromThunk(
  { pending, fulfilled, rejected }: any,
  requestKey?: any
) {
  // If the thunk arg is an object, can pass in a key to directly retrieve the request index or a
  // function to generate the index from it
  // Otherwise the arg should be a string and will be used directly
  const getRequestIndex = (arg: any) => {
    if (_.isFunction(requestKey)) {
      return requestKey(arg);
    } else if (_.isPlainObject(arg)) {
      return arg[requestKey];
    }
    return arg;
  };
  return createReducer({} as Record<string, APIRequestState>, builder =>
    builder
      .addCase(pending, (state, { meta }) => {
        state[getRequestIndex(meta.arg)] = {
          ...INITIAL_REQUEST_STATE,
          active: true,
          requestId: meta.requestId,
        };
      })
      .addCase(fulfilled, (state, { meta }) => {
        const requestState = state[getRequestIndex(meta.arg)]!;
        requestState.active = false;
        requestState.success = true;
        requestState.error = null;
      })
      .addCase(rejected, (state, { error, meta }) => {
        const requestState = state[getRequestIndex(meta.arg)]!;
        requestState.active = false;
        requestState.success = false;
        requestState.error = error;
      })
  );
}

export type ThunkCreator<State, Args extends any[] = [], Return = void> = (
  ...args: Args
) => ThunkAction<Return, State, null, AnyAction>;
export type AsyncThunkCreator<State, Args extends any[] = [], Return = void> = ThunkCreator<
  State,
  Args,
  Promise<Return>
>;
type WrappedThunkCreator<AppState, SliceState, T> = T extends ThunkCreator<
  SliceState,
  infer Args,
  infer Return
>
  ? ThunkCreator<AppState, Args, Return>
  : unknown;

export const makeWrappedThunk = <SliceState, AppState, Args extends any[], Return>(
  thunkCreator: ThunkCreator<SliceState, Args, Return>,
  sliceSelector: Selector<AppState, SliceState, []>
) => {
  // NOTE(sam): This is essentially injecting a custom version of the thunk middleware logic
  // which should allow for wrapped state thunks to wrap any state thunks they dispatch themselves
  // (This is incredibly confusing, I know. It's thunks all the way down)
  const makeNewGetState = (getState: () => AppState) => () => sliceSelector(getState());
  const makeNewDispatch =
    (
      dispatch: ThunkDispatch<AppState, null, AnyAction>
    ): ThunkDispatch<SliceState, null, AnyAction> =>
    <R>(action: AnyAction | ThunkAction<R, SliceState, null, AnyAction>) => {
      if (_.isFunction(action)) {
        return dispatch((dispatch, getState) =>
          action(makeNewDispatch(dispatch), makeNewGetState(getState), null)
        );
      }
      return dispatch(action);
    };

  const wrappedThunkCreator: ThunkCreator<AppState, Args, Return> = (...args) => {
    const thunkToWrap = thunkCreator(...args);
    return (dispatch, getState) =>
      thunkToWrap(makeNewDispatch(dispatch), makeNewGetState(getState), null);
  };
  return wrappedThunkCreator;
};

export const makeWrappedThunks = <
  AppState,
  SliceState,
  Thunks extends Record<keyof Thunks, ThunkCreator<SliceState, any, any>>
>(
  thunks: Thunks,
  sliceSelector: Selector<AppState, SliceState>
) =>
  _.reduce(
    thunks,
    (all, thunk, name) => ({
      ...all,
      [name]: makeWrappedThunk(thunk, sliceSelector),
    }),
    {} as { [K in keyof Thunks]: WrappedThunkCreator<AppState, SliceState, Thunks[K]> }
  );
