import _ from "lodash";
import { createSelector } from "reselect";

import { DevReleaseFilesState } from "./slice";
import { DevReleaseFile } from "./types";
import { getUploadedSortFunc } from "../releases/utils";

export const getAllDevReleaseFiles = ({ state }: DevReleaseFilesState) => state.devReleaseFiles;

export const getDevReleaseFile = (state: DevReleaseFilesState, key: string) =>
  getAllDevReleaseFiles(state)[key]!;

export const getDevReleaseFilesRequest = ({ requests }: DevReleaseFilesState) =>
  requests.devReleaseFiles;

export const isDevReleaseFilesRequestActive = (state: DevReleaseFilesState) =>
  getDevReleaseFilesRequest(state).active;

export const getDevReleaseFileRequest = ({ requests }: DevReleaseFilesState, key: string) =>
  requests.devReleaseFile[key];

export const isDevReleaseFileRequestActive = (state: DevReleaseFilesState, key: string) =>
  getDevReleaseFileRequest(state, key)?.active;

export const getDevReleaseFileRequestError = (state: DevReleaseFilesState, key: string) =>
  getDevReleaseFileRequest(state, key)?.error;

export const getDevReleaseFileModifications = (state: DevReleaseFilesState, key: string) =>
  // @ts-ignore TS2339
  getDevReleaseFile(state, key).modified;

export const getFilesForDevReleaseFile = ({ state }: DevReleaseFilesState, key: string) =>
  state.devReleaseFiles[key] ?? {};

export const getDevReleaseFilesQueryTimeout = ({ state }: DevReleaseFilesState) =>
  state.dispatchTimeout;

export const getDevReleaseFileRequestKeys = ({ state }: DevReleaseFilesState) => state.requestedIds;

export const doesDevReleaseFileNeedUpdate = (state: DevReleaseFilesState, key: string) =>
  // @ts-ignore TS2339
  _.keys(getDevReleaseFile(state, key)?.modified ?? {}).length > 0;

function setDevReleaseBuildInfo(release: DevReleaseFile) {
  if (release.fpinfo) {
    return {
      ...release,
      branch: JSON.parse(release.fpinfo)?.fpinfo.git_branch,
      builder: JSON.parse(release.fpinfo)?.fpinfo.user_name,
      commit: JSON.parse(release.fpinfo)?.fpinfo.git_commit,
    };
  }
  return release;
}

const uploadedSort = getUploadedSortFunc<DevReleaseFile>("uploaded");

export const getDevReleaseFilesForRequest = createSelector(
  getDevReleaseFileRequestKeys,
  getAllDevReleaseFiles,
  (releaseKeys, allReleases: { [key: string]: DevReleaseFile }) =>
    releaseKeys.map(key => setDevReleaseBuildInfo(allReleases[key]!)).sort(uploadedSort)
);

export const getPagination = ({ state }: DevReleaseFilesState) => state.pagination;
