import { Reducer } from "@reduxjs/toolkit";
import _ from "lodash";

export default function reduceReducers<TState>(...reducers: Reducer<TState>[]): Reducer<TState>;
export default function reduceReducers<TState>(
  initialState: TState,
  ...reducers: Reducer<TState>[]
): Reducer<TState>;
export default function reduceReducers<TState>(...args: any[]) {
  let initialState: TState | undefined;
  if (!_.isFunction(args[0])) {
    initialState = args.shift() as TState;
  }
  if (!args.length) {
    throw new Error("Must supply at least one reducer.");
  }
  const reducers: Reducer<TState>[] = args;

  const reducedReducer: Reducer<TState> = (state, action) =>
    reducers.reduce(
      (newState, reducer) => reducer(newState, action),
      _.isUndefined(state) && initialState ? initialState : state
    ) as TState;
  return reducedReducer;
}
