// source: pbtypes/tools/cloud_api/alert_v2.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_tools_cloud_api_webhook_pb = require('../../../pbtypes/tools/cloud_api/webhook_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_webhook_pb);
goog.exportSymbol('proto.cloud_api.AlertConfigV2', null, global);
goog.exportSymbol('proto.cloud_api.AlertContext', null, global);
goog.exportSymbol('proto.cloud_api.AlertDetails', null, global);
goog.exportSymbol('proto.cloud_api.AlertDetails.AlertDetailsCase', null, global);
goog.exportSymbol('proto.cloud_api.AlertRecordV2', null, global);
goog.exportSymbol('proto.cloud_api.AlertTargetRule', null, global);
goog.exportSymbol('proto.cloud_api.DockErrorDetails', null, global);
goog.exportSymbol('proto.cloud_api.FindAlertConfigRequestV2', null, global);
goog.exportSymbol('proto.cloud_api.FindAlertConfigResponseV2', null, global);
goog.exportSymbol('proto.cloud_api.FlightDetails', null, global);
goog.exportSymbol('proto.cloud_api.FlightStatusDetails', null, global);
goog.exportSymbol('proto.cloud_api.FlightStatusDetails.FlightState', null, global);
goog.exportSymbol('proto.cloud_api.IncomingAlert', null, global);
goog.exportSymbol('proto.cloud_api.LiveStreamStatusDetails', null, global);
goog.exportSymbol('proto.cloud_api.LiveStreamStatusDetails.LiveStreamState', null, global);
goog.exportSymbol('proto.cloud_api.MediaFileDetails', null, global);
goog.exportSymbol('proto.cloud_api.MissionIncompleteDetails', null, global);
goog.exportSymbol('proto.cloud_api.OnlineStatusDetails', null, global);
goog.exportSymbol('proto.cloud_api.ScanDetails', null, global);
goog.exportSymbol('proto.cloud_api.Target', null, global);
goog.exportSymbol('proto.cloud_api.Target.TypeEnum', null, global);
goog.exportSymbol('proto.cloud_api.Trigger', null, global);
goog.exportSymbol('proto.cloud_api.Trigger.TypeEnum', null, global);
goog.exportSymbol('proto.cloud_api.WaypointProgressDetails', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.Trigger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.Trigger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.Trigger.displayName = 'proto.cloud_api.Trigger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.Target = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.Target, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.Target.displayName = 'proto.cloud_api.Target';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.AlertTargetRule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.AlertTargetRule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.AlertTargetRule.displayName = 'proto.cloud_api.AlertTargetRule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.AlertConfigV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.AlertConfigV2.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.AlertConfigV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.AlertConfigV2.displayName = 'proto.cloud_api.AlertConfigV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FindAlertConfigRequestV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.FindAlertConfigRequestV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FindAlertConfigRequestV2.displayName = 'proto.cloud_api.FindAlertConfigRequestV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FindAlertConfigResponseV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.FindAlertConfigResponseV2.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.FindAlertConfigResponseV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FindAlertConfigResponseV2.displayName = 'proto.cloud_api.FindAlertConfigResponseV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.IncomingAlert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.IncomingAlert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.IncomingAlert.displayName = 'proto.cloud_api.IncomingAlert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.AlertRecordV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.AlertRecordV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.AlertRecordV2.displayName = 'proto.cloud_api.AlertRecordV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.AlertContext = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.AlertContext, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.AlertContext.displayName = 'proto.cloud_api.AlertContext';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.AlertDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.cloud_api.AlertDetails.oneofGroups_);
};
goog.inherits(proto.cloud_api.AlertDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.AlertDetails.displayName = 'proto.cloud_api.AlertDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.OnlineStatusDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.OnlineStatusDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.OnlineStatusDetails.displayName = 'proto.cloud_api.OnlineStatusDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FlightStatusDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.FlightStatusDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FlightStatusDetails.displayName = 'proto.cloud_api.FlightStatusDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.WaypointProgressDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.WaypointProgressDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.WaypointProgressDetails.displayName = 'proto.cloud_api.WaypointProgressDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.MediaFileDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.MediaFileDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.MediaFileDetails.displayName = 'proto.cloud_api.MediaFileDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.DockErrorDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.DockErrorDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.DockErrorDetails.displayName = 'proto.cloud_api.DockErrorDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.MissionIncompleteDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.MissionIncompleteDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.MissionIncompleteDetails.displayName = 'proto.cloud_api.MissionIncompleteDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.ScanDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.ScanDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.ScanDetails.displayName = 'proto.cloud_api.ScanDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.LiveStreamStatusDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.LiveStreamStatusDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.LiveStreamStatusDetails.displayName = 'proto.cloud_api.LiveStreamStatusDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FlightDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.FlightDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FlightDetails.displayName = 'proto.cloud_api.FlightDetails';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.Trigger.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.Trigger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.Trigger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Trigger.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.Trigger}
 */
proto.cloud_api.Trigger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.Trigger;
  return proto.cloud_api.Trigger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.Trigger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.Trigger}
 */
proto.cloud_api.Trigger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.Trigger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.Trigger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.Trigger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Trigger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.cloud_api.Trigger.TypeEnum = {
  UNKNOWN: 0,
  HUMAN_DETECTED: 1,
  MISSION_INCOMPLETE: 2,
  DOCK_ERROR: 3,
  SCHEDULED_MISSION_FAILED_TAKEOFF: 4,
  FLIGHT_STATUS: 5,
  WAYPOINT_PROGRESS: 6,
  ONLINE_STATUS: 7,
  MEDIA_FILE_AVAILABLE: 8,
  MEDIA_AVAILABLE_FOR_SCAN: 9,
  TELEMETRY_AVAILABLE: 10,
  LIVE_STREAM_STATUS_CHANGED: 11,
  MEDIA_AVAILABLE_FOR_FLIGHT: 12
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.Target.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.Target.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.Target} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Target.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.Target}
 */
proto.cloud_api.Target.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.Target;
  return proto.cloud_api.Target.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.Target} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.Target}
 */
proto.cloud_api.Target.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.Target.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.Target.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.Target} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Target.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.cloud_api.Target.TypeEnum = {
  UNKNOWN: 0,
  ORGANIZATION: 1,
  SITE: 2,
  DOCK: 3,
  VEHICLE: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.AlertTargetRule.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.AlertTargetRule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.AlertTargetRule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AlertTargetRule.toObject = function(includeInstance, msg) {
  var f, obj = {
targetParentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
targetParentType: jspb.Message.getFieldWithDefault(msg, 2, 0),
targetBaseType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.AlertTargetRule}
 */
proto.cloud_api.AlertTargetRule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.AlertTargetRule;
  return proto.cloud_api.AlertTargetRule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.AlertTargetRule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.AlertTargetRule}
 */
proto.cloud_api.AlertTargetRule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetParentId(value);
      break;
    case 2:
      var value = /** @type {!proto.cloud_api.Target.TypeEnum} */ (reader.readEnum());
      msg.setTargetParentType(value);
      break;
    case 3:
      var value = /** @type {!proto.cloud_api.Target.TypeEnum} */ (reader.readEnum());
      msg.setTargetBaseType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.AlertTargetRule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.AlertTargetRule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.AlertTargetRule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AlertTargetRule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetParentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTargetParentType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTargetBaseType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string target_parent_id = 1;
 * @return {string}
 */
proto.cloud_api.AlertTargetRule.prototype.getTargetParentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AlertTargetRule} returns this
 */
proto.cloud_api.AlertTargetRule.prototype.setTargetParentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Target.TypeEnum target_parent_type = 2;
 * @return {!proto.cloud_api.Target.TypeEnum}
 */
proto.cloud_api.AlertTargetRule.prototype.getTargetParentType = function() {
  return /** @type {!proto.cloud_api.Target.TypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.cloud_api.Target.TypeEnum} value
 * @return {!proto.cloud_api.AlertTargetRule} returns this
 */
proto.cloud_api.AlertTargetRule.prototype.setTargetParentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Target.TypeEnum target_base_type = 3;
 * @return {!proto.cloud_api.Target.TypeEnum}
 */
proto.cloud_api.AlertTargetRule.prototype.getTargetBaseType = function() {
  return /** @type {!proto.cloud_api.Target.TypeEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.cloud_api.Target.TypeEnum} value
 * @return {!proto.cloud_api.AlertTargetRule} returns this
 */
proto.cloud_api.AlertTargetRule.prototype.setTargetBaseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.AlertConfigV2.repeatedFields_ = [4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.AlertConfigV2.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.AlertConfigV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.AlertConfigV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AlertConfigV2.toObject = function(includeInstance, msg) {
  var f, obj = {
triggerType: jspb.Message.getFieldWithDefault(msg, 1, 0),
name: jspb.Message.getFieldWithDefault(msg, 2, ""),
organizationId: jspb.Message.getFieldWithDefault(msg, 3, ""),
webhooksList: jspb.Message.toObjectList(msg.getWebhooksList(),
    pbtypes_tools_cloud_api_webhook_pb.Webhook.toObject, includeInstance),
userEmailsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
alertTargetRulesList: jspb.Message.toObjectList(msg.getAlertTargetRulesList(),
    proto.cloud_api.AlertTargetRule.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.AlertConfigV2}
 */
proto.cloud_api.AlertConfigV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.AlertConfigV2;
  return proto.cloud_api.AlertConfigV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.AlertConfigV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.AlertConfigV2}
 */
proto.cloud_api.AlertConfigV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cloud_api.Trigger.TypeEnum} */ (reader.readEnum());
      msg.setTriggerType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 4:
      var value = new pbtypes_tools_cloud_api_webhook_pb.Webhook;
      reader.readMessage(value,pbtypes_tools_cloud_api_webhook_pb.Webhook.deserializeBinaryFromReader);
      msg.addWebhooks(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserEmails(value);
      break;
    case 6:
      var value = new proto.cloud_api.AlertTargetRule;
      reader.readMessage(value,proto.cloud_api.AlertTargetRule.deserializeBinaryFromReader);
      msg.addAlertTargetRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.AlertConfigV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.AlertConfigV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.AlertConfigV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AlertConfigV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTriggerType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWebhooksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      pbtypes_tools_cloud_api_webhook_pb.Webhook.serializeBinaryToWriter
    );
  }
  f = message.getUserEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getAlertTargetRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.cloud_api.AlertTargetRule.serializeBinaryToWriter
    );
  }
};


/**
 * optional Trigger.TypeEnum trigger_type = 1;
 * @return {!proto.cloud_api.Trigger.TypeEnum}
 */
proto.cloud_api.AlertConfigV2.prototype.getTriggerType = function() {
  return /** @type {!proto.cloud_api.Trigger.TypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.cloud_api.Trigger.TypeEnum} value
 * @return {!proto.cloud_api.AlertConfigV2} returns this
 */
proto.cloud_api.AlertConfigV2.prototype.setTriggerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cloud_api.AlertConfigV2.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AlertConfigV2} returns this
 */
proto.cloud_api.AlertConfigV2.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organization_id = 3;
 * @return {string}
 */
proto.cloud_api.AlertConfigV2.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AlertConfigV2} returns this
 */
proto.cloud_api.AlertConfigV2.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Webhook webhooks = 4;
 * @return {!Array<!proto.cloud_api.Webhook>}
 */
proto.cloud_api.AlertConfigV2.prototype.getWebhooksList = function() {
  return /** @type{!Array<!proto.cloud_api.Webhook>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_tools_cloud_api_webhook_pb.Webhook, 4));
};


/**
 * @param {!Array<!proto.cloud_api.Webhook>} value
 * @return {!proto.cloud_api.AlertConfigV2} returns this
*/
proto.cloud_api.AlertConfigV2.prototype.setWebhooksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cloud_api.Webhook=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.Webhook}
 */
proto.cloud_api.AlertConfigV2.prototype.addWebhooks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cloud_api.Webhook, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.AlertConfigV2} returns this
 */
proto.cloud_api.AlertConfigV2.prototype.clearWebhooksList = function() {
  return this.setWebhooksList([]);
};


/**
 * repeated string user_emails = 5;
 * @return {!Array<string>}
 */
proto.cloud_api.AlertConfigV2.prototype.getUserEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_api.AlertConfigV2} returns this
 */
proto.cloud_api.AlertConfigV2.prototype.setUserEmailsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.AlertConfigV2} returns this
 */
proto.cloud_api.AlertConfigV2.prototype.addUserEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.AlertConfigV2} returns this
 */
proto.cloud_api.AlertConfigV2.prototype.clearUserEmailsList = function() {
  return this.setUserEmailsList([]);
};


/**
 * repeated AlertTargetRule alert_target_rules = 6;
 * @return {!Array<!proto.cloud_api.AlertTargetRule>}
 */
proto.cloud_api.AlertConfigV2.prototype.getAlertTargetRulesList = function() {
  return /** @type{!Array<!proto.cloud_api.AlertTargetRule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.AlertTargetRule, 6));
};


/**
 * @param {!Array<!proto.cloud_api.AlertTargetRule>} value
 * @return {!proto.cloud_api.AlertConfigV2} returns this
*/
proto.cloud_api.AlertConfigV2.prototype.setAlertTargetRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.cloud_api.AlertTargetRule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.AlertTargetRule}
 */
proto.cloud_api.AlertConfigV2.prototype.addAlertTargetRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.cloud_api.AlertTargetRule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.AlertConfigV2} returns this
 */
proto.cloud_api.AlertConfigV2.prototype.clearAlertTargetRulesList = function() {
  return this.setAlertTargetRulesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FindAlertConfigRequestV2.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FindAlertConfigRequestV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FindAlertConfigRequestV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FindAlertConfigRequestV2.toObject = function(includeInstance, msg) {
  var f, obj = {
triggerType: jspb.Message.getFieldWithDefault(msg, 1, 0),
targetType: jspb.Message.getFieldWithDefault(msg, 2, 0),
targetUuid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FindAlertConfigRequestV2}
 */
proto.cloud_api.FindAlertConfigRequestV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FindAlertConfigRequestV2;
  return proto.cloud_api.FindAlertConfigRequestV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FindAlertConfigRequestV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FindAlertConfigRequestV2}
 */
proto.cloud_api.FindAlertConfigRequestV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cloud_api.Trigger.TypeEnum} */ (reader.readEnum());
      msg.setTriggerType(value);
      break;
    case 2:
      var value = /** @type {!proto.cloud_api.Target.TypeEnum} */ (reader.readEnum());
      msg.setTargetType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FindAlertConfigRequestV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FindAlertConfigRequestV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FindAlertConfigRequestV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FindAlertConfigRequestV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTriggerType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTargetType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTargetUuid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional Trigger.TypeEnum trigger_type = 1;
 * @return {!proto.cloud_api.Trigger.TypeEnum}
 */
proto.cloud_api.FindAlertConfigRequestV2.prototype.getTriggerType = function() {
  return /** @type {!proto.cloud_api.Trigger.TypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.cloud_api.Trigger.TypeEnum} value
 * @return {!proto.cloud_api.FindAlertConfigRequestV2} returns this
 */
proto.cloud_api.FindAlertConfigRequestV2.prototype.setTriggerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Target.TypeEnum target_type = 2;
 * @return {!proto.cloud_api.Target.TypeEnum}
 */
proto.cloud_api.FindAlertConfigRequestV2.prototype.getTargetType = function() {
  return /** @type {!proto.cloud_api.Target.TypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.cloud_api.Target.TypeEnum} value
 * @return {!proto.cloud_api.FindAlertConfigRequestV2} returns this
 */
proto.cloud_api.FindAlertConfigRequestV2.prototype.setTargetType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string target_uuid = 3;
 * @return {string}
 */
proto.cloud_api.FindAlertConfigRequestV2.prototype.getTargetUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FindAlertConfigRequestV2} returns this
 */
proto.cloud_api.FindAlertConfigRequestV2.prototype.setTargetUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.FindAlertConfigResponseV2.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FindAlertConfigResponseV2.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FindAlertConfigResponseV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FindAlertConfigResponseV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FindAlertConfigResponseV2.toObject = function(includeInstance, msg) {
  var f, obj = {
alertConfigsList: jspb.Message.toObjectList(msg.getAlertConfigsList(),
    proto.cloud_api.AlertConfigV2.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FindAlertConfigResponseV2}
 */
proto.cloud_api.FindAlertConfigResponseV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FindAlertConfigResponseV2;
  return proto.cloud_api.FindAlertConfigResponseV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FindAlertConfigResponseV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FindAlertConfigResponseV2}
 */
proto.cloud_api.FindAlertConfigResponseV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.AlertConfigV2;
      reader.readMessage(value,proto.cloud_api.AlertConfigV2.deserializeBinaryFromReader);
      msg.addAlertConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FindAlertConfigResponseV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FindAlertConfigResponseV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FindAlertConfigResponseV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FindAlertConfigResponseV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cloud_api.AlertConfigV2.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AlertConfigV2 alert_configs = 1;
 * @return {!Array<!proto.cloud_api.AlertConfigV2>}
 */
proto.cloud_api.FindAlertConfigResponseV2.prototype.getAlertConfigsList = function() {
  return /** @type{!Array<!proto.cloud_api.AlertConfigV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.AlertConfigV2, 1));
};


/**
 * @param {!Array<!proto.cloud_api.AlertConfigV2>} value
 * @return {!proto.cloud_api.FindAlertConfigResponseV2} returns this
*/
proto.cloud_api.FindAlertConfigResponseV2.prototype.setAlertConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cloud_api.AlertConfigV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.AlertConfigV2}
 */
proto.cloud_api.FindAlertConfigResponseV2.prototype.addAlertConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cloud_api.AlertConfigV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.FindAlertConfigResponseV2} returns this
 */
proto.cloud_api.FindAlertConfigResponseV2.prototype.clearAlertConfigsList = function() {
  return this.setAlertConfigsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.IncomingAlert.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.IncomingAlert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.IncomingAlert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.IncomingAlert.toObject = function(includeInstance, msg) {
  var f, obj = {
targetType: jspb.Message.getFieldWithDefault(msg, 1, 0),
targetUuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
alertRecord: (f = msg.getAlertRecord()) && proto.cloud_api.AlertRecordV2.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.IncomingAlert}
 */
proto.cloud_api.IncomingAlert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.IncomingAlert;
  return proto.cloud_api.IncomingAlert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.IncomingAlert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.IncomingAlert}
 */
proto.cloud_api.IncomingAlert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cloud_api.Target.TypeEnum} */ (reader.readEnum());
      msg.setTargetType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUuid(value);
      break;
    case 3:
      var value = new proto.cloud_api.AlertRecordV2;
      reader.readMessage(value,proto.cloud_api.AlertRecordV2.deserializeBinaryFromReader);
      msg.setAlertRecord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.IncomingAlert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.IncomingAlert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.IncomingAlert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.IncomingAlert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTargetUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAlertRecord();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cloud_api.AlertRecordV2.serializeBinaryToWriter
    );
  }
};


/**
 * optional Target.TypeEnum target_type = 1;
 * @return {!proto.cloud_api.Target.TypeEnum}
 */
proto.cloud_api.IncomingAlert.prototype.getTargetType = function() {
  return /** @type {!proto.cloud_api.Target.TypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.cloud_api.Target.TypeEnum} value
 * @return {!proto.cloud_api.IncomingAlert} returns this
 */
proto.cloud_api.IncomingAlert.prototype.setTargetType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string target_uuid = 2;
 * @return {string}
 */
proto.cloud_api.IncomingAlert.prototype.getTargetUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.IncomingAlert} returns this
 */
proto.cloud_api.IncomingAlert.prototype.setTargetUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AlertRecordV2 alert_record = 3;
 * @return {?proto.cloud_api.AlertRecordV2}
 */
proto.cloud_api.IncomingAlert.prototype.getAlertRecord = function() {
  return /** @type{?proto.cloud_api.AlertRecordV2} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.AlertRecordV2, 3));
};


/**
 * @param {?proto.cloud_api.AlertRecordV2|undefined} value
 * @return {!proto.cloud_api.IncomingAlert} returns this
*/
proto.cloud_api.IncomingAlert.prototype.setAlertRecord = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.IncomingAlert} returns this
 */
proto.cloud_api.IncomingAlert.prototype.clearAlertRecord = function() {
  return this.setAlertRecord(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.IncomingAlert.prototype.hasAlertRecord = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.AlertRecordV2.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.AlertRecordV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.AlertRecordV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AlertRecordV2.toObject = function(includeInstance, msg) {
  var f, obj = {
uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
organizationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
alertType: jspb.Message.getFieldWithDefault(msg, 3, 0),
alertUclock: jspb.Message.getFieldWithDefault(msg, 4, 0),
alertContext: (f = msg.getAlertContext()) && proto.cloud_api.AlertContext.toObject(includeInstance, f),
alertDetails: (f = msg.getAlertDetails()) && proto.cloud_api.AlertDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.AlertRecordV2}
 */
proto.cloud_api.AlertRecordV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.AlertRecordV2;
  return proto.cloud_api.AlertRecordV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.AlertRecordV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.AlertRecordV2}
 */
proto.cloud_api.AlertRecordV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 3:
      var value = /** @type {!proto.cloud_api.Trigger.TypeEnum} */ (reader.readEnum());
      msg.setAlertType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAlertUclock(value);
      break;
    case 5:
      var value = new proto.cloud_api.AlertContext;
      reader.readMessage(value,proto.cloud_api.AlertContext.deserializeBinaryFromReader);
      msg.setAlertContext(value);
      break;
    case 6:
      var value = new proto.cloud_api.AlertDetails;
      reader.readMessage(value,proto.cloud_api.AlertDetails.deserializeBinaryFromReader);
      msg.setAlertDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.AlertRecordV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.AlertRecordV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.AlertRecordV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AlertRecordV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAlertType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAlertUclock();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAlertContext();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.cloud_api.AlertContext.serializeBinaryToWriter
    );
  }
  f = message.getAlertDetails();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.cloud_api.AlertDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.cloud_api.AlertRecordV2.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AlertRecordV2} returns this
 */
proto.cloud_api.AlertRecordV2.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.cloud_api.AlertRecordV2.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AlertRecordV2} returns this
 */
proto.cloud_api.AlertRecordV2.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Trigger.TypeEnum alert_type = 3;
 * @return {!proto.cloud_api.Trigger.TypeEnum}
 */
proto.cloud_api.AlertRecordV2.prototype.getAlertType = function() {
  return /** @type {!proto.cloud_api.Trigger.TypeEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.cloud_api.Trigger.TypeEnum} value
 * @return {!proto.cloud_api.AlertRecordV2} returns this
 */
proto.cloud_api.AlertRecordV2.prototype.setAlertType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 alert_uclock = 4;
 * @return {number}
 */
proto.cloud_api.AlertRecordV2.prototype.getAlertUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.AlertRecordV2} returns this
 */
proto.cloud_api.AlertRecordV2.prototype.setAlertUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional AlertContext alert_context = 5;
 * @return {?proto.cloud_api.AlertContext}
 */
proto.cloud_api.AlertRecordV2.prototype.getAlertContext = function() {
  return /** @type{?proto.cloud_api.AlertContext} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.AlertContext, 5));
};


/**
 * @param {?proto.cloud_api.AlertContext|undefined} value
 * @return {!proto.cloud_api.AlertRecordV2} returns this
*/
proto.cloud_api.AlertRecordV2.prototype.setAlertContext = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.AlertRecordV2} returns this
 */
proto.cloud_api.AlertRecordV2.prototype.clearAlertContext = function() {
  return this.setAlertContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.AlertRecordV2.prototype.hasAlertContext = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AlertDetails alert_details = 6;
 * @return {?proto.cloud_api.AlertDetails}
 */
proto.cloud_api.AlertRecordV2.prototype.getAlertDetails = function() {
  return /** @type{?proto.cloud_api.AlertDetails} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.AlertDetails, 6));
};


/**
 * @param {?proto.cloud_api.AlertDetails|undefined} value
 * @return {!proto.cloud_api.AlertRecordV2} returns this
*/
proto.cloud_api.AlertRecordV2.prototype.setAlertDetails = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.AlertRecordV2} returns this
 */
proto.cloud_api.AlertRecordV2.prototype.clearAlertDetails = function() {
  return this.setAlertDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.AlertRecordV2.prototype.hasAlertDetails = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.AlertContext.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.AlertContext.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.AlertContext} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AlertContext.toObject = function(includeInstance, msg) {
  var f, obj = {
vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
dockId: jspb.Message.getFieldWithDefault(msg, 2, ""),
flightId: jspb.Message.getFieldWithDefault(msg, 3, ""),
flightDataFileId: jspb.Message.getFieldWithDefault(msg, 4, ""),
missionSpecId: jspb.Message.getFieldWithDefault(msg, 5, ""),
missionExecutionId: jspb.Message.getFieldWithDefault(msg, 6, ""),
scheduledMissionId: jspb.Message.getFieldWithDefault(msg, 7, ""),
missionTemplateId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.AlertContext}
 */
proto.cloud_api.AlertContext.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.AlertContext;
  return proto.cloud_api.AlertContext.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.AlertContext} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.AlertContext}
 */
proto.cloud_api.AlertContext.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDockId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightDataFileId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMissionSpecId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMissionExecutionId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setScheduledMissionId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMissionTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.AlertContext.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.AlertContext.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.AlertContext} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AlertContext.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDockId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFlightDataFileId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMissionSpecId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMissionExecutionId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getScheduledMissionId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMissionTemplateId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.cloud_api.AlertContext.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AlertContext} returns this
 */
proto.cloud_api.AlertContext.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dock_id = 2;
 * @return {string}
 */
proto.cloud_api.AlertContext.prototype.getDockId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AlertContext} returns this
 */
proto.cloud_api.AlertContext.prototype.setDockId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string flight_id = 3;
 * @return {string}
 */
proto.cloud_api.AlertContext.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AlertContext} returns this
 */
proto.cloud_api.AlertContext.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string flight_data_file_id = 4;
 * @return {string}
 */
proto.cloud_api.AlertContext.prototype.getFlightDataFileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AlertContext} returns this
 */
proto.cloud_api.AlertContext.prototype.setFlightDataFileId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string mission_spec_id = 5;
 * @return {string}
 */
proto.cloud_api.AlertContext.prototype.getMissionSpecId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AlertContext} returns this
 */
proto.cloud_api.AlertContext.prototype.setMissionSpecId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string mission_execution_id = 6;
 * @return {string}
 */
proto.cloud_api.AlertContext.prototype.getMissionExecutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AlertContext} returns this
 */
proto.cloud_api.AlertContext.prototype.setMissionExecutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string scheduled_mission_id = 7;
 * @return {string}
 */
proto.cloud_api.AlertContext.prototype.getScheduledMissionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AlertContext} returns this
 */
proto.cloud_api.AlertContext.prototype.setScheduledMissionId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string mission_template_id = 8;
 * @return {string}
 */
proto.cloud_api.AlertContext.prototype.getMissionTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AlertContext} returns this
 */
proto.cloud_api.AlertContext.prototype.setMissionTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.cloud_api.AlertDetails.oneofGroups_ = [[1,2,3,4,5,6,7,8,9]];

/**
 * @enum {number}
 */
proto.cloud_api.AlertDetails.AlertDetailsCase = {
  ALERT_DETAILS_NOT_SET: 0,
  ONLINE_STATUS: 1,
  MEDIA_FILE: 2,
  DOCK_ERROR: 3,
  MISSION_INCOMPLETE: 4,
  FLIGHT_STATUS: 5,
  WAYPOINT_PROGRESS: 6,
  SCAN: 7,
  LIVE_STREAM_STATUS: 8,
  FLIGHT: 9
};

/**
 * @return {proto.cloud_api.AlertDetails.AlertDetailsCase}
 */
proto.cloud_api.AlertDetails.prototype.getAlertDetailsCase = function() {
  return /** @type {proto.cloud_api.AlertDetails.AlertDetailsCase} */(jspb.Message.computeOneofCase(this, proto.cloud_api.AlertDetails.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.AlertDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.AlertDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.AlertDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AlertDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
onlineStatus: (f = msg.getOnlineStatus()) && proto.cloud_api.OnlineStatusDetails.toObject(includeInstance, f),
mediaFile: (f = msg.getMediaFile()) && proto.cloud_api.MediaFileDetails.toObject(includeInstance, f),
dockError: (f = msg.getDockError()) && proto.cloud_api.DockErrorDetails.toObject(includeInstance, f),
missionIncomplete: (f = msg.getMissionIncomplete()) && proto.cloud_api.MissionIncompleteDetails.toObject(includeInstance, f),
flightStatus: (f = msg.getFlightStatus()) && proto.cloud_api.FlightStatusDetails.toObject(includeInstance, f),
waypointProgress: (f = msg.getWaypointProgress()) && proto.cloud_api.WaypointProgressDetails.toObject(includeInstance, f),
scan: (f = msg.getScan()) && proto.cloud_api.ScanDetails.toObject(includeInstance, f),
liveStreamStatus: (f = msg.getLiveStreamStatus()) && proto.cloud_api.LiveStreamStatusDetails.toObject(includeInstance, f),
flight: (f = msg.getFlight()) && proto.cloud_api.FlightDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.AlertDetails}
 */
proto.cloud_api.AlertDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.AlertDetails;
  return proto.cloud_api.AlertDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.AlertDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.AlertDetails}
 */
proto.cloud_api.AlertDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.OnlineStatusDetails;
      reader.readMessage(value,proto.cloud_api.OnlineStatusDetails.deserializeBinaryFromReader);
      msg.setOnlineStatus(value);
      break;
    case 2:
      var value = new proto.cloud_api.MediaFileDetails;
      reader.readMessage(value,proto.cloud_api.MediaFileDetails.deserializeBinaryFromReader);
      msg.setMediaFile(value);
      break;
    case 3:
      var value = new proto.cloud_api.DockErrorDetails;
      reader.readMessage(value,proto.cloud_api.DockErrorDetails.deserializeBinaryFromReader);
      msg.setDockError(value);
      break;
    case 4:
      var value = new proto.cloud_api.MissionIncompleteDetails;
      reader.readMessage(value,proto.cloud_api.MissionIncompleteDetails.deserializeBinaryFromReader);
      msg.setMissionIncomplete(value);
      break;
    case 5:
      var value = new proto.cloud_api.FlightStatusDetails;
      reader.readMessage(value,proto.cloud_api.FlightStatusDetails.deserializeBinaryFromReader);
      msg.setFlightStatus(value);
      break;
    case 6:
      var value = new proto.cloud_api.WaypointProgressDetails;
      reader.readMessage(value,proto.cloud_api.WaypointProgressDetails.deserializeBinaryFromReader);
      msg.setWaypointProgress(value);
      break;
    case 7:
      var value = new proto.cloud_api.ScanDetails;
      reader.readMessage(value,proto.cloud_api.ScanDetails.deserializeBinaryFromReader);
      msg.setScan(value);
      break;
    case 8:
      var value = new proto.cloud_api.LiveStreamStatusDetails;
      reader.readMessage(value,proto.cloud_api.LiveStreamStatusDetails.deserializeBinaryFromReader);
      msg.setLiveStreamStatus(value);
      break;
    case 9:
      var value = new proto.cloud_api.FlightDetails;
      reader.readMessage(value,proto.cloud_api.FlightDetails.deserializeBinaryFromReader);
      msg.setFlight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.AlertDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.AlertDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.AlertDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AlertDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOnlineStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.OnlineStatusDetails.serializeBinaryToWriter
    );
  }
  f = message.getMediaFile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.cloud_api.MediaFileDetails.serializeBinaryToWriter
    );
  }
  f = message.getDockError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cloud_api.DockErrorDetails.serializeBinaryToWriter
    );
  }
  f = message.getMissionIncomplete();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.cloud_api.MissionIncompleteDetails.serializeBinaryToWriter
    );
  }
  f = message.getFlightStatus();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.cloud_api.FlightStatusDetails.serializeBinaryToWriter
    );
  }
  f = message.getWaypointProgress();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.cloud_api.WaypointProgressDetails.serializeBinaryToWriter
    );
  }
  f = message.getScan();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.cloud_api.ScanDetails.serializeBinaryToWriter
    );
  }
  f = message.getLiveStreamStatus();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.cloud_api.LiveStreamStatusDetails.serializeBinaryToWriter
    );
  }
  f = message.getFlight();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.cloud_api.FlightDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional OnlineStatusDetails online_status = 1;
 * @return {?proto.cloud_api.OnlineStatusDetails}
 */
proto.cloud_api.AlertDetails.prototype.getOnlineStatus = function() {
  return /** @type{?proto.cloud_api.OnlineStatusDetails} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.OnlineStatusDetails, 1));
};


/**
 * @param {?proto.cloud_api.OnlineStatusDetails|undefined} value
 * @return {!proto.cloud_api.AlertDetails} returns this
*/
proto.cloud_api.AlertDetails.prototype.setOnlineStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.cloud_api.AlertDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.AlertDetails} returns this
 */
proto.cloud_api.AlertDetails.prototype.clearOnlineStatus = function() {
  return this.setOnlineStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.AlertDetails.prototype.hasOnlineStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MediaFileDetails media_file = 2;
 * @return {?proto.cloud_api.MediaFileDetails}
 */
proto.cloud_api.AlertDetails.prototype.getMediaFile = function() {
  return /** @type{?proto.cloud_api.MediaFileDetails} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.MediaFileDetails, 2));
};


/**
 * @param {?proto.cloud_api.MediaFileDetails|undefined} value
 * @return {!proto.cloud_api.AlertDetails} returns this
*/
proto.cloud_api.AlertDetails.prototype.setMediaFile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.cloud_api.AlertDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.AlertDetails} returns this
 */
proto.cloud_api.AlertDetails.prototype.clearMediaFile = function() {
  return this.setMediaFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.AlertDetails.prototype.hasMediaFile = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DockErrorDetails dock_error = 3;
 * @return {?proto.cloud_api.DockErrorDetails}
 */
proto.cloud_api.AlertDetails.prototype.getDockError = function() {
  return /** @type{?proto.cloud_api.DockErrorDetails} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.DockErrorDetails, 3));
};


/**
 * @param {?proto.cloud_api.DockErrorDetails|undefined} value
 * @return {!proto.cloud_api.AlertDetails} returns this
*/
proto.cloud_api.AlertDetails.prototype.setDockError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.cloud_api.AlertDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.AlertDetails} returns this
 */
proto.cloud_api.AlertDetails.prototype.clearDockError = function() {
  return this.setDockError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.AlertDetails.prototype.hasDockError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MissionIncompleteDetails mission_incomplete = 4;
 * @return {?proto.cloud_api.MissionIncompleteDetails}
 */
proto.cloud_api.AlertDetails.prototype.getMissionIncomplete = function() {
  return /** @type{?proto.cloud_api.MissionIncompleteDetails} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.MissionIncompleteDetails, 4));
};


/**
 * @param {?proto.cloud_api.MissionIncompleteDetails|undefined} value
 * @return {!proto.cloud_api.AlertDetails} returns this
*/
proto.cloud_api.AlertDetails.prototype.setMissionIncomplete = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.cloud_api.AlertDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.AlertDetails} returns this
 */
proto.cloud_api.AlertDetails.prototype.clearMissionIncomplete = function() {
  return this.setMissionIncomplete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.AlertDetails.prototype.hasMissionIncomplete = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FlightStatusDetails flight_status = 5;
 * @return {?proto.cloud_api.FlightStatusDetails}
 */
proto.cloud_api.AlertDetails.prototype.getFlightStatus = function() {
  return /** @type{?proto.cloud_api.FlightStatusDetails} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.FlightStatusDetails, 5));
};


/**
 * @param {?proto.cloud_api.FlightStatusDetails|undefined} value
 * @return {!proto.cloud_api.AlertDetails} returns this
*/
proto.cloud_api.AlertDetails.prototype.setFlightStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.cloud_api.AlertDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.AlertDetails} returns this
 */
proto.cloud_api.AlertDetails.prototype.clearFlightStatus = function() {
  return this.setFlightStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.AlertDetails.prototype.hasFlightStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional WaypointProgressDetails waypoint_progress = 6;
 * @return {?proto.cloud_api.WaypointProgressDetails}
 */
proto.cloud_api.AlertDetails.prototype.getWaypointProgress = function() {
  return /** @type{?proto.cloud_api.WaypointProgressDetails} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.WaypointProgressDetails, 6));
};


/**
 * @param {?proto.cloud_api.WaypointProgressDetails|undefined} value
 * @return {!proto.cloud_api.AlertDetails} returns this
*/
proto.cloud_api.AlertDetails.prototype.setWaypointProgress = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.cloud_api.AlertDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.AlertDetails} returns this
 */
proto.cloud_api.AlertDetails.prototype.clearWaypointProgress = function() {
  return this.setWaypointProgress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.AlertDetails.prototype.hasWaypointProgress = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ScanDetails scan = 7;
 * @return {?proto.cloud_api.ScanDetails}
 */
proto.cloud_api.AlertDetails.prototype.getScan = function() {
  return /** @type{?proto.cloud_api.ScanDetails} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.ScanDetails, 7));
};


/**
 * @param {?proto.cloud_api.ScanDetails|undefined} value
 * @return {!proto.cloud_api.AlertDetails} returns this
*/
proto.cloud_api.AlertDetails.prototype.setScan = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.cloud_api.AlertDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.AlertDetails} returns this
 */
proto.cloud_api.AlertDetails.prototype.clearScan = function() {
  return this.setScan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.AlertDetails.prototype.hasScan = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional LiveStreamStatusDetails live_stream_status = 8;
 * @return {?proto.cloud_api.LiveStreamStatusDetails}
 */
proto.cloud_api.AlertDetails.prototype.getLiveStreamStatus = function() {
  return /** @type{?proto.cloud_api.LiveStreamStatusDetails} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.LiveStreamStatusDetails, 8));
};


/**
 * @param {?proto.cloud_api.LiveStreamStatusDetails|undefined} value
 * @return {!proto.cloud_api.AlertDetails} returns this
*/
proto.cloud_api.AlertDetails.prototype.setLiveStreamStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.cloud_api.AlertDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.AlertDetails} returns this
 */
proto.cloud_api.AlertDetails.prototype.clearLiveStreamStatus = function() {
  return this.setLiveStreamStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.AlertDetails.prototype.hasLiveStreamStatus = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional FlightDetails flight = 9;
 * @return {?proto.cloud_api.FlightDetails}
 */
proto.cloud_api.AlertDetails.prototype.getFlight = function() {
  return /** @type{?proto.cloud_api.FlightDetails} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.FlightDetails, 9));
};


/**
 * @param {?proto.cloud_api.FlightDetails|undefined} value
 * @return {!proto.cloud_api.AlertDetails} returns this
*/
proto.cloud_api.AlertDetails.prototype.setFlight = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.cloud_api.AlertDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.AlertDetails} returns this
 */
proto.cloud_api.AlertDetails.prototype.clearFlight = function() {
  return this.setFlight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.AlertDetails.prototype.hasFlight = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.OnlineStatusDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.OnlineStatusDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.OnlineStatusDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OnlineStatusDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
isOnline: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.OnlineStatusDetails}
 */
proto.cloud_api.OnlineStatusDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.OnlineStatusDetails;
  return proto.cloud_api.OnlineStatusDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.OnlineStatusDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.OnlineStatusDetails}
 */
proto.cloud_api.OnlineStatusDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOnline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.OnlineStatusDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.OnlineStatusDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.OnlineStatusDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OnlineStatusDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsOnline();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_online = 1;
 * @return {boolean}
 */
proto.cloud_api.OnlineStatusDetails.prototype.getIsOnline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.OnlineStatusDetails} returns this
 */
proto.cloud_api.OnlineStatusDetails.prototype.setIsOnline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FlightStatusDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FlightStatusDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FlightStatusDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FlightStatusDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
flightState: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FlightStatusDetails}
 */
proto.cloud_api.FlightStatusDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FlightStatusDetails;
  return proto.cloud_api.FlightStatusDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FlightStatusDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FlightStatusDetails}
 */
proto.cloud_api.FlightStatusDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cloud_api.FlightStatusDetails.FlightState} */ (reader.readEnum());
      msg.setFlightState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FlightStatusDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FlightStatusDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FlightStatusDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FlightStatusDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.cloud_api.FlightStatusDetails.FlightState = {
  UNKNOWN: 0,
  FLIGHT_START: 1,
  FLIGHT_END: 2
};

/**
 * optional FlightState flight_state = 1;
 * @return {!proto.cloud_api.FlightStatusDetails.FlightState}
 */
proto.cloud_api.FlightStatusDetails.prototype.getFlightState = function() {
  return /** @type {!proto.cloud_api.FlightStatusDetails.FlightState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.cloud_api.FlightStatusDetails.FlightState} value
 * @return {!proto.cloud_api.FlightStatusDetails} returns this
 */
proto.cloud_api.FlightStatusDetails.prototype.setFlightState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.WaypointProgressDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.WaypointProgressDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.WaypointProgressDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.WaypointProgressDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
waypointUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
waypointIndex: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.WaypointProgressDetails}
 */
proto.cloud_api.WaypointProgressDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.WaypointProgressDetails;
  return proto.cloud_api.WaypointProgressDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.WaypointProgressDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.WaypointProgressDetails}
 */
proto.cloud_api.WaypointProgressDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWaypointUuid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWaypointIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.WaypointProgressDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.WaypointProgressDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.WaypointProgressDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.WaypointProgressDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWaypointUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWaypointIndex();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string waypoint_uuid = 1;
 * @return {string}
 */
proto.cloud_api.WaypointProgressDetails.prototype.getWaypointUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.WaypointProgressDetails} returns this
 */
proto.cloud_api.WaypointProgressDetails.prototype.setWaypointUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 waypoint_index = 2;
 * @return {number}
 */
proto.cloud_api.WaypointProgressDetails.prototype.getWaypointIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.WaypointProgressDetails} returns this
 */
proto.cloud_api.WaypointProgressDetails.prototype.setWaypointIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.MediaFileDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.MediaFileDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.MediaFileDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.MediaFileDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
fileKind: jspb.Message.getFieldWithDefault(msg, 1, ""),
fileName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.MediaFileDetails}
 */
proto.cloud_api.MediaFileDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.MediaFileDetails;
  return proto.cloud_api.MediaFileDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.MediaFileDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.MediaFileDetails}
 */
proto.cloud_api.MediaFileDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileKind(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.MediaFileDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.MediaFileDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.MediaFileDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.MediaFileDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileKind();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string file_kind = 1;
 * @return {string}
 */
proto.cloud_api.MediaFileDetails.prototype.getFileKind = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.MediaFileDetails} returns this
 */
proto.cloud_api.MediaFileDetails.prototype.setFileKind = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.cloud_api.MediaFileDetails.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.MediaFileDetails} returns this
 */
proto.cloud_api.MediaFileDetails.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.DockErrorDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.DockErrorDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.DockErrorDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DockErrorDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
dockErrorName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.DockErrorDetails}
 */
proto.cloud_api.DockErrorDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.DockErrorDetails;
  return proto.cloud_api.DockErrorDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.DockErrorDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.DockErrorDetails}
 */
proto.cloud_api.DockErrorDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDockErrorName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.DockErrorDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.DockErrorDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.DockErrorDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DockErrorDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDockErrorName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dock_error_name = 1;
 * @return {string}
 */
proto.cloud_api.DockErrorDetails.prototype.getDockErrorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DockErrorDetails} returns this
 */
proto.cloud_api.DockErrorDetails.prototype.setDockErrorName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.MissionIncompleteDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.MissionIncompleteDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.MissionIncompleteDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.MissionIncompleteDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
missionResultName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.MissionIncompleteDetails}
 */
proto.cloud_api.MissionIncompleteDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.MissionIncompleteDetails;
  return proto.cloud_api.MissionIncompleteDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.MissionIncompleteDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.MissionIncompleteDetails}
 */
proto.cloud_api.MissionIncompleteDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMissionResultName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.MissionIncompleteDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.MissionIncompleteDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.MissionIncompleteDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.MissionIncompleteDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMissionResultName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string mission_result_name = 1;
 * @return {string}
 */
proto.cloud_api.MissionIncompleteDetails.prototype.getMissionResultName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.MissionIncompleteDetails} returns this
 */
proto.cloud_api.MissionIncompleteDetails.prototype.setMissionResultName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.ScanDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.ScanDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.ScanDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ScanDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
scanId: jspb.Message.getFieldWithDefault(msg, 1, ""),
scanName: jspb.Message.getFieldWithDefault(msg, 2, ""),
scanTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
photoCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
scanMode: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.ScanDetails}
 */
proto.cloud_api.ScanDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.ScanDetails;
  return proto.cloud_api.ScanDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.ScanDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.ScanDetails}
 */
proto.cloud_api.ScanDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPhotoCount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.ScanDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.ScanDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.ScanDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ScanDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScanName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScanTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhotoCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getScanMode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string scan_id = 1;
 * @return {string}
 */
proto.cloud_api.ScanDetails.prototype.getScanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ScanDetails} returns this
 */
proto.cloud_api.ScanDetails.prototype.setScanId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string scan_name = 2;
 * @return {string}
 */
proto.cloud_api.ScanDetails.prototype.getScanName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ScanDetails} returns this
 */
proto.cloud_api.ScanDetails.prototype.setScanName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string scan_time = 3;
 * @return {string}
 */
proto.cloud_api.ScanDetails.prototype.getScanTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ScanDetails} returns this
 */
proto.cloud_api.ScanDetails.prototype.setScanTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 photo_count = 4;
 * @return {number}
 */
proto.cloud_api.ScanDetails.prototype.getPhotoCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.ScanDetails} returns this
 */
proto.cloud_api.ScanDetails.prototype.setPhotoCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string scan_mode = 5;
 * @return {string}
 */
proto.cloud_api.ScanDetails.prototype.getScanMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ScanDetails} returns this
 */
proto.cloud_api.ScanDetails.prototype.setScanMode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.LiveStreamStatusDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.LiveStreamStatusDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.LiveStreamStatusDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.LiveStreamStatusDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
liveStreamState: jspb.Message.getFieldWithDefault(msg, 1, 0),
trackName: jspb.Message.getFieldWithDefault(msg, 2, ""),
rtspEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
userEmail: jspb.Message.getFieldWithDefault(msg, 4, ""),
token: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.LiveStreamStatusDetails}
 */
proto.cloud_api.LiveStreamStatusDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.LiveStreamStatusDetails;
  return proto.cloud_api.LiveStreamStatusDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.LiveStreamStatusDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.LiveStreamStatusDetails}
 */
proto.cloud_api.LiveStreamStatusDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cloud_api.LiveStreamStatusDetails.LiveStreamState} */ (reader.readEnum());
      msg.setLiveStreamState(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRtspEnabled(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.LiveStreamStatusDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.LiveStreamStatusDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.LiveStreamStatusDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.LiveStreamStatusDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveStreamState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTrackName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRtspEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.cloud_api.LiveStreamStatusDetails.LiveStreamState = {
  UNKNOWN: 0,
  LIVE_STREAM_START: 1,
  LIVE_STREAM_END: 2
};

/**
 * optional LiveStreamState live_stream_state = 1;
 * @return {!proto.cloud_api.LiveStreamStatusDetails.LiveStreamState}
 */
proto.cloud_api.LiveStreamStatusDetails.prototype.getLiveStreamState = function() {
  return /** @type {!proto.cloud_api.LiveStreamStatusDetails.LiveStreamState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.cloud_api.LiveStreamStatusDetails.LiveStreamState} value
 * @return {!proto.cloud_api.LiveStreamStatusDetails} returns this
 */
proto.cloud_api.LiveStreamStatusDetails.prototype.setLiveStreamState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string track_name = 2;
 * @return {string}
 */
proto.cloud_api.LiveStreamStatusDetails.prototype.getTrackName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LiveStreamStatusDetails} returns this
 */
proto.cloud_api.LiveStreamStatusDetails.prototype.setTrackName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool rtsp_enabled = 3;
 * @return {boolean}
 */
proto.cloud_api.LiveStreamStatusDetails.prototype.getRtspEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.LiveStreamStatusDetails} returns this
 */
proto.cloud_api.LiveStreamStatusDetails.prototype.setRtspEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string user_email = 4;
 * @return {string}
 */
proto.cloud_api.LiveStreamStatusDetails.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LiveStreamStatusDetails} returns this
 */
proto.cloud_api.LiveStreamStatusDetails.prototype.setUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string token = 5;
 * @return {string}
 */
proto.cloud_api.LiveStreamStatusDetails.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LiveStreamStatusDetails} returns this
 */
proto.cloud_api.LiveStreamStatusDetails.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FlightDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FlightDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FlightDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FlightDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
pilotEmail: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FlightDetails}
 */
proto.cloud_api.FlightDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FlightDetails;
  return proto.cloud_api.FlightDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FlightDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FlightDetails}
 */
proto.cloud_api.FlightDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPilotEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FlightDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FlightDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FlightDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FlightDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPilotEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string pilot_email = 1;
 * @return {string}
 */
proto.cloud_api.FlightDetails.prototype.getPilotEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FlightDetails} returns this
 */
proto.cloud_api.FlightDetails.prototype.setPilotEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.cloud_api);
