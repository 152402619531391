import { createSelector } from "reselect";
import _ from "lodash";

import { APIRequestState, INITIAL_REQUEST_STATE } from "@skydio/redux_util/src";

import { NEW_ORG_PLACEHOLDER_ID } from "./types";

import { OrganizationsState } from "./slice";

export const canFetchOrganizations = (state: OrganizationsState) =>
  !state.requests.organizations.active;

export const getOrganizations = (state: OrganizationsState) => state.organizations;

export const getOrganizationsByName = (state: OrganizationsState) =>
  _.keyBy(getOrganizationsList(state), "name");

export const getOrganization = (state: OrganizationsState, uuid: string) =>
  state.organizations[uuid];

export const getOrganizationGroupLinks = (state: OrganizationsState, uuid: string) =>
  state.organizations[uuid]!.groupLinksList;

export const getOrganizationSkillsetLinks = (state: OrganizationsState, uuid: string) =>
  state.organizations[uuid]!.skillsetLinksList;

export const getOrganizationsList = createSelector(getOrganizations, orgs => _.toArray(orgs));

export const getOrganizationNames = createSelector(getOrganizationsList, orgs =>
  _.map(orgs, "name")
);

export const isOrganizationRequestActive = (state: OrganizationsState, organizationId: string) =>
  state.requests.organization[organizationId]?.active ?? false;

export const getOrganizationRequest = (state: OrganizationsState, organizationId: string) =>
  state.requests.organization[organizationId];

export const getOrganizationRequestError = (state: OrganizationsState, organizationId: string) =>
  getOrganizationRequest(state, organizationId)?.error;

export const getNewOrganizationRequest = (state: OrganizationsState): APIRequestState =>
  getOrganizationRequest(state, NEW_ORG_PLACEHOLDER_ID) || INITIAL_REQUEST_STATE;

export const getOrganizationStorageStats = (state: OrganizationsState) =>
  state.organizationStorageStats;
export const getOrganizationPilotStats = (state: OrganizationsState) =>
  state.organizationPilotStats;
