import { createAsyncThunk } from "@reduxjs/toolkit";
import { sendRequest } from "../requests-browser";
import endpoints from "../endpoints";

import wifi_settings_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/wifi_setting_pb";
import field_mask_pb from "google-protobuf/google/protobuf/field_mask_pb";

export const fetchWifiSettings = createAsyncThunk(
  "wifiSettings/list",
  async (organizationId: string) => {
    const wifiSettings = await sendRequest(endpoints.GET_WIFI_SETTINGS, {
      path: { organizationId },
    });
    return wifiSettings.toObject();
  }
);

export interface CreateWifiSettingArgs {
  organizationId: string;
  ssid: string;
  password: string;
}

export const addWifiSetting = createAsyncThunk(
  "wifiSetting/create",
  async ({ organizationId, ssid, password }: CreateWifiSettingArgs) => {
    const requestProto = new wifi_settings_pb.UpdateWifiSettingRequest();
    const updateMasks = new field_mask_pb.FieldMask();
    updateMasks.addPaths("ssid");
    updateMasks.addPaths("password");
    requestProto.setUpdateMask(updateMasks);
    requestProto.setSsid(ssid);
    requestProto.setPassword(password);

    const wifiSettingResponse = await sendRequest(endpoints.CREATE_WIFI_SETTING, {
      path: { organizationId },
      protobuf: requestProto,
    });
    return wifiSettingResponse.toObject();
  }
);

export interface UpdateWifiSettingArgs {
  organizationId: string;
  wifiSettingId: string;
  ssid?: string;
  password?: string;
}

export const updateWifiSetting = createAsyncThunk(
  "wifiSetting/update",
  async ({ organizationId, wifiSettingId, ssid, password }: UpdateWifiSettingArgs) => {
    const requestProto = new wifi_settings_pb.UpdateWifiSettingRequest();
    const updateMasks = new field_mask_pb.FieldMask();

    if (ssid) {
      requestProto.setSsid(ssid);
      updateMasks.addPaths("ssid");
    }

    if (password) {
      requestProto.setPassword(password);
      updateMasks.addPaths("password");
    }

    requestProto.setUpdateMask(updateMasks);

    const wifiSetting = await sendRequest(endpoints.UPDATE_WIFI_SETTING, {
      path: { organizationId, wifiSettingId },
      protobuf: requestProto,
    });
    return wifiSetting.toObject();
  }
);

export interface DeleteWifiSettingArgs {
  wifiSettingId: string;
  organizationId: string;
}

export const deleteWifiSettings = createAsyncThunk(
  "wifiSettings/delete",
  async ({ wifiSettingId, organizationId }: DeleteWifiSettingArgs) => {
    const wifiSetting = await sendRequest(endpoints.DELETE_WIFI_SETTING, {
      path: { organizationId, wifiSettingId },
    });
    return wifiSetting.toObject();
  }
);
