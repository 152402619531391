import { createAsyncThunk } from "@reduxjs/toolkit";
import field_mask_pb from "google-protobuf/google/protobuf/field_mask_pb";

import release_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/release_pb";
import user_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/user_pb";

import { download } from "../../../common/http";
import endpoints from "../endpoints";
import { sendRequest } from "../requests-browser";

import { ReleaseFilesRequest, ReleasesRequest, ReleaseUpdate } from "./types";

export const fetchReleases = createAsyncThunk(
  "releases/fetchAll",
  async (args: ReleasesRequest) => {
    const response = await sendRequest(endpoints.GET_RELEASES, {
      ...args,
      includeAdminFields: 1,
    });
    return response.toObject();
  }
);

export const fetchRelease = createAsyncThunk("releases/fetchOne", async (key: string) => {
  const release = await sendRequest(endpoints.GET_RELEASE, { path: { key } });
  return release.toObject();
});

export interface UpdateArgs {
  key: string;
  update: ReleaseUpdate;
}

export const updateRelease = createAsyncThunk(
  "releases/update",
  async ({ key, update }: UpdateArgs) => {
    const releaseUpdateProto = new release_pb.ReleaseFile();
    const updateMasks = new field_mask_pb.FieldMask();
    if ("version" in update) {
      updateMasks.addPaths("version");
      if (update.version) releaseUpdateProto.setVersion(update.version);
    }
    if ("description" in update) {
      updateMasks.addPaths("description");
      if (update.description) releaseUpdateProto.setDescription(update.description);
    }
    if ("comment" in update) {
      updateMasks.addPaths("comment");
      if (update.comment) releaseUpdateProto.setComment(update.comment);
    }
    if ("active" in update) {
      updateMasks.addPaths("active");
      if (update.active) releaseUpdateProto.setActive(update.active);
    }
    if ("warn" in update) {
      updateMasks.addPaths("warn");
      if (update.warn) releaseUpdateProto.setWarn(update.warn);
    }
    if ("vehicleType" in update) {
      updateMasks.addPaths("vehicle_type");
      if (update.vehicleType) releaseUpdateProto.setVehicleType(update.vehicleType);
    }
    if ("groups" in update) {
      updateMasks.addPaths("groups");
      update.groups?.forEach(groupName => {
        const groupProto = new user_pb.Group();
        groupProto.setName(groupName);
        releaseUpdateProto.addGroups(groupProto);
      });
    }
    if ("minIosVersion" in update) {
      updateMasks.addPaths("min_ios_version");
      if (update.minIosVersion) releaseUpdateProto.setMinIosVersion(update.minIosVersion);
    }
    if ("maxIosVersion" in update) {
      updateMasks.addPaths("max_ios_version");
      if (update.maxIosVersion) releaseUpdateProto.setMaxIosVersion(update.maxIosVersion);
    }
    if ("minAndroidVersion" in update) {
      updateMasks.addPaths("min_android_version");
      if (update.minAndroidVersion)
        releaseUpdateProto.setMinAndroidVersion(update.minAndroidVersion);
    }
    if ("maxAndroidVersion" in update) {
      updateMasks.addPaths("max_android_version");
      if (update.maxAndroidVersion)
        releaseUpdateProto.setMaxAndroidVersion(update.maxAndroidVersion);
    }
    const protobuf = new release_pb.UpdateReleaseRequest();
    protobuf.setRelease(releaseUpdateProto);
    protobuf.setUpdateMask(updateMasks);
    try {
      const release = await sendRequest(endpoints.UPDATE_RELEASE, { path: { key }, protobuf });
      return release.toObject();
    } catch (error: any) {
      console.error(error);
      alert(
        "Failed to update release. Check the console for more information. " +
          (error.code ? `Code: ${error.code}` : "")
      );
      throw error;
    }
  }
);

export interface ReleaseFileRequestArgs {
  toReleaseKey: string;
  args: ReleaseFilesRequest;
}

export const fetchReleaseFiles = createAsyncThunk(
  "releases/fetchFiles",
  async ({ toReleaseKey, args }: ReleaseFileRequestArgs) => {
    const response = await sendRequest(endpoints.GET_RELEASE_FILES, {
      ...args,
      toReleaseKey: toReleaseKey,
      includeAdminFields: 1,
    });
    return response.toObject();
  }
);

export interface DownloadReleaseArgs {
  key: string;
  encrypted?: boolean;
}

export const downloadRelease = createAsyncThunk(
  "releases/download",
  async ({ key, encrypted = false }: DownloadReleaseArgs) => {
    const protobuf = new release_pb.GetReleaseRequest();
    protobuf.setEncrypted(encrypted);
    const response = await sendRequest(endpoints.DOWNLOAD_RELEASE, {
      path: { key },
      protobuf,
    });
    download(response.toObject().downloadUrl);
  }
);

export interface OverrideUpdateArgs {
  key: string;
  email: string;
  validDuration?: number;
  removeOverride?: boolean;
  forceFullFlashpack?: boolean;
}

export const updateOverride = createAsyncThunk(
  "releases/setUser",
  async ({ key, email, validDuration, removeOverride, forceFullFlashpack }: OverrideUpdateArgs) => {
    const protobuf = new release_pb.UserReleaseOverrideRequest();
    if (email) {
      protobuf.setEmail(email);
    }
    if (validDuration) {
      protobuf.setValidDuration(validDuration);
    }
    if (removeOverride) {
      protobuf.setRemove(removeOverride);
    }
    if (forceFullFlashpack) {
      protobuf.setForceFullFlashpack(forceFullFlashpack);
    }
    const userReleaseOverrides = await sendRequest(endpoints.SET_USER_RELEASE_OVERRIDE, {
      path: { key },
      protobuf,
    });
    return userReleaseOverrides.toObject();
  }
);

export interface DeviceOverrideUpdateArgs {
  key: string;
  deviceId: string;
  validDuration?: number;
  removeOverride?: boolean;
  forceFullFlashpack?: boolean;
}

export const updateDeviceOverride = createAsyncThunk(
  "releases/setDevice",
  async ({
    key,
    deviceId,
    validDuration,
    removeOverride,
    forceFullFlashpack,
  }: DeviceOverrideUpdateArgs) => {
    const protobuf = new release_pb.DeviceReleaseOverrideRequest();
    if (deviceId) {
      protobuf.setDeviceId(deviceId);
    }
    if (validDuration) {
      protobuf.setValidDuration(validDuration);
    }
    if (removeOverride) {
      protobuf.setRemove(removeOverride);
    }
    if (forceFullFlashpack) {
      protobuf.setForceFullFlashpack(forceFullFlashpack);
    }
    const deviceReleaseOverrides = await sendRequest(endpoints.SET_DEVICE_RELEASE_OVERRIDE, {
      path: { key },
      protobuf,
    });
    return deviceReleaseOverrides.toObject();
  }
);
