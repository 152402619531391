import _ from "lodash";
import { createSelector } from "reselect";

import { ReleasesState } from "./slice";
import { Release, ReleaseFile } from "./types";
import { getVersionSortFunc } from "./utils";

export const getAllReleases = ({ state }: ReleasesState) => state.releases;

export const getRelease = (state: ReleasesState, key: string) => getAllReleases(state)[key]!;

export const getReleasesRequest = ({ requests }: ReleasesState) => requests.releases;

export const isReleasesRequestActive = (state: ReleasesState) => getReleasesRequest(state).active;

export const getReleaseRequest = ({ requests }: ReleasesState, key: string) =>
  requests.release[key];

export const isReleaseRequestActive = (state: ReleasesState, key: string) =>
  getReleaseRequest(state, key)?.active;

export const getReleaseRequestError = (state: ReleasesState, key: string) =>
  getReleaseRequest(state, key)?.error;

export const getReleaseModifications = (state: ReleasesState, key: string) =>
  // @ts-ignore TS2339
  getRelease(state, key).modified;

export const getFilesForRelease = ({ state }: ReleasesState, toReleaseKey: string) =>
  state.releaseFiles[toReleaseKey]?.files ?? {};

export const getReleaseFilesRequest = ({ requests }: ReleasesState, toReleaseKey: string) =>
  requests.releaseFiles[toReleaseKey];

export const isReleaseFilesRequestActive = (state: ReleasesState, toReleaseKey: string) =>
  getReleaseFilesRequest(state, toReleaseKey)?.active;

export const getReleaseFilesRequestError = (state: ReleasesState, toReleaseKey: string) =>
  getReleaseFilesRequest(state, toReleaseKey)?.error ?? null;

export const getReleasesQueryTimeout = ({ state }: ReleasesState) => state.dispatchTimeout;

export const getReleaseRequestKeys = ({ state }: ReleasesState) => state.requestedIds;

export const getReleaseFileRequestKeys = ({ state }: ReleasesState, toReleaseKey: string) =>
  state.releaseFiles[toReleaseKey]?.requestedIds ?? [];

export const doesReleaseNeedUpdate = (state: ReleasesState, key: string) =>
  // @ts-ignore TS2339
  _.keys(getRelease(state, key)?.modified ?? {}).length > 0;

export const updateUserReleaseOverrideRequestError = (state: ReleasesState, key: string) =>
  state.requests.userReleaseOverrides[key]?.error;

export const updateDeviceReleaseOverrideRequestError = (state: ReleasesState, key: string) =>
  state.requests.deviceReleaseOverrides[key]?.error;

const versionSort = getVersionSortFunc<Release>("version");
const fromVersionSortFunc = getVersionSortFunc<ReleaseFile>("fromVersion");

export const getReleasesForRequest = createSelector(
  getReleaseRequestKeys,
  getAllReleases,
  (releaseKeys, allReleases) => releaseKeys.map(key => allReleases[key]!).sort(versionSort)
);

export const getReleaseFiles = createSelector(
  [getReleaseFileRequestKeys, getFilesForRelease, getAllReleases],
  (keys, releaseFiles) =>
    keys
      .map(key => ({
        ...releaseFiles[key]!,
        fromVersion: releaseFiles[key]!.fromReleaseVersion,
      }))
      // @ts-ignore
      .sort(fromVersionSortFunc)
);

export const getPagination = ({ state }: ReleasesState) => state.pagination;

export const getReleaseFilePagination = (state: ReleasesState, toReleaseKey: string) =>
  state.state.releaseFiles[toReleaseKey]!.pagination;
