import { setSearchParams } from "@skydio/uri_util/src/query";

import { QUERY_CHANGE_DELAY } from "../constants";
import { fetchSimulators, fetchSimulator, updateSimulator } from "./asyncThunks";
import {
  getSimulatorsQueryTimeout,
  isSimulatorRequestActive,
  isSimulatorsRequestActive,
  getSimulatorModifications,
} from "./selectors";
import { simulatorActions, SimulatorsState } from "./slice";
import { prepareSimulatorsRequest } from "./utils";

import { ThunkCreator, AsyncThunkCreator } from "@skydio/redux_util/src";
import { SimulatorsQueryState } from "./types";

const { setSimulatorsQueryTimeout, clearSimulatorsQueryTimeout } = simulatorActions;

export const updateSimulatorsQuery: ThunkCreator<
  SimulatorsState,
  [SimulatorsQueryState, boolean?]
> = (fields, immediate) => (dispatch, getState) => {
  const newArgs = prepareSimulatorsRequest(fields);
  if (immediate) {
    dispatch(fetchSimulators(newArgs));
  } else {
    const state = getState();

    clearTimeout(getSimulatorsQueryTimeout(state)!);
    dispatch(clearSimulatorsQueryTimeout());

    const timeout = window?.setTimeout(
      () => dispatch(fetchSimulators(newArgs)),
      QUERY_CHANGE_DELAY
    );
    dispatch(setSimulatorsQueryTimeout(timeout));
  }

  dispatch(setSearchParams(newArgs));
};

export const fetchSimulatorsIfAble: AsyncThunkCreator<SimulatorsState, [SimulatorsQueryState]> =
  fields => async (dispatch, getState) => {
    const state = getState();
    if (!isSimulatorsRequestActive(state)) {
      dispatch(fetchSimulators(prepareSimulatorsRequest(fields)));
    }
  };

export const fetchSimulatorIfAble: AsyncThunkCreator<SimulatorsState, [string]> =
  uuid => async (dispatch, getState) => {
    if (!isSimulatorRequestActive(getState(), uuid)) {
      dispatch(fetchSimulator(uuid));
    }
  };

export const updateSimulatorIfAble: AsyncThunkCreator<SimulatorsState, [string]> =
  uuid => async (dispatch, getState) => {
    const state = getState();
    if (!isSimulatorRequestActive(state, uuid)) {
      const update = getSimulatorModifications(state, uuid);
      dispatch(updateSimulator({ uuid, update }));
    }
  };
