import { createReducer, combineReducers } from "@reduxjs/toolkit";

import { createRequestReducerFromThunk } from "@skydio/redux_util/src";

import { fetchGroups } from "./asyncThunks";

import { GroupsMap } from "./types";

const groups = createReducer({} as GroupsMap, builder =>
  builder.addCase(fetchGroups.fulfilled, (state, { payload }) => {
    payload.forEach(group => {
      if (group.name in state) {
        state[group.name]!.name = group.name;
      } else {
        state[group.name] = group;
      }
    });
  })
);

const reducer = combineReducers({
  groups,
  request: createRequestReducerFromThunk(fetchGroups),
});
export type GroupsState = ReturnType<typeof reducer>;
export default reducer;
