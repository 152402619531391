import { createReducer, combineReducers, createAction } from "@reduxjs/toolkit";

import {
  createRequestReducerFromThunk,
  createClearableRequestReducer,
} from "@skydio/redux_util/src";

import { authLoginTypes, authLogin, authLogout, authAuthenticate, checkAuth } from "./asyncThunks";
import { LoginTypeResponse } from "./types";

export const clearLoginTypes = createAction("auth/clearLoginTypes");
const loginTypes = createReducer<LoginTypeResponse | null>(null, builder =>
  builder
    .addCase(authLoginTypes.fulfilled, (_state, { payload }) => payload)
    .addCase(clearLoginTypes, () => null)
);

const email = createReducer("", builder =>
  builder
    .addCase(authLogin.pending, (_state, { meta }) => meta.arg?.email)
    .addCase(authLogout.fulfilled, () => "")
    .addCase(authAuthenticate.fulfilled, (_state, { meta }) => meta.arg?.email || "")
    .addCase(authLoginTypes.fulfilled, (_state, { meta }) => meta.arg?.email || "")
);

const organizationUuid = createReducer("", builder =>
  builder
    .addCase(authLoginTypes.pending, (_state, { meta }) => meta?.arg?.organization_uuid || "")
    .addCase(authLogout.fulfilled, () => "")
    .addCase(authAuthenticate.fulfilled, (_state, { meta }) => meta.arg?.organization_uuid || "")
    .addCase(
      authLoginTypes.fulfilled,
      (_state, { meta, payload }) =>
        (payload.organizationUuid ? payload.organizationUuid : meta.arg?.organization_uuid) || ""
    )
);

const authenticated = createReducer<boolean | null>(null, builder =>
  builder
    .addCase(authAuthenticate.fulfilled, () => true)
    .addCase(authLogout.fulfilled, () => false)
    .addCase(checkAuth.fulfilled, () => true)
    .addCase(checkAuth.rejected, () => false)
);

const loginTypesRequestReducer = createClearableRequestReducer(
  createRequestReducerFromThunk(authLoginTypes),
  [authLogout.fulfilled, authAuthenticate.fulfilled, checkAuth.rejected]
);

export const clearLoginRequest = createAction("auth/clearLoginRequest");
const loginRequestReducer = createClearableRequestReducer(
  createRequestReducerFromThunk(authLogin),
  [authLogout.fulfilled, authAuthenticate.fulfilled, checkAuth.rejected, clearLoginRequest]
);
const authenticateRequestReducer = createClearableRequestReducer(
  createRequestReducerFromThunk(authAuthenticate),
  [authLogout.fulfilled, checkAuth.rejected]
);

const reducer = combineReducers({
  email,
  authenticated,
  organizationUuid,
  loginTypes,
  requests: combineReducers({
    login: loginRequestReducer,
    loginTypes: loginTypesRequestReducer,
    authenticate: authenticateRequestReducer,
    logout: createRequestReducerFromThunk(authLogout),
  }),
});
export type AuthState = ReturnType<typeof reducer>;

export default reducer;
