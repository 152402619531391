import { fetchGroups } from "./asyncThunks";
import { canFetchGroups } from "./selectors";

import { AsyncThunkCreator } from "@skydio/redux_util/src";
import { GroupsState } from "./slice";

export const fetchGroupsIfAble: AsyncThunkCreator<GroupsState, [boolean?]> =
  includeGroupMetadata => async (dispatch, getState) => {
    if (canFetchGroups(getState())) {
      dispatch(fetchGroups(includeGroupMetadata));
    }
  };
