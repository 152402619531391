import { createSelector } from "reselect";

import { SimulatorsState } from "./slice";
import { NEW_SIM_PLACEHOLDER_ID } from "./types";

const getSimulatorsRequestUUIDs = ({ state }: SimulatorsState) => state.requestedIds;
export const getAllSimulators = ({ state }: SimulatorsState) => state.simulators;

export const getSimulatorsForRequest = createSelector(
  getSimulatorsRequestUUIDs,
  getAllSimulators,
  (uuids, simulators) => uuids.map(uuid => simulators[uuid])
);

export const getSimulatorsQueryTimeout = ({ state }: SimulatorsState) => state.dispatchTimeout;

export const getSimulatorsRequest = ({ requests }: SimulatorsState) => requests.simulators;

export const isSimulatorsRequestActive = (state: SimulatorsState) =>
  getSimulatorsRequest(state).active;

export const getSimulatorRequest = ({ requests }: SimulatorsState, uuid: string) =>
  requests.simulator[uuid];

export const getNewSimulatorRequest = (state: SimulatorsState) =>
  getSimulatorRequest(state, NEW_SIM_PLACEHOLDER_ID) ?? {};

export const isSimulatorRequestActive = (state: SimulatorsState, uuid: string) =>
  getSimulatorRequest(state, uuid)?.active;

export const getSimulatorModifications = ({ state }: SimulatorsState, uuid: string) =>
  state.simulators[uuid]!.modified;

export const getSimulatorsPagination = ({ state }: SimulatorsState) => state.pagination;
