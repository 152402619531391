import { setSearchParams } from "@skydio/uri_util/src/query";

import { QUERY_CHANGE_DELAY } from "../constants";
import {
  fetchControllerReleases,
  fetchControllerRelease,
  updateControllerRelease,
  deleteControllerOveride,
  deleteControllerUserOverride,
  updateControllerOverride,
  updateControllerUserOverride,
} from "./asyncThunks";
import {
  isControllerReleasesRequestActive,
  isControllerReleaseRequestActive,
  getControllerReleaseModifications,
  getControllerReleasesQueryTimeout,
} from "./selectors";
import { controllerReleaseActions, ControllerReleasesState } from "./slice";
import { prepareControllerReleasesRequest } from "./utils";

import { ThunkCreator, AsyncThunkCreator } from "@skydio/redux_util/src";
import { ControllerReleasesQueryState } from "./types";

const { clearControllerReleasesQueryTimeout, setControllerReleasesQueryTimeout } =
  controllerReleaseActions;

export const updateControllerReleasesQuery: ThunkCreator<
  ControllerReleasesState,
  [ControllerReleasesQueryState]
> = fields => (dispatch, getState) => {
  const state = getState();

  clearTimeout(getControllerReleasesQueryTimeout(state)!);
  dispatch(clearControllerReleasesQueryTimeout());

  const newArgs = prepareControllerReleasesRequest(fields);
  const timeout = window?.setTimeout(
    () => dispatch(fetchControllerReleases(newArgs)),
    QUERY_CHANGE_DELAY
  );
  dispatch(setControllerReleasesQueryTimeout(timeout));

  dispatch(setSearchParams(newArgs));
};

export const fetchControllerReleasesIfAble: AsyncThunkCreator<
  ControllerReleasesState,
  [ControllerReleasesQueryState]
> = fields => async (dispatch, getState) => {
  const state = getState();
  if (!isControllerReleasesRequestActive(state)) {
    dispatch(fetchControllerReleases(prepareControllerReleasesRequest(fields)));
  }
};

export const fetchControllerReleaseIfAble: AsyncThunkCreator<ControllerReleasesState, [string]> =
  key => async (dispatch, getState) => {
    if (!isControllerReleaseRequestActive(getState(), key)) {
      dispatch(fetchControllerRelease(key));
    }
  };

export const updateControllerReleaseIfAble: AsyncThunkCreator<ControllerReleasesState, [string]> =
  key => async (dispatch, getState) => {
    const state = getState();
    if (!isControllerReleaseRequestActive(state, key)) {
      dispatch(
        updateControllerRelease({ key, update: getControllerReleaseModifications(state, key) })
      );
    }
  };

export const updateControllerReleaseOverride: AsyncThunkCreator<
  ControllerReleasesState,
  [string, string, number?]
> =
  (controllerId, key, validDuration = 0) =>
  async (dispatch, getState) => {
    if (!isControllerReleaseRequestActive(getState(), key)) {
      dispatch(
        updateControllerOverride({
          key,
          controllerId: controllerId,
          validDuration,
        })
      );
    }
  };

export const updateControllerReleaseUserOverride: AsyncThunkCreator<
  ControllerReleasesState,
  [string, string, number?]
> =
  (userEmail, key, validDuration = 0) =>
  async (dispatch, getState) => {
    if (!isControllerReleaseRequestActive(getState(), key)) {
      dispatch(
        updateControllerUserOverride({
          key,
          userEmail,
          validDuration,
        })
      );
    }
  };

export const deleteControllerReleaseOverride: AsyncThunkCreator<
  ControllerReleasesState,
  [string, string]
> = (controllerId, key) => async (dispatch, getState) => {
  if (!isControllerReleaseRequestActive(getState(), key)) {
    dispatch(
      deleteControllerOveride({
        key,
        controllerId: controllerId,
      })
    );
  }
};

export const deleteControllerReleaseUserOverride: AsyncThunkCreator<
  ControllerReleasesState,
  [string, string]
> = (userEmail, key) => async (dispatch, getState) => {
  if (!isControllerReleaseRequestActive(getState(), key)) {
    dispatch(
      deleteControllerUserOverride({
        key,
        userEmail,
      })
    );
  }
};
