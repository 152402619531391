import { createSlice, combineReducers, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import { $CombinedState } from "@reduxjs/toolkit";

import {
  createRequestReducerFromThunk,
  createIndexedRequestReducerFromThunk,
  createClearableIndexedRequestReducer,
  reduceReducers,
} from "@skydio/redux_util/src";

import { fetchControllerDevReleaseFile, fetchControllerDevReleaseFiles } from "./asyncThunks";

import {
  ControllerDevReleaseFilesPrimaryState,
  ControllerDevReleaseFile,
  ControllerDevReleaseFilesMap,
} from "./types";

import {
  defaultPagination,
  initialFile,
  initialState as controllerReleaseInitialState,
  clearableControllerReleaseUserOverrideReducer,
  controllerReleaseFileInitialState,
} from "../controller_releases/slice";
import { APIControllerRelease } from "../controller_releases/types";
import {
  deleteControllerOveride,
  deleteControllerUserOverride,
  updateControllerOverride,
  updateControllerUserOverride,
} from "../controller_releases/asyncThunks";

const controllerDevReleaseFileInitialState: ControllerDevReleaseFile = {
  ...controllerReleaseFileInitialState,
};

const initialState: ControllerDevReleaseFilesPrimaryState = {
  controllerDevReleaseFiles: {},
  dispatchTimeout: null,
  requestedIds: [],
  pagination: defaultPagination,
};

const updateControllerDevReleaseFileState = (
  state: ControllerDevReleaseFilesMap,
  { file = initialFile, ...release }: APIControllerRelease
) => {
  if (!(file.key in state)) {
    state[file.key] = { ...controllerDevReleaseFileInitialState };
  }

  Object.assign(state[file.key]!, file, release, {
    uploaded: file.uploaded ? moment(file.uploaded * 1000) : state.uploaded,
    s3Key: file.key,
  });
};

const { actions, reducer: primaryReducer } = createSlice({
  name: "controllerReleases",
  initialState,
  reducers: {
    setControllerDevReleaseFilesQueryTimeout(state, { payload }: PayloadAction<number>) {
      state.dispatchTimeout = payload;
    },
    clearControllerDevReleaseFilesQueryTimeout(state) {
      state.dispatchTimeout = null;
    },
    clearControllerDevReleaseFileModifications(state, { payload }: PayloadAction<string>) {
      state.controllerDevReleaseFiles[payload]!.modified = {};
    },
  },
  extraReducers: builder =>
    builder
      .addCase(fetchControllerDevReleaseFiles.fulfilled, (state, { payload }) => {
        state.requestedIds = payload.controllerReleasesList.map(({ releaseKey }) => releaseKey);
        payload.controllerReleasesList.forEach(controllerRelease => {
          updateControllerDevReleaseFileState(state.controllerDevReleaseFiles, controllerRelease);
        });
        state.pagination = {
          maxPerPage: payload.pagination!.maxPerPage,
          currentPage: payload.pagination!.currentPage,
          totalPages: payload.pagination!.totalPages,
        };
      })
      .addCase(fetchControllerDevReleaseFiles.rejected, state => {
        state.requestedIds = [];
      })
      .addCase(updateControllerOverride.fulfilled, (state, { payload }) => {
        if (!(payload.releaseKey in state.controllerDevReleaseFiles)) {
          state.controllerDevReleaseFiles[payload.releaseKey] = {
            ...controllerDevReleaseFileInitialState,
          };
        }
        Object.assign(state.controllerDevReleaseFiles[payload.releaseKey]!, {
          controllerReleaseOverridesList: payload.overridesList,
        });
      })
      .addCase(updateControllerUserOverride.fulfilled, (state, { payload }) => {
        if (!(payload.releaseKey in state.controllerDevReleaseFiles)) {
          state.controllerDevReleaseFiles[payload.releaseKey] = {
            ...controllerDevReleaseFileInitialState,
          };
        }
        Object.assign(state.controllerDevReleaseFiles[payload.releaseKey]!, {
          controllerReleaseOverridesList: payload.overridesList,
        });
      })
      .addCase(deleteControllerOveride.fulfilled, (state, { payload }) => {
        const { key, controllerId } = payload;
        const controllerRelease = state.controllerDevReleaseFiles[key];

        if (controllerRelease) {
          controllerRelease.controllerReleaseOverridesList =
            controllerRelease.controllerReleaseOverridesList.filter(
              override => override.controllerId !== controllerId
            );
        }
      })
      .addCase(deleteControllerUserOverride.fulfilled, (state, { payload }) => {
        const { key, userEmail } = payload;
        const controllerRelease = state.controllerDevReleaseFiles[key];

        if (controllerRelease) {
          controllerRelease.controllerReleaseOverridesList =
            controllerRelease.controllerReleaseOverridesList.filter(
              override => override.email !== userEmail
            );
        }
      })
      .addCase(fetchControllerDevReleaseFile.fulfilled, (state, { payload }) => {
        updateControllerDevReleaseFileState(state.controllerDevReleaseFiles, payload);
      }),
});

const { clearAction: clearControllerDevReleaseFileRequest, clearableReducer } =
  createClearableIndexedRequestReducer(
    reduceReducers(createIndexedRequestReducerFromThunk(fetchControllerDevReleaseFile)),
    "controllerReleases"
  );

const reducer = combineReducers({
  state: primaryReducer,
  requests: combineReducers({
    controllerDevReleaseFiles: createRequestReducerFromThunk(fetchControllerDevReleaseFiles),
    controllerDevReleaseFile: clearableReducer,
    controllerReleaseUserOverrides: clearableControllerReleaseUserOverrideReducer,
  }),
});
export type ControllerDevReleaseFilesState = Omit<
  ReturnType<typeof reducer>,
  typeof $CombinedState
>;

export const controllerDevReleaseFileActions = {
  ...actions,
  clearControllerDevReleaseFileRequest,
};
export default reducer;
