import { createAsyncThunk } from "@reduxjs/toolkit";
import { sendRequest } from "../requests-browser";
import endpoints from "../endpoints";

import feature_flags_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/feature_flags_pb";

import { OrgRole } from "./types";

export const fetchFeatureFlags = createAsyncThunk<
  feature_flags_pb.FeatureFlags.AsObject,
  string | undefined
>("featureFlags/fetch", async sharedLinkToken => {
  const response = await sendRequest(endpoints.GET_FEATURE_FLAGS, {
    ...(sharedLinkToken && { shared_link_token: sharedLinkToken }),
  });
  return response.toObject();
});

export const fetchFeatureFlagsForOrgRole = createAsyncThunk(
  "featureFlags/fetchOrgRole",
  async ({ organizationId, organizationPermission }: OrgRole) => {
    const requestProto = new feature_flags_pb.FeatureFlagRequest();
    requestProto.setOrganizationId(organizationId);
    requestProto.setOrganizationPermission(organizationPermission);
    const response = await sendRequest(endpoints.GET_FEATURE_FLAGS_FOR_ORG_ROLE, {
      protobuf: requestProto,
    });
    return response.toObject();
  }
);
