import { AsyncThunkCreator } from "@skydio/redux_util/src";

import { authAuthenticate } from "./asyncThunks";
import { getCurrentUserEmail, getUserOrganizationId } from "./selectors";

import { AuthState } from "./slice";

export const authenticateWithCode: AsyncThunkCreator<AuthState, [number]> =
  loginCode => async (dispatch, getState) => {
    dispatch(
      authAuthenticate({
        loginCode,
        email: getCurrentUserEmail(getState()),
        organization_uuid: getUserOrganizationId(getState()),
      })
    );
  };
