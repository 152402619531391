import { findKey, keys } from "lodash";

import enums_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/enums_pb";

export const orgPermissionEnum = enums_pb.OrgPermission.PermEnum;
export const featureSwitchEnum = enums_pb.FeatureSwitch.FeatureSwitchEnum;
export const orgTierEnum = enums_pb.OrgTier.OrgTierEnum;
export const dataLevelEnum = enums_pb.DataLevel.LevelEnum;

export const orgPermissionKeys = keys(orgPermissionEnum);

export const getOrgPermissionKey = (permission: enums_pb.OrgPermission.PermEnum) =>
  findKey(enums_pb.OrgPermission.PermEnum, o => o === permission);

export const getFeatureSwitchKey = (switchValue: enums_pb.FeatureSwitch.FeatureSwitchEnum) =>
  findKey(enums_pb.FeatureSwitch.FeatureSwitchEnum, o => o === switchValue);
