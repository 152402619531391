import { createAsyncThunk } from "@reduxjs/toolkit";

import pagination_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/pagination_pb";
import release_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/release_pb";

import endpoints from "../endpoints";
import { sendRequest } from "../requests-browser";

import { ControllerDevReleaseFilesRequest } from "./types";

export const fetchControllerDevReleaseFiles = createAsyncThunk(
  "controllerReleases/fetchAllDev",
  async (args: ControllerDevReleaseFilesRequest) => {
    const protobuf = new release_pb.ControllerReleasesRequest();
    const pagination = new pagination_pb.Pagination();
    protobuf.setDevReleasesOnly(true);
    if (args.releaseKey) {
      protobuf.setReleaseKey(args.releaseKey);
    }
    if (args.description) {
      protobuf.setDescription(args.description);
    }
    if (args.branch) {
      protobuf.setBranch(args.branch);
    }
    if (args.builder) {
      protobuf.setBuilder(args.builder);
    }
    if (args.commit) {
      protobuf.setCommit(args.commit);
    }
    if (args.limit) {
      protobuf.setLimit(args.limit);
    }
    if (args.pageNumber) {
      pagination.setCurrentPage(args.pageNumber);
    }
    if (args.perPage) {
      pagination.setMaxPerPage(args.perPage);
    }
    protobuf.setPagination(pagination);
    const response = await sendRequest(endpoints.GET_CONTROLLER_RELEASES, { protobuf });
    return response.toObject();
  }
);

export const fetchControllerDevReleaseFile = createAsyncThunk(
  "controllerReleases/fetchOneDev",
  async (key: string) => {
    const controllerRelease = await sendRequest(endpoints.GET_CONTROLLER_RELEASE, {
      path: { key },
    });
    return controllerRelease.toObject();
  }
);
